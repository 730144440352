import type { ChangeEvent, ReactNode } from 'react'
import { useEffect, useState } from 'react'

import { Dropdown, DropdownContent, DropdownPlaceholder, DropdownTrigger } from '@/components/atoms/Dropdown'
import { Slider } from '@/components/atoms/Slider'
import { LabeledInputField } from '@/components/molecules'
import { DEFAULT_TVL_IN_USD_RANGE } from '@/config/search'
import { TVL_LOWER_BOUND_KEY, TVL_UPPER_BOUND_KEY } from '@/constants/stateConstants'
import { useDebounce } from '@/hooks'
import { useTvlSearchParams } from '@/hooks/searchState/useTvlSearchParams'
import { useSearchState } from '@/hooks/useSearchState'
import { formatNumber, parseFormattedNumber } from '@/utils'

const SLIDER_MIN_VALUE = 0
const SLIDER_MAX_VALUE = 1000

export const SelectTvlRange = () => {
  const { updateSearchState } = useSearchState()
  const { selectedTvlRange } = useTvlSearchParams()

  const [sliderTvlRange, setSliderTvlRange] = useState(selectedTvlRange)

  useEffect(() => {
    setSliderTvlRange(selectedTvlRange)
  }, [selectedTvlRange])

  useDebounce(
    () => {
      const entries: [string, number | undefined][] = []
      if (sliderTvlRange[0] !== selectedTvlRange[0])
        entries.push([
          TVL_LOWER_BOUND_KEY,
          sliderTvlRange[0] === DEFAULT_TVL_IN_USD_RANGE[0] ? undefined : sliderTvlRange[0],
        ])
      if (sliderTvlRange[1] !== selectedTvlRange[1])
        entries.push([
          TVL_UPPER_BOUND_KEY,
          sliderTvlRange[1] === DEFAULT_TVL_IN_USD_RANGE[1] ? undefined : sliderTvlRange[1],
        ])
      entries.length > 0 && updateSearchState(...entries)
    },
    [sliderTvlRange, updateSearchState],
    500
  )

  const [sliderRange, setSliderRange] = useState(sliderTvlRange.map(tvlToSliderScale))
  const [maxFocused, setMaxFocused] = useState(false)
  const [minFocused, setMinFocused] = useState(false)
  const [inputRange, setInputRange] = useState<[string, string]>(sliderTvlRange.map(String) as [string, string])

  useEffect(() => {
    setSliderRange(sliderTvlRange.map(tvlToSliderScale))
    setInputRange(sliderTvlRange.map((num) => formatNumber(num)) as [string, string])
  }, [sliderTvlRange])

  const handleInputRangeChange = () => {
    const lowerBound = parseFormattedNumber(inputRange[0]) ?? sliderTvlRange[0]
    const upperBound = parseFormattedNumber(inputRange[1]) ?? sliderTvlRange[1]
    setSliderRange([lowerBound, upperBound].map(tvlToSliderScale))
    setSliderTvlRange([lowerBound, upperBound])
  }

  return (
    <Dropdown
      onOpenChange={() => {
        handleInputRangeChange()
        setMinFocused(false)
        setMaxFocused(false)
      }}
    >
      <DropdownTrigger>{SelectTriggerText({ sliderTvlRange })}</DropdownTrigger>
      <DropdownContent>
        <div className="flex min-h-24 flex-col gap-3 p-4">
          <span className="font-bold">TVL range</span>
          <Slider
            min={SLIDER_MIN_VALUE}
            max={SLIDER_MAX_VALUE}
            value={sliderRange}
            onValueChange={(value) => {
              setSliderRange(value)
              setSliderTvlRange(value.map(sliderScaleToTvl) as [number, number])
            }}
          />
          <div className="grid grid-cols-2 gap-3">
            <LabeledInputField
              id="min-tvl"
              label="Min TVL"
              value={minFocused ? inputRange[0] : formatNumber(sliderTvlRange[0])}
              onFocus={() => setMinFocused(true)}
              onBlur={() => {
                setMinFocused(false)
                handleInputRangeChange()
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInputRange([e.target.value, inputRange[1]])
              }}
            />
            <LabeledInputField
              id="max-tvl"
              label="Max TVL"
              value={maxFocused ? inputRange[1] : formatNumber(sliderTvlRange[1])}
              onFocus={() => setMaxFocused(true)}
              onBlur={() => {
                setMaxFocused(false)
                handleInputRangeChange()
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInputRange([inputRange[0], e.target.value])
              }}
            />
          </div>
        </div>
      </DropdownContent>
    </Dropdown>
  )
}

interface SelectTriggerTextProps {
  sliderTvlRange: [number, number]
}

const SelectTriggerText = ({ sliderTvlRange }: SelectTriggerTextProps): ReactNode => {
  if (
    !sliderTvlRange ||
    (sliderTvlRange[0] === DEFAULT_TVL_IN_USD_RANGE[0] && sliderTvlRange[1] === DEFAULT_TVL_IN_USD_RANGE[1])
  ) {
    return <DropdownPlaceholder>Filter by TVL</DropdownPlaceholder>
  }

  const lowerBoundText = formatNumber(sliderTvlRange[0])
  const upperBoundText = formatNumber(sliderTvlRange[1])

  if (sliderTvlRange[0] !== DEFAULT_TVL_IN_USD_RANGE[0] && sliderTvlRange[1] === DEFAULT_TVL_IN_USD_RANGE[1]) {
    return (
      <span>
        TVL {'>'} {formatNumber(sliderTvlRange[0])}
      </span>
    )
  }

  if (sliderTvlRange[0] === DEFAULT_TVL_IN_USD_RANGE[0] && sliderTvlRange[1] !== DEFAULT_TVL_IN_USD_RANGE[1]) {
    return (
      <span>
        TVL {'<'} {formatNumber(sliderTvlRange[1])}
      </span>
    )
  }

  return (
    <span>
      TVL: {lowerBoundText} – {upperBoundText}
    </span>
  )
}

const [TVL_LOG_MIN_VALUE, TVL_LOG_MAX_VALUE] = DEFAULT_TVL_IN_USD_RANGE.map(Math.log10)

function tvlToSliderScale(tvl: number): number {
  const normalizedToLogScale = normalize(Math.log10(tvl), TVL_LOG_MIN_VALUE, TVL_LOG_MAX_VALUE)
  return denormalize(normalizedToLogScale, SLIDER_MIN_VALUE, SLIDER_MAX_VALUE)
}

function sliderScaleToTvl(sliderValue: number): number {
  const normalizedToLinearScale = normalize(sliderValue, SLIDER_MIN_VALUE, SLIDER_MAX_VALUE)
  return Math.round(10 ** denormalize(normalizedToLinearScale, TVL_LOG_MIN_VALUE, TVL_LOG_MAX_VALUE))
}

function normalize(value: number, min: number, max: number): number {
  return (value - min) / (max - min)
}

function denormalize(value: number, min: number, max: number): number {
  return value * (max - min) + min
}
