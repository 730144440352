export function sortTokens(a: string, b: string, priority: string[]): number {
  const indexA = priority.indexOf(a)
  const indexB = priority.indexOf(b)

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB
  }
  if (indexA !== -1) {
    return -1
  }
  if (indexB !== -1) {
    return 1
  }
  return a.localeCompare(b)
}
