import { convertBlockTimestampToDate } from '@vaultsfyi/common'
import { endOfDay, isWithinInterval, startOfDay, subDays } from 'date-fns'
import type { MultiChartDataGroup } from './composeMultiChartData'

type ChartData<T> = {
  date: number
} & T

export const CHART_PERIODS = ['1D', '7D', '30D', '90D', '1Y', 'Max'] as const
export type ChartPeriod = (typeof CHART_PERIODS)[number]
export const DEFAULT_CHART_PERIOD: ChartPeriod = '90D'

export function isChartPeriod(value: string): value is ChartPeriod {
  return CHART_PERIODS.includes(value as ChartPeriod)
}

export const getChartDataByPeriod = <T>(data: ChartData<T>[], period: ChartPeriod, firstNonZeroIndex?: number) => {
  if (period === 'Max') {
    return firstNonZeroIndex ? data.slice(firstNonZeroIndex) : data
  }

  const currentDate = new Date()
  const periodAgo = subDays(currentDate, getPeriodInDays(period))
  const rangeInterval = { start: startOfDay(periodAgo), end: endOfDay(currentDate) }

  return data.filter((point) => {
    const pointDate = convertBlockTimestampToDate(point.date)
    return isWithinInterval(pointDate, rangeInterval)
  })
}

function getPeriodInDays(period: ChartPeriod): number {
  switch (period) {
    case '1D':
    case '7D':
    case '30D':
    case '90D':
      return Number(period.replace('D', ''))
    case '1Y':
      return 365
    default:
      return 0
  }
}

export function getMultiChartDataByPeriod(data: MultiChartDataGroup, period: ChartPeriod) {
  return {
    highResolution: getChartDataByPeriod(data.highResolution, period),
    daily: getChartDataByPeriod(data.daily, period),
  }
}
