import { ArrowIcon } from './Icons'

import { isSafari } from '@/utils'
import { cn } from '@/utils/cn'
import type { ButtonHTMLAttributes } from 'react'
import { Button } from './Button'

interface TableRowButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  $isActive: boolean
}

export const TableRowButton = ({ onClick, $isActive }: TableRowButtonProps) => {
  const accessibleLabel = $isActive ? 'Close details' : 'Open details'

  return (
    <Button
      variant="default"
      onClick={onClick}
      aria-label={accessibleLabel}
      data-role="table-row-button"
      className={cn(
        'flex size-6 items-center justify-center gap-2 sm:size-8',
        'hover:text-textPrimary focus-visible:text-textPrimary md:focus-visible:text-decorationPrimary md:hover:text-decorationPrimary',
        !isSafari() &&
          'before:absolute before:inset-0 before:h-full before:w-full before:rounded-none before:border before:border-decorationPrimary/0 before:bg-decorationPrimary/0 before:transition-all hover:before:rounded-lg hover:before:border-decorationPrimary hover:before:bg-decorationPrimary/15'
      )}
    >
      <div className={cn('transition-all', $isActive && '-scale-y-100')}>
        <ArrowIcon direction="down" />
      </div>
    </Button>
  )
}
