import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const BanknotesIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M558.75-446q-47.25 0-80-32.75t-32.75-80q0-47.25 32.958-79.75Q511.917-671 559-671q46.667 0 79.333 32.735Q671-605.529 671-558.765 671-512 638.5-479t-79.75 33ZM284-326q-30.938 0-52.969-22.031Q209-370.062 209-401v-315q0-30.938 22.031-52.969Q253.062-791 284-791h550q30.938 0 52.969 22.031Q909-746.938 909-716v315q0 30.938-22.031 52.969Q864.938-326 834-326H284Zm77-75h396q0-32.5 22.619-55.25Q802.237-479 834-479v-159q-32 0-54.5-22.75T757-716H361q0 32.5-22.619 55.25Q315.763-638 284-638v159q32 0 54.5 22.75T361-401Zm392.5 232H126q-30.938 0-52.969-22.031Q51-213.062 51-244v-391.5Q51-651 62-662t26.5-11q15.5 0 26.5 11t11 26.5V-244h627.5q15.5 0 26.5 11t11 26.5q0 15.5-11 26.5t-26.5 11ZM284-401v-315 315Z"
        fill="currentColor"
      />
    </svg>
  )
}
