import type { Network } from '@vaultsfyi/common'

import { Tile } from '@/components/atoms/Tile'
import {
  AddressStatistic,
  HoldersStatistic,
  ProtocolStatistic,
  TVLStatistic,
  TokenPriceStatistic,
} from '@/components/organisms/VaultStatistics'
import type { BackendVaultDetailed } from '@/types'

interface DetailsStatisticsProps {
  vaultData: BackendVaultDetailed
}

export const DetailsStatistics = ({ vaultData }: DetailsStatisticsProps) => {
  const { address, network, tvl, asset, metadata, assetPriceInUsd: tokenPriceWithPrecision, holdersCount } = vaultData

  return (
    <>
      <Tile variant="secondary" spacing="medium">
        <ProtocolStatistic metadata={metadata} />
      </Tile>
      <Tile variant="secondary" spacing="medium">
        <AddressStatistic address={address} network={network} />
      </Tile>
      <Tile variant="secondary" spacing="medium">
        <TVLStatistic tvl={tvl} asset={asset} network={network as Network} address={address} />
      </Tile>
      <Tile variant="secondary" spacing="medium">
        <TokenPriceStatistic tokenPriceWithPrecision={tokenPriceWithPrecision} />
      </Tile>
      <Tile variant="secondary" spacing="medium">
        <HoldersStatistic holders={holdersCount} />
      </Tile>
    </>
  )
}
