import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

import { HoldersChartLabel } from './HoldersChartLabel'
import { HoldersChartTooltip } from './HoldersChartTooltip'

import { ChartContainer } from '@/components/atoms/Charts/ChartContainer'
import { ChartWatermark } from '@/components/atoms/Charts/ChartWatermark'
import type { HoldersChartData } from '@/utils'
import { cn } from '@/utils/cn'

interface HoldersChartProps {
  chartData: HoldersChartData[]
  tvlInNativeAsset: boolean
}

const CHART_RADIUS = 112
const CHART_SIZE = CHART_RADIUS * 2
const REST_HOLDERS_PATTERN_KEY = 'rest-holders-pattern'

export const HoldersChart = ({ chartData, tvlInNativeAsset }: HoldersChartProps) => {
  return (
    <div className="relative mx-auto flex w-fit items-center justify-center">
      <ChartContainer className="aspect-square min-h-56 w-56">
        <ResponsiveContainer>
          <PieChart
            className={cn(
              '[&_.customChartCellLabel]:transition-opacity [&_.customChartCell]:transition-opacity',
              '[&_.clickableChartCell]:cursor-pointer',
              '[&_.recharts-pie:hover_.customChartCell:not(:hover)]:opacity-40 [&_.recharts-pie:hover_.customChartCellLabel]:opacity-40'
            )}
          >
            <defs>
              <pattern
                id={REST_HOLDERS_PATTERN_KEY}
                x="0"
                y="0"
                width={CHART_SIZE}
                height={CHART_SIZE}
                patternUnits="userSpaceOnUse"
                patternContentUnits="userSpaceOnUse"
              >
                <circle
                  cx={CHART_RADIUS}
                  cy={CHART_RADIUS}
                  r={CHART_RADIUS * 0.9}
                  fill={chartData[chartData.length - 1].color}
                />
              </pattern>
            </defs>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={CHART_RADIUS}
              dataKey="proportion"
              isAnimationActive={false}
              startAngle={90}
              endAngle={-270}
              label={(props) => <HoldersChartLabel {...props} />}
            >
              {chartData.map(({ explorerLink, color }, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={explorerLink ? color : `url(#${REST_HOLDERS_PATTERN_KEY})`}
                  className={`customChartCell ${explorerLink ? 'clickableChartCell' : ''}`}
                  stroke="transparent"
                  onClick={() => handleHolderCellClick(explorerLink)}
                />
              ))}
            </Pie>
            <Tooltip content={<HoldersChartTooltip tvlInNativeAsset={tvlInNativeAsset} />} />
          </PieChart>
        </ResponsiveContainer>
        <ChartWatermark variant="circle" />
      </ChartContainer>
    </div>
  )
}

function handleHolderCellClick(explorerLink: string | undefined) {
  if (explorerLink) {
    return window.open(`${explorerLink}`, '_blank')
  }
}
