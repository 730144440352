import { cn } from '@/utils/cn'
import type { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

export function Markdown(props: ReactMarkdownOptions) {
  return (
    <ReactMarkdown
      className={cn(
        'prose prose-sm max-w-full text-base',
        '[&_a]:text-actionPrimary [&_a]:hover:text-decorationPrimary [&_a]:focus-visible:text-decorationPrimary [&_a]:active:text-decorationPrimary'
      )}
      {...props}
      components={components}
    />
  )
}

const components: ReactMarkdownOptions['components'] = {
  a: (props) => <a {...props} target="_blank" />,
}
