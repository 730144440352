import { Input } from '@/components/atoms/Input'
import type { InputHTMLAttributes } from 'react'
import type { LabeledFieldProps } from '../LabeledField'
import { LabeledField } from '../LabeledField'

type LabeledInputFieldProps = Omit<LabeledFieldProps, 'children'> &
  InputHTMLAttributes<HTMLInputElement> & { className?: string }

export function LabeledInputField({ label, headerContent, id, className, ...props }: LabeledInputFieldProps) {
  return (
    <LabeledField id={id} label={label} headerContent={headerContent}>
      <Input {...props} className={className} id={id} name={id} />
    </LabeledField>
  )
}
