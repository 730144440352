import { trackEvent } from '../trackEvent'

import { SEND_EVENT_TIMEOUT } from '@/constants'
import { useDebounce } from '@/hooks'
import {
  DEFAULT_APY_MODE,
  DEFAULT_EXCLUDE_REWARDS,
  DEFAULT_PROTOCOL_CURATED,
} from '@/pages/YieldOptimizerPage/defaultValues'
import type { ApyModes } from '@/types'

interface InvestmentPanelTrackingProps {
  amount: number | undefined
  selectedTokens: string[]
  period: number | undefined
  excludeRewards: boolean
  protocolCuratedOnly: boolean
  apyMode: ApyModes
}

export const useTrackInvestmentPanelEvent = ({
  amount,
  selectedTokens,
  period,
  excludeRewards,
  protocolCuratedOnly,
  apyMode,
}: InvestmentPanelTrackingProps) => {
  useDebounce(
    () => {
      const amountChanged = amount !== 0
      const assetsChanged = selectedTokens.length > 0
      const periodChanged = period !== 0
      const rewardsChanged = excludeRewards !== DEFAULT_EXCLUDE_REWARDS
      const curatedChanged = protocolCuratedOnly !== DEFAULT_PROTOCOL_CURATED
      const apyModeChanged = apyMode !== DEFAULT_APY_MODE

      if (amountChanged || assetsChanged || periodChanged || rewardsChanged || apyModeChanged || curatedChanged) {
        trackEvent({
          event: 'Yield Search',
          params: {
            Tokens: selectedTokens.join(', '),
            Amount: amount ?? 'empty',
            Period: period ?? 'empty',
            Rewards: excludeRewards ? 'Excluded' : 'Included',
            Curated: protocolCuratedOnly ? 'Excluded' : 'Included',
            'APY Range': apyMode,
          },
        })
      }
    },
    [amount, selectedTokens, period, excludeRewards, protocolCuratedOnly, apyMode],
    SEND_EVENT_TIMEOUT
  )
}

export const trackInvestmentFiltersToggleEvent = (isFiltersOpen: boolean) => {
  trackEvent({ event: 'Toggle Investment Filters', params: { State: !isFiltersOpen ? 'Open' : 'Close' } })
}
