import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const CheckIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="m383-353 335-335q11.5-11.5 26.75-11.5T771.5-688q11.5 11.5 11.5 26.75t-11.5 26.75l-362 362Q398-261 383-261t-26.5-11.5l-168-168Q177-452 177.25-467.25T189-494q11.5-11.5 26.75-11.5T242.5-494L383-353Z"
        fill="currentColor"
      />
    </svg>
  )
}
