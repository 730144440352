import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { NavLink, useMatch } from 'react-router-dom'

import { Button } from '@/components/atoms/Button'
import { Checkbox } from '@/components/atoms/Checkbox'
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/atoms/Dialog'
import { Label } from '@/components/atoms/Label'
import { ScrollArea } from '@/components/atoms/ScrollArea'
import { routes } from '@/config'
import { ACCEPTED_DISCLAIMER_STORAGE_KEY, ACCEPTED_TERMS_OF_USE_STORAGE_KEY } from '@/constants'
import { getLocalStorageValue, setLocalStorageValue } from '@/utils'

interface DisclaimerProps {
  isHidden?: boolean
  trigger: ReactNode
}

export function Disclaimer({ isHidden, trigger }: DisclaimerProps) {
  const isTermsOfUsePage = !!useMatch(routes.termsOfService)
  const [isAgreed, setAgreed] = useState<boolean>(Boolean(getLocalStorageValue(ACCEPTED_TERMS_OF_USE_STORAGE_KEY)))
  const [isAccepted, setAccepted] = useState<boolean>(Boolean(getLocalStorageValue(ACCEPTED_DISCLAIMER_STORAGE_KEY)))
  const isDefaultOpen = isHidden ? false : !isTermsOfUsePage && (!isAccepted || !isAgreed)

  const handleAgreed = () => {
    setAgreed((prevState) => {
      if (prevState) setAccepted(false)
      return !prevState
    })
  }

  const handleAccept = () => {
    setLocalStorageValue(ACCEPTED_DISCLAIMER_STORAGE_KEY, true)
    setAccepted(true)
  }

  useEffect(() => {
    setLocalStorageValue(ACCEPTED_TERMS_OF_USE_STORAGE_KEY, isAgreed)
  }, [isAgreed])

  return (
    <Dialog defaultOpen={isDefaultOpen}>
      {trigger}
      <DialogContent
        onOpenAutoFocus={(event) => event.preventDefault()}
        onPointerDownOutside={(event) => !isAccepted && event.preventDefault()}
        onEscapeKeyDown={(event) => !isAccepted && event.preventDefault()}
      >
        <DialogHeader closable={isAccepted && isAgreed}>
          <DialogTitle className="text-2xl leading-none">Disclaimer</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <ScrollArea className="h-80">
            <div className="flex flex-col gap-4 px-4 py-2 leading-6">
              <p>
                This tool is being made available for educational purposes only and should not be used for any other
                purpose. The information contained herein does not constitute and should not be construed as an offering
                of advisory services or an offer to sell or solicitation to buy any securities or related financial
                instruments in any jurisdiction.
              </p>
              <p>
                Certain information contained herein is based on or derived from information provided by independent
                third-party sources. Wallfacer Labs believes that the sources from which such information has been
                obtained are reliable; however, it cannot guarantee the accuracy of such information and has not
                independently verified the accuracy or completeness of such information or the assumptions on which such
                information is based.
              </p>
              <p>
                We use cookies and similar technologies to collect information from your device, such as your IP address
                and the pages you’ve visited. We use this data to help us to better understand our audiences and to
                improve vaults.fyi.
              </p>
            </div>
          </ScrollArea>
        </DialogBody>
        <DialogFooter>
          <div className="flex flex-col gap-6">
            <Label>
              <Checkbox onCheckedChange={handleAgreed} checked={isAgreed} />
              <span>
                I agree to the{' '}
                <Button variant="text" asChild>
                  <NavLink to={routes.termsOfService} target="_blank">
                    Terms of Use
                  </NavLink>
                </Button>
                .
              </span>
            </Label>
            <DialogClose asChild>
              <Button className="w-full" onClick={handleAccept} disabled={!isAgreed}>
                I Understand
              </Button>
            </DialogClose>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
