import { TableCell, type TableCellProps } from '@/components/atoms/Table'
import type { VaultForList } from '@/types'
import { formatNumber } from '@/utils'

interface TvlCellProps extends Pick<VaultForList, 'tvlInUsd'> {
  testId?: string
}

export const TvlCell = ({ tvlInUsd, testId, ...props }: TvlCellProps & TableCellProps) => {
  return (
    <TableCell align="right" data-cell="TVL" {...props}>
      <span data-testid={`${testId}-tvl`}>${formatNumber(tvlInUsd ?? 0)}</span>
    </TableCell>
  )
}
