import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const InvisibleIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M638.5-428 584-482.5q11-54.242-27.75-92.621T462.5-604L408-658.5q17-7.75 35-11.625T480-674q72.5 0 123.25 50.75T654-500q0 19-3.875 37T638.5-428ZM762-305.5 708.5-358q38.161-29 68.288-64.5Q806.915-458 827-500q-49-100-142.5-158.5T480-717q-29 0-57.25 4.25T366.5-700L308-758.5q41.5-17 84.506-25.25Q435.511-792 480-792q133 0 244 70.5T893.5-532q4 7.5 5.75 15.51Q901-508.481 901-500q0 8.481-1.5 16.49-1.5 8.01-5 16.01-22.5 48.5-56.25 89.25T762-305.5ZM480-208q-131 0-239-71T69.928-465.19Q65-473 63-481.845 61-490.69 61-500q0-9.459 1.889-17.973T69.5-535q20.5-39.5 46.75-76T176-677l-83-86q-10.5-11.067-10-26.283Q83.5-804.5 94-815q10.5-10.5 26-10.5t26 10.5l667.5 667.5q11 11 11 26.5T813-94.5Q802-84 786.75-84.25T761-95L623.5-230.5q-35 11-70.835 16.75Q516.83-208 480-208ZM229-625q-30 26-54.5 57.25T133-500q49 100 142.5 158.5T480-283q21.207 0 41.353-2.5Q541.5-288 562-292l-38-40q-11 3-21.645 4.5T480-326q-72.5 0-123.25-50.75T306-500q0-11 1.5-21.5T312-543l-83-82Zm308.5 96Zm-142 70.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
