import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const ListIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M323-286.5q15.5 0 26.5-11t11-26.5q0-15.5-11-26.5t-26.5-11q-15.5 0-26.5 11t-11 26.5q0 15.5 11 26.5t26.5 11Zm0-156q15.5 0 26.5-11t11-26.5q0-15.5-11-26.5t-26.5-11q-15.5 0-26.5 11t-11 26.5q0 15.5 11 26.5t26.5 11Zm0-157q15.5 0 26.5-11t11-26.5q0-15.5-11-26.5t-26.5-11q-15.5 0-26.5 11t-11 26.5q0 15.5 11 26.5t26.5 11ZM478.5-286h159q15.5 0 26.5-11t11-26.5q0-15.5-11-26.5t-26.5-11h-159q-15.5 0-26.5 11t-11 26.5q0 15.5 11 26.5t26.5 11Zm0-157h159q15.5 0 26.5-11t11-26.5q0-15.5-11-26.5t-26.5-11h-159q-15.5 0-26.5 11t-11 26.5q0 15.5 11 26.5t26.5 11Zm0-156h159q15.5 0 26.5-11t11-26.5q0-15.5-11-26.5t-26.5-11h-159q-15.5 0-26.5 11t-11 26.5q0 15.5 11 26.5t26.5 11ZM204-129q-30.938 0-52.969-22.031Q129-173.062 129-204v-552q0-30.938 22.031-52.969Q173.062-831 204-831h552q30.938 0 52.969 22.031Q831-786.938 831-756v552q0 30.938-22.031 52.969Q786.938-129 756-129H204Zm0-75h552v-552H204v552Zm0-552v552-552Z"
        fill="currentColor"
      />
    </svg>
  )
}
