import type { DbWarning } from '@vaultsfyi/common'

import { WarningEditor } from './WarningEditingWrapper'

interface WarningListProps {
  warnings: DbWarning[]
  updateWarning: (warning: DbWarning) => void
  deleteWarning: (warning: DbWarning) => void
  revalidate: () => void
}

export const WarningList = ({ warnings, revalidate, updateWarning, deleteWarning }: WarningListProps) => {
  return (
    <div>
      {warnings.map((warning) => (
        <WarningEditor
          key={warning.id}
          {...warning}
          revalidate={revalidate}
          warningId={warning.id}
          updateWarning={updateWarning}
          deleteWarning={deleteWarning}
        />
      ))}
    </div>
  )
}
