import { cn } from '@/utils/cn'
import { getSubdomain } from '@/utils/subdomain'
import { type VariantProps, cva } from 'class-variance-authority'
import type { HTMLAttributes } from 'react'
import { Logo } from '../Logo'

const watermarkVariants = cva(
  'pointer-events-none absolute flex select-none items-center gap-2 font-bold text-textPrimary text-xl opacity-15 grayscale',
  {
    variants: {
      variant: {
        square: 'right-1 bottom-8 md:right-4 md:bottom-10',
        circle: 'right-2 bottom-2',
        miniature: 'right-2 bottom-2',
      },
    },
    defaultVariants: {
      variant: 'square',
    },
  }
)

export type ChartWatermarkType = VariantProps<typeof watermarkVariants>

export const ChartWatermark = ({ variant, className }: HTMLAttributes<HTMLDivElement> & ChartWatermarkType) => {
  const subdomain = getSubdomain()

  return (
    <div className={cn(watermarkVariants({ className, variant }))}>
      <Logo className="size-5" />
      <span className="-translate-y-1 opacity-75">{subdomain && `${subdomain.prefix}.`}vaults.fyi</span>
    </div>
  )
}
