import { Fragment } from 'react'

import { Loader, Page } from '@/components/molecules'
import { Footer, Header } from '@/components/organisms'

export const FallbackPage = () => {
  return (
    <Fragment>
      <Header hasLayoutRole />
      <Page title="Loading">
        <Loader />
      </Page>
      <Footer />
    </Fragment>
  )
}
