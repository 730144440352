import { Fragment } from 'react'

import bankEmoji from '/images/emojis/bankEmoji.png'
import magnifyingGlassEmoji from '/images/emojis/magnifyingGlassEmoji.png'

import { Image } from '@/components/atoms/Image'
import { Page } from '@/components/molecules'
import { Footer, Header } from '@/components/organisms'

export const NotFoundPage = () => {
  return (
    <Fragment>
      <Header />
      <Page title="Not found">
        <div className="flex flex-col items-center gap-4 py-16 text-center">
          <div className="relative mb-4">
            <Image className="repeat-infinite size-20 animate-404-bank rounded-none" src={bankEmoji} loading="lazy" />
            <Image
              className="repeat-infinite absolute top-0 left-0 size-14 animate-404-glass rounded-none"
              src={magnifyingGlassEmoji}
              loading="lazy"
            />
          </div>
          <h1 className="text-3xl leading-none sm:text-4xl">Page can't be found</h1>
          <p className="text-xl">The address is not correct or page may have been moved.</p>
        </div>
      </Page>
      <Footer />
    </Fragment>
  )
}
