import { formatBips } from '@vaultsfyi/common'

import { ReturnsTile } from './ReturnsTile'

import { getValueIndicatorView } from '@/components/atoms/ValueIndicator'
import type { VaultForOptimizer } from '@/types'
import { formatNumber, getPeriodText, isUndefined } from '@/utils'

interface VaultExpandedStatisticsProps {
  vault: VaultForOptimizer
  period: number | undefined
  isHighTvlImpact?: boolean
}

export function VaultExpandedStatistics({ vault, period, isHighTvlImpact }: VaultExpandedStatisticsProps) {
  const {
    apy,
    rewards,
    rawYieldInToken,
    rewardsApy,
    txCostsInNativeCurrency,
    annualPercentageLossOnTxCosts,
    nativeCurrency,
    rawApy,
    yieldInUsd,
    asset,
  } = vault

  if (isUndefined(apy) || isUndefined(yieldInUsd)) return null

  const returnsMainContent = `${yieldInUsd > 0 ? '+' : ''}$${formatNumber(yieldInUsd)}`
  const returnsBaseYieldText = rawYieldInToken ? `${formatNumber(rawYieldInToken)} ${asset.symbol}` : undefined
  const returnsRewardsText = rewards
    ?.map(({ asset, yieldInToken }) => `${formatNumber(yieldInToken)} ${asset.symbol}`)
    .join('\n')
  const returnsTransactionCostsText =
    txCostsInNativeCurrency === undefined
      ? 'n/a'
      : txCostsInNativeCurrency < 0.00001
        ? `<0.00001 ${nativeCurrency.toUpperCase()}`
        : `${formatNumber(txCostsInNativeCurrency, 0, 5)} ${nativeCurrency.toUpperCase()}`

  const apyMainContent = `${apy > 0 ? '+' : ''}${formatBips(apy)}`
  const baseApyText = rawApy ? `${formatBips(rawApy)}` : undefined
  const rewardsApyText = rewardsApy ? `${formatBips(rewardsApy)}` : undefined
  const txCostsApyText = annualPercentageLossOnTxCosts === 0 ? '<0.01%' : formatBips(annualPercentageLossOnTxCosts)

  const periodText = period ? getPeriodText(period) : ''
  const returnsValueIndicator = isHighTvlImpact ? 'mute' : getValueIndicatorView(yieldInUsd)

  return (
    <>
      <ReturnsTile
        headerText={`Est. Return for ${periodText}`}
        tileMainContent={returnsMainContent}
        baseYieldText={returnsBaseYieldText}
        rewardsText={returnsRewardsText}
        txCostsText={returnsTransactionCostsText}
        valueIndicatorView={returnsValueIndicator}
        isHighTvlImpact={isHighTvlImpact}
      />
      <ReturnsTile
        headerText="Est. APY"
        tileMainContent={apyMainContent}
        baseYieldText={baseApyText}
        rewardsText={rewardsApyText}
        txCostsText={txCostsApyText}
        valueIndicatorView={returnsValueIndicator}
        isHighTvlImpact={isHighTvlImpact}
      />
    </>
  )
}
