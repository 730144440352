import { copyToClipboard } from './copyToClipboard'

interface ShareButtonReturn {
  isShareApiSupported: boolean
}

export const sharePage = async ({ title, text, url }: ShareData): Promise<ShareButtonReturn> => {
  try {
    if (navigator.share) {
      await navigator.share({ title, text, url })
      return { isShareApiSupported: true }
    } else {
      copyToClipboard(`${title} | ${text}: ${url}`)
      return { isShareApiSupported: false }
    }
  } catch (error) {
    console.error('Failed to share data:', error)
    return { isShareApiSupported: false }
  }
}
