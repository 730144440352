export type ObjectEndpoints<T> = {
  [K in keyof Required<T>]: T[K] extends object
    ? T[K] extends Array<any>
      ? never
      : [K, ...ObjectEndpoints<Required<T[K]>>]
    : [K]
}[keyof T]

export type WalkObject<T extends object, Keys> = Keys extends [infer Key, ...infer Rest]
  ? Key extends keyof T
    ? Rest extends []
      ? T[Key]
      : Rest extends string[]
        ? T[Key] extends object
          ? WalkObject<T[Key], Rest>
          : never
        : never
    : never
  : never

/**
 * @dev Get primitive values from provider VaultData object based on provided keys. If value is undefined, return default value.
 *      Examples:
 *      - ['apy'] -> a.apy
 *      - ['asset', 'symbol'] -> a.asset.symbol
 */
export function getPrimitiveValues<T extends object, Keys extends ObjectEndpoints<T>>(
  a: T,
  b: T,
  keys: Keys
): [WalkObject<T, Keys> | undefined, WalkObject<T, Keys> | undefined] {
  let valueA: any = a
  let valueB: any = b

  for (const key of keys) {
    valueA = valueA?.[key]
    valueB = valueB?.[key]
  }

  return [valueA, valueB]
}
