const CHART_COLORS = {
  red: 'var(--explorer-red)',
  green: 'var(--explorer-green)',
  blue: 'var(--explorer-blue)',
  orange: 'var(--explorer-orange)',
  turquoise: 'var(--explorer-turquoise)',
  purple: 'var(--explorer-purple)',
  yellow: 'var(--explorer-yellow)',
  cyan: 'var(--explorer-cyan)',
  pink: 'var(--explorer-pink)',
  gray: 'var(--explorer-gray)',
}

export function getChartColor(index: number, opacity?: string): string {
  const opacityValue = opacity ? `/ ${opacity}` : ''

  return `hsl(${Object.values(CHART_COLORS)[index % Object.keys(CHART_COLORS).length]}${
    opacityValue && ` ${opacityValue}`
  })`
}
