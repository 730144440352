import { trpc } from '@/config/trpc'
import type { ApyModes, BackendVaultDetailed, BackendVaultDetailedWithChartData } from '@/types'
import { transformVault } from '@/utils'

interface VaultQueryParams {
  address: string
  chainName: string
  interval: ApyModes
}

export const useVault = ({ address, chainName, interval }: VaultQueryParams): BackendVaultDetailed | undefined => {
  const { data: vault } = trpc.getVault.useQuery({ address, chainName, interval }, { suspense: false })
  const transformedVault = transformVault(vault)
  return transformedVault
}

export const useVaultWithSuspense = ({ address, chainName, interval }: VaultQueryParams): BackendVaultDetailed => {
  const [vault] = trpc.getVault.useSuspenseQuery(
    { address, chainName, interval },
    {
      retry: (count, error) => {
        if (error.data?.code === 'NOT_FOUND') {
          return false
        }
        return count < 3
      },
      retryDelay: 200,
    }
  )
  const transformedVault = transformVault(vault)
  return transformedVault
}

export const useVaults = (vaults: VaultQueryParams[]): BackendVaultDetailedWithChartData[] => {
  const vaultQueries = trpc.useQueries((t) =>
    vaults.map(({ address, chainName, interval }) =>
      t.getVault({ address, chainName, interval }, { suspense: false, select: (data) => transformVault(data) })
    )
  )

  const chartDataQueries = trpc.useQueries((t) =>
    vaults.map(({ address, chainName, interval }) =>
      t.getChartData({ address, chainName, interval }, { suspense: false })
    )
  )

  return vaultQueries.flatMap(({ data }, index) => {
    if (!data) {
      return []
    }

    const chartData = chartDataQueries[index].data ?? []
    return { ...data, chartData }
  })
}
