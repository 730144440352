import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import type { BackendVault } from '@/types'
import { CHAINLINK_PRECISION } from '@vaultsfyi/common'

interface Props {
  tokenPriceWithPrecision: BackendVault['assetPriceInUsd']
}

export const TokenPriceStatistic = ({ tokenPriceWithPrecision }: Props) => {
  const numericPriceInUsd = Number(tokenPriceWithPrecision) / CHAINLINK_PRECISION
  const { minimumFractionDigits, maximumFractionDigits } = getPricePrecision(numericPriceInUsd)
  const tokenPrice = formatPrice(numericPriceInUsd, minimumFractionDigits, maximumFractionDigits)

  return (
    <div>
      <LabeledValueHeader>Token price</LabeledValueHeader>
      <LabeledValueContent>
        <LabeledValueValue>
          <span>{tokenPrice} USD</span>
        </LabeledValueValue>
      </LabeledValueContent>
    </div>
  )
}

function formatPrice(value: number, minimumFractionDigits: number, maximumFractionDigits: number) {
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value)
}

function getPricePrecision(price: number): { minimumFractionDigits: number; maximumFractionDigits: number } {
  switch (true) {
    case price < 1:
      return { minimumFractionDigits: 2, maximumFractionDigits: 4 }
    case price < 10:
      return { minimumFractionDigits: 0, maximumFractionDigits: 2 }
    case price >= 10:
    default:
      return { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  }
}
