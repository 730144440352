import { Button } from '@/components/atoms/Button'
import { EditNoteIcon } from '@/components/atoms/Icons'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/atoms/Tooltip'

export const EditVaultButton = ({ href }: { href: string }) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="icon" asChild>
          <a href={href}>
            <EditNoteIcon size={24} />
          </a>
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <p>Edit vault data</p>
      </TooltipContent>
    </Tooltip>
  )
}
