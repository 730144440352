import { type VariantProps, cva } from 'class-variance-authority'
import type { ComponentPropsWithoutRef } from 'react'

export const iconVariants = cva('block', {
  variants: {
    size: {
      8: 'min-h-2 min-w-2',
      12: 'min-h-3 min-w-3',
      16: 'min-h-4 min-w-4',
      20: 'min-h-5 min-w-5',
      24: 'min-h-6 min-w-6',
    },
  },
})

export type IconSize = NonNullable<VariantProps<typeof iconVariants>['size']>

export interface IconProps extends ComponentPropsWithoutRef<'svg'>, VariantProps<typeof iconVariants> {
  size?: IconSize
}
