type DeviceType = 'Android' | 'iOS' | 'another'

export const getDeviceType = (): DeviceType => {
  if (/Android/i.test(navigator.userAgent)) {
    return 'Android'
  }
  if (/iPhone/i.test(navigator.userAgent)) {
    return 'iOS'
  }
  return 'another'
}
