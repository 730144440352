import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const DotsIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M228-402q-32.5 0-55.25-22.75T150-480q0-32.5 22.75-55.25T228-558q32.5 0 55.25 22.75T306-480q0 32.5-22.75 55.25T228-402Zm252 0q-32.5 0-55.25-22.75T402-480q0-32.5 22.75-55.25T480-558q32.5 0 55.25 22.75T558-480q0 32.5-22.75 55.25T480-402Zm252 0q-32.5 0-55.25-22.75T654-480q0-32.5 22.75-55.25T732-558q32.5 0 55.25 22.75T810-480q0 32.5-22.75 55.25T732-402Z"
        fill="currentColor"
      />
    </svg>
  )
}
