import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'

interface Props {
  holders: number | undefined
}

export const HoldersStatistic = ({ holders }: Props) => {
  return (
    <div>
      <LabeledValueHeader title="Number of holders"># of holders</LabeledValueHeader>
      <LabeledValueContent>
        <LabeledValueValue>
          <span>{holders ?? 'N/A'}</span>
        </LabeledValueValue>
      </LabeledValueContent>
    </div>
  )
}
