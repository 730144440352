import type { ApyModes } from '@/types'

export const getChartApyText = (apyMode: ApyModes) => {
  switch (apyMode) {
    case '1day':
      return 'APY (1 day avg.)'
    case '7day':
      return 'APY (7 days avg.)'
    case '30day':
      return 'APY (30 days avg.)'
  }
}

export const getChartBaseApyItemDescriptor = (apyMode: ApyModes) => {
  return `Base (${getShortApyModeText(apyMode)})`
}

export const getChartRewardsApyItemDescriptor = (apyMode: ApyModes) => {
  return `Rewards (${getShortApyModeText(apyMode)})`
}

export const getShortApyModeText = (mode: ApyModes) => {
  switch (mode) {
    case '1day':
      return '1d'
    case '7day':
      return '7d'
    case '30day':
      return '30d'
  }
}
