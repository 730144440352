import type { TvlThreshold } from '@vaultsfyi/common'

export const DEFAULT_TVL_THRESHOLD: TvlThreshold = {
  usd: 100_000,
  eth: 32,
}

export const YEARN_TVL_THRESHOLD: TvlThreshold = {
  usd: 1_000,
}
