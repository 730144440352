import { Image } from '@/components/atoms/Image'
import { TableCell, type TableCellProps } from '@/components/atoms/Table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/atoms/Tooltip'
import type { VaultForList } from '@/types'
import { getVaultProtocolImage } from '@/utils'

interface ProtocolCellProps extends Pick<VaultForList, 'protocolName'> {
  testId?: string
}

export const ProtocolCell = ({ protocolName, testId, ...props }: ProtocolCellProps & TableCellProps) => {
  return (
    <TableCell data-cell="Protocol" data-testid={`${testId}-protocol-name`} data-testvalue={protocolName} {...props}>
      <Tooltip>
        <TooltipTrigger>
          <Image
            src={getVaultProtocolImage(protocolName)}
            alt={`${protocolName} protocol image`}
            title={`${protocolName} protocol image`}
            loading="lazy"
          />
        </TooltipTrigger>
        <TooltipContent>
          <p>
            <b>Vault protocol:</b> <span className="inline-block first-letter:uppercase">{protocolName}</span>
          </p>
        </TooltipContent>
      </Tooltip>
    </TableCell>
  )
}
