import { useState } from 'react'

import { Accordion } from '@/components/atoms/Accordion'
import { LoadMoreButton } from '@/components/molecules/LoadMoreButton'
import type { InvestmentData } from '@/components/organisms/VaultItem'
import type { ApyModes, VaultForOptimizer } from '@/types'
import { trackEvent } from '@/utils'
import { OptimizerVaultItem } from './OptimizerVaultItem'

type OptimizerVaultsProps = {
  vaults: VaultForOptimizer[]
  apyMode: ApyModes
} & InvestmentData

const INITIAL_LIST_SIZE = 3

export const OptimizerVaults = ({ vaults, ...props }: OptimizerVaultsProps) => {
  const [listSize, setListSize] = useState(INITIAL_LIST_SIZE)
  const loadMoreHandler = () => {
    setListSize((prev) => prev + INITIAL_LIST_SIZE)
    trackEvent({ event: 'Load more vaults on Optimizer' })
  }
  const moreVaultsToLoad = listSize < vaults.length

  return (
    <>
      <Accordion type="multiple" className="flex flex-col gap-4">
        {vaults.slice(0, listSize).map((vault, index) => (
          <OptimizerVaultItem key={getVaultKey(vault)} vault={vault} {...props} topItem={index === 0} />
        ))}
      </Accordion>
      {moreVaultsToLoad && <LoadMoreButton onClick={loadMoreHandler} />}
    </>
  )
}

function getVaultKey(vault: VaultForOptimizer) {
  return vault.network + vault.address
}
