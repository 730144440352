import type { ApyChartDescriptorKey } from '@/types'

const RANGE_APY_STACK_ID = 1
const RAW_APY_STACK_ID = 2

export function getApyChartDescriptorStackId(key: ApyChartDescriptorKey) {
  switch (key) {
    case 'rangeApy':
      return RANGE_APY_STACK_ID
    case 'rawApy':
      return RAW_APY_STACK_ID
    case 'rewardsApy':
      return RANGE_APY_STACK_ID
  }
}
