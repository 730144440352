import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const UndoIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M322.5-210q-15.5 0-26.5-11t-11-26.5q0-15.5 11-26.5t26.5-11H570q59.5 0 102.25-39.5T715-422q0-58-42.75-97.5T570-559H313l77 77q11 11 11 26.5T390-429q-11 10.5-26.25 10.75T337.5-429l-141-141q-5.5-5.5-8.25-12.25t-2.75-14.25q0-7.5 2.75-14.25T196.5-623L337-763.5q11-11 26.5-11t26.5 11q11 11 11 26.5t-11 26.5L313-634h257q91 0 155.5 61.25T790-422q0 89.5-64.5 150.75T570-210H322.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
