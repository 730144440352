import { BookmarkedVaults } from './components'

import { Page } from '@/components/molecules'
import { usePageTracking } from '@/hooks'

export const Component = () => {
  usePageTracking()

  return (
    <Page title="Favorites">
      <BookmarkedVaults />
    </Page>
  )
}
