import { ScrollArea, ScrollBar } from '@/components/atoms/ScrollArea'
import { Table, TableBody } from '@/components/atoms/Table'
import { useVaultsSortState } from '@/hooks'
import type { VaultProtocol } from '@/hooks/list/useProtocols'
import { useIsMobile } from '@/hooks/useIsMobile'
import { ProtocolsListRow } from './ProtocolsListRow'
import { ProtocolsTableHeader } from './ProtocolsTableHeader'

interface Props {
  protocols: VaultProtocol[]
}

export const ProtocolsTable = ({ protocols }: Props) => {
  const isMobile = useIsMobile()

  if (isMobile) {
    return <TableComponent protocols={protocols} />
  }

  return (
    <ScrollArea className="w-full rounded-2xl">
      <TableComponent protocols={protocols} />
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  )
}

const TableComponent = ({ protocols }: Props) => {
  const { sortState, handleSetSortBy } = useVaultsSortState()

  return (
    <Table>
      <ProtocolsTableHeader setSortBy={handleSetSortBy} sortBy={sortState} />
      <TableBody>
        {protocols.map((protocol, index) => (
          <ProtocolsListRow protocol={protocol} key={`${protocol.name}-${index}`} />
        ))}
      </TableBody>
    </Table>
  )
}
