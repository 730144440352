import { arrayIncludes } from '@/utils'
import { NETWORKS, type VaultFilters } from '@vaultsfyi/common'
import { DEFAULT_TVL_THRESHOLD, YEARN_TVL_THRESHOLD } from './threshold'

const PROTOCOLS_FILTERS: Record<string, VaultFilters> = {
  default: {
    onlyFeatured: true,
    tvlThreshold: DEFAULT_TVL_THRESHOLD,
  },
  yearn: {
    protocol: { name: 'yearn', version: 'v3' },
    tvlThreshold: YEARN_TVL_THRESHOLD,
    excludeZeroMaxDeposit: true,
  },
  stakewise: {
    protocol: { name: 'stakewise' },
  },
} as const

export const SUPPORTED_PROTOCOL_SUBDOMAINS = Object.keys(PROTOCOLS_FILTERS) as Array<keyof typeof PROTOCOLS_FILTERS>
export type ProtocolName = keyof typeof PROTOCOLS_FILTERS

export function getBackendFilters(subdomainName: string | undefined): VaultFilters {
  if (arrayIncludes(NETWORKS, subdomainName)) {
    return { ...PROTOCOLS_FILTERS.default, chainName: subdomainName }
  }
  if (arrayIncludes(SUPPORTED_PROTOCOL_SUBDOMAINS, subdomainName)) {
    return PROTOCOLS_FILTERS[subdomainName]
  }
  return PROTOCOLS_FILTERS.default
}
