import { SELECTED_PROTOCOLS_KEY } from '@/constants/stateConstants'
import { useVaultsSearchParams } from '@/hooks'
import type { BackendVault } from '@/types'
import { getVaultProtocolImage } from '@/utils'
import { useMemo } from 'react'
import { SelectFilter } from './SelectFilter'

interface SelectProtocolsFilterProps {
  vaults: Array<BackendVault>
}

export function SelectProtocolsFilter({ vaults }: SelectProtocolsFilterProps) {
  const { selectedProtocols, updateSearchState } = useVaultsSearchParams()
  const protocols = useMemo(() => [...new Set(vaults.map((vault) => vault.protocolName))], [vaults])

  return (
    <SelectFilter
      items={protocols}
      selectedItems={selectedProtocols}
      setSelectedItems={(selectedProtocols) => updateSearchState([SELECTED_PROTOCOLS_KEY, selectedProtocols])}
      name={'protocol'}
      dropdownPrefix="Filter by"
      imageGetter={getVaultProtocolImage}
      capitalize
    />
  )
}
