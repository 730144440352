import type { ReactNode } from 'react'

import { PageContainer } from '@/components/atoms/Container'
import { PageContent } from '@/components/atoms/PageContent'
import { PageHelmet } from '@/components/atoms/PageHelmet'
import { SubdomainBanner } from '@/components/organisms/SubdomainBanner'
import { getSubdomain } from '@/utils/subdomain'

interface PageProps {
  children: ReactNode
  title: string
}

export function Page({ children, title }: PageProps) {
  const subdomain = getSubdomain()

  return (
    <>
      <PageHelmet text={title} />
      {subdomain && <SubdomainBanner subdomain={subdomain} />}
      <PageContent>
        <PageContainer>{children}</PageContainer>
      </PageContent>
    </>
  )
}
