import { isUndefined } from './isUndefined'

export const safeNumber = (
  value: string | number | undefined,
  defaultValue: number | undefined = undefined
): number | undefined => {
  if (isUndefined(value) || value === '') {
    return defaultValue
  }
  const numberValue = Number(value)
  return Number.isNaN(numberValue) ? defaultValue : numberValue
}
