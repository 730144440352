import type { ChangeEvent, ComponentProps } from 'react'
import { useCallback, useState } from 'react'

import { Input } from '@/components/atoms/Input'
import { safeNumber } from '@/utils'

interface NumberInputProps extends ComponentProps<typeof Input> {
  setValue: (val: number | undefined) => void
  value: number | undefined
  maxValue?: number | undefined
}

export function NumberInput({ setValue, value, maxValue, ...props }: NumberInputProps) {
  const [textValue, setTextValue] = useState(value?.toString() ?? '')

  const onChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (value === '') {
        setTextValue('')
        setValue(undefined)
        return
      }
      const numberValue = safeNumber(value)
      if (numberValue === undefined) {
        return
      }
      if (maxValue && numberValue >= maxValue) {
        setValue(maxValue)
        setTextValue(maxValue.toString())
        return
      }
      if (numberValue >= 0) {
        setValue(numberValue > 0 ? numberValue : undefined)
        setTextValue(value)
      }
    },
    [setValue, maxValue]
  )

  return <Input value={textValue} onChange={onChange} inputMode="numeric" {...props} />
}
