import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const WarningIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M118-129q-10.5 0-19-5.25T85.5-148q-5-8.5-5.25-18.25t5.25-19.25l362-624.5q5.5-9.5 14.25-14t18.25-4.5q9.5 0 18.25 4.5t14.25 14l362 624.5q5.5 9.5 5.25 19.25T874.5-148q-5 8.5-13.5 13.75T842-129H118Zm65-75h594L480-716 183-204Zm297-41q16 0 27-11t11-27q0-16-11-27t-27-11q-16 0-27 11t-11 27q0 16 11 27t27 11Zm.5-115q15.5 0 26.5-11t11-26.5v-122q0-15.5-11-26.5t-26.5-11q-15.5 0-26.5 11t-11 26.5v122q0 15.5 11 26.5t26.5 11Zm-.5-100Z"
        fill="currentColor"
      />
    </svg>
  )
}
