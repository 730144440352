import { Button, type ButtonProps } from '@/components/atoms/Button'
import { ArrowIcon } from '@/components/atoms/Icons'
import { cn } from '@/utils/cn'

export const LoadMoreButton = ({ className, ...props }: ButtonProps) => {
  return (
    <Button className={cn('m-auto items-baseline text-textPrimary underline', className)} variant="text" {...props}>
      Load more
      <ArrowIcon direction="down" />
    </Button>
  )
}
