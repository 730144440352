import type { Network } from '@vaultsfyi/common'

export interface VaultsBenchmark {
  name: string
  vaults: {
    address: string
    network: Network
  }[]
  asset: {
    symbol: string
  }
}

export const BENCHMARKS: Record<string, VaultsBenchmark> = {
  usd: {
    name: 'vaults.fyi USD benchmark rate',
    vaults: [
      {
        address: '0x98C23E9d8f34FEFb1B7BD6a91B7FF122F4e16F5c', // Aave v3 USDC
        network: 'mainnet',
      },
      {
        address: '0x23878914EFE38d27C4D67Ab83ed1b93A74D4086a', // Aave v3 USDT
        network: 'mainnet',
      },
      {
        address: '0x83F20F44975D03b1b09e64809B757c47f942BEeA', // sDAI
        network: 'mainnet',
      },
      {
        address: '0xc3d688B66703497DAA19211EEdff47f25384cdc3', // Compound v3 USDC
        network: 'mainnet',
      },
    ],
    asset: {
      symbol: 'USD',
    },
  },
  eth: {
    name: 'vaults.fyi ETH benchmark rate',
    vaults: [
      {
        address: '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84', // Lido stETH
        network: 'mainnet',
      },
      {
        address: '0x35fA164735182de50811E8e2E824cFb9B6118ac2', // ether.fi eETH
        network: 'mainnet',
      },
      {
        address: '0xBe9895146f7AF43049ca1c1AE358B0541Ea49704', // Coinbase cbETH
        network: 'mainnet',
      },
      {
        address: '0xae78736Cd615f374D3085123A210448E74Fc6393', // Rocket Pool rETH
        network: 'mainnet',
      },
    ],
    asset: {
      symbol: 'ETH',
    },
  },
}
