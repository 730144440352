import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const ShareIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M716.882-89q-48.382 0-82.132-33.833Q601-156.667 601-205q0-6.964 1-14.424 1-7.461 3-13.43L324-396q-16.5 15.5-36.952 23.75-20.452 8.25-42.852 8.25-48.696 0-82.446-33.979T128-480.5q0-48.125 33.833-81.812Q195.667-596 244-596q22.646 0 43.323 8T324-565l281-163.146q-2-5.969-3-13.43-1-7.46-1-14.424 0-48.333 33.868-82.167Q668.735-872 717.118-872q48.382 0 82.132 33.688Q833-804.625 833-756.5q0 48.542-33.833 82.521Q765.333-640 717-640q-22.469 0-42.985-8.25Q653.5-656.5 637-672L356-509q2 6.286 3 14.143 1 7.857 1 14.822 0 6.965-1 14.427t-3 13.432L637-289q16.5-15.5 36.952-23.75 20.452-8.25 42.852-8.25 48.696 0 82.446 33.979T833-204.5q0 48.125-33.868 81.812Q765.265-89 716.882-89ZM717-715q17 0 29-12t12-29q0-17-12-29t-29-12q-17 0-29 12t-12 29q0 17 12 29t29 12ZM244-439q17 0 29-12t12-29q0-17-12-29t-29-12q-17 0-29 12t-12 29q0 17 12 29t29 12Zm473 275q17 0 29-12t12-29q0-17-12-29t-29-12q-17 0-29 12t-12 29q0 17 12 29t29 12Zm0-592ZM244-480Zm473 275Z"
        fill="currentColor"
      />
    </svg>
  )
}
