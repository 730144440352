import { VaultsList } from './components'

import { Page } from '@/components/molecules'
import { Benchmarks } from '@/components/molecules/Benchmarks'
import { usePageTracking } from '@/hooks'
import { getSubdomain } from '@/utils/subdomain'
import { VaultsListHero } from './components/VaultsListHero'

export function Component() {
  usePageTracking()
  const subdomain = getSubdomain()

  return (
    <Page title="Vaults">
      <div className="mx-auto mb-4 flex flex-col flex-wrap items-center justify-between gap-x-4 gap-y-2 text-center lg:flex-row">
        <VaultsListHero subdomain={subdomain} />
        <Benchmarks />
      </div>
      <VaultsList />
    </Page>
  )
}
