import type { ReactNode } from 'react'

import type { SortBySchema, VaultListColumn } from '@/pages/VaultsListPage/types'
import { isVaultSortableColumn } from '@/pages/VaultsListPage/types'

import { DocumentIcon, GlobeIcon } from '@/components/atoms/Icons'
import { TableHead, type TableHeadProps, TableHeader, TableRow } from '@/components/atoms/Table'
import { TableSortButton, type TableSortButtonProps } from '@/components/atoms/TableSortButton'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/atoms/Tooltip'
import { useIsMobile } from '@/hooks/useIsMobile'
import type { ApyModes } from '@/types'
import type { Subdomain } from '@/types/Subdomain'
import { getColumnAlign, getColumnSize, getFilteredTableColumns, getShortApyModeText } from '@/utils'
import { cn } from '@/utils/cn'

interface VaultsHeaderProps {
  sortBy: SortBySchema
  setSortBy: (val: SortBySchema['sortBy']) => void
  subdomain: Subdomain | undefined
  apyMode: ApyModes
}

export function VaultsTableHeader({ subdomain, ...props }: VaultsHeaderProps) {
  const isMobile = useIsMobile()
  const filteredColumns = getFilteredTableColumns({ subdomain, isMobile })

  return (
    <TableHeader className="hidden sm:table-header-group">
      <TableRow>{filteredColumns.map((column) => getColumnHeader({ column, subdomain, ...props }))}</TableRow>
    </TableHeader>
  )
}

interface HybridColumnHeaderProps extends VaultsHeaderProps {
  column: VaultListColumn
}

function getColumnHeader({ column, ...props }: HybridColumnHeaderProps) {
  if (isVaultSortableColumn(column)) {
    return (
      <SortColumnHeader
        key={column}
        name={column}
        align={getColumnAlign(column)}
        className={getColumnSize(column)}
        {...props}
      />
    )
  }

  return <TableHead key={column} align={getColumnAlign(column)} className={getColumnSize(column)} />
}

interface SortColumnVaultsHeaderProps {
  sortBy: SortBySchema
  setSortBy: (val: SortBySchema['sortBy']) => void
  name: SortBySchema['sortBy']
  apyMode: ApyModes
}

function SortColumnHeader({
  sortBy,
  name,
  align,
  setSortBy,
  apyMode,
  className,
}: SortColumnVaultsHeaderProps & TableHeadProps) {
  const isActive = sortBy.sortBy === name

  return (
    <TableHead align={align} className={cn('hidden sm:table-cell', className)}>
      {getColumnHeaderElement({ sortBy, setSortBy, name, apyMode, isActive, asc: sortBy.asc })}
    </TableHead>
  )
}

function getColumnHeaderElement({
  setSortBy,
  name,
  apyMode,
  isActive,
  asc,
}: SortColumnVaultsHeaderProps & TableSortButtonProps): ReactNode {
  switch (name) {
    case 'network':
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <TableSortButton onClick={() => setSortBy(name)} isActive={isActive} asc={asc}>
              <GlobeIcon />
            </TableSortButton>
          </TooltipTrigger>
          <TooltipContent>
            <p>Network</p>
          </TooltipContent>
        </Tooltip>
      )
    case 'protocol':
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <TableSortButton onClick={() => setSortBy(name)} isActive={isActive} asc={asc}>
              <DocumentIcon />
            </TableSortButton>
          </TooltipTrigger>
          <TooltipContent>
            <p>Protocol</p>
          </TooltipContent>
        </Tooltip>
      )
    case 'tvl':
      return (
        <TableSortButton onClick={() => setSortBy(name)} isActive={isActive} asc={asc}>
          <span>{name.toUpperCase()}</span>
        </TableSortButton>
      )
    case 'apy':
      return (
        <TableSortButton onClick={() => setSortBy(name)} isActive={isActive} asc={asc}>
          <span>
            {name.toUpperCase()} ({getShortApyModeText(apyMode)} avg.)
          </span>
        </TableSortButton>
      )

    default:
      return (
        <TableSortButton onClick={() => setSortBy(name)} isActive={isActive} asc={asc}>
          <span>{name}</span>
        </TableSortButton>
      )
  }
}
