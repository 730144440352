import { TableRow } from '@/components/atoms/Table'
import type { VaultProtocol } from '@/hooks/list/useProtocols'
import { isSafari } from '@/utils'
import { cn } from '@/utils/cn'
import { ProtocolNameCell } from './ProtocolNameCell'

interface ProtocolsListRowProps {
  protocol: VaultProtocol
  testId?: string
}

export const ProtocolsListRow = ({ protocol, testId }: ProtocolsListRowProps) => {
  return (
    <TableRow
      className={cn(
        !isSafari() && 'rounded-lg sm:[&:last-of-type_td_[data-role="table-row-button"]]:before:rounded-b-2xl'
      )}
    >
      <ProtocolNameCell protocolName={protocol.name} testId={testId} />
    </TableRow>
  )
}
