import * as CheckboxPrimitives from '@radix-ui/react-checkbox'

import { CheckIcon } from './Icons'

import { cn } from '@/utils/cn'

export const Checkbox = (props: CheckboxPrimitives.CheckboxProps) => (
  <CheckboxPrimitives.Root
    className={cn(
      'flex size-4 items-center justify-center rounded border border-gray bg-white p-0 text-white outline-none transition-all',
      'hover:border-decorationPrimary focus-visible:border-decorationPrimary active:border-decorationPrimary',
      'data-[state=checked]:border-decorationPrimary data-[state=checked]:bg-decorationPrimary'
    )}
    {...props}
  >
    <span className="text-inherit">
      <CheckIcon />
    </span>
  </CheckboxPrimitives.Root>
)
