import { PanelContentWrapper, PanelHeader, PanelTitle } from '@/components/atoms/Panel'
import { ApyChart } from '@/components/molecules'
import { useChartData } from '@/hooks/query/useChartData'
import type { ApyModes, BackendVaultDetailed } from '@/types'
import type { ChartPeriod } from '@/utils'
import { getChartDataByPeriod } from '@/utils'

interface Props {
  vault: BackendVaultDetailed
  apyMode: ApyModes
  period: ChartPeriod
}

export const ApyHistoryChart = ({ vault, apyMode, period }: Props) => {
  const chartData = useChartData({ address: vault.address, chainName: vault.network, interval: apyMode })
  const firstNonZeroIndex = chartData.findIndex((dataPoint) => dataPoint.rangeApy !== 0 || dataPoint.rawApy !== 0)
  const chartDataByPeriod = getChartDataByPeriod(chartData, period, firstNonZeroIndex)

  return (
    <PanelContentWrapper>
      <PanelHeader>
        <PanelTitle>APY History</PanelTitle>
      </PanelHeader>
      <ApyChart chartData={chartDataByPeriod} apyMode={apyMode} zoomable variant="square" />
    </PanelContentWrapper>
  )
}
