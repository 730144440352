export const ETH_IN_GWEI = 10 ** 9
export const GWEI_IN_WEI = 10 ** 9

export const ONE_BILLION = 1e9

export const HIGH_TVL_IMPACT_PERCENTAGE = 5

export const ONE_IN_BPS = 10_000

export const BN_ONE_IN_PERCENT = 100n
