import type { ObjectEndpoints, WalkObject } from './getPrimitiveValues'
import { getPrimitiveValues } from './getPrimitiveValues'

const A_IS_GREATER_THAN_B = 1
const B_IS_GREATER_THAN_A = -1

export type CompareTransformFunction<T extends object, Keys extends ObjectEndpoints<T>> = (
  value: WalkObject<T, Keys> | undefined,
  obj: T
) => string | number | bigint | undefined

function orderedCompare<T extends string | number | bigint | undefined>(a: T, b: T, ascendingOrder: boolean) {
  const comparatorResult = compareValues(a, b)
  return ascendingOrder ? comparatorResult : -comparatorResult
}

export function compareObjectsByProperties<T extends object, const Keys extends ObjectEndpoints<T>>(
  a: T,
  b: T,
  keys: Keys,
  ascendingOrder: boolean,
  transform?: CompareTransformFunction<T, Keys>
) {
  const [valueA, valueB] = getPrimitiveValues(a, b, keys)

  if (transform) {
    const transformedA = transform(valueA, a)
    const transformedB = transform(valueB, b)

    return orderedCompare(transformedA, transformedB, ascendingOrder)
  }
  return orderedCompare(valueA, valueB, ascendingOrder)
}

export function compareValues<T extends string | number | bigint | undefined>(a: T, b: T) {
  if (a === undefined) return B_IS_GREATER_THAN_A
  if (b === undefined) return A_IS_GREATER_THAN_B
  return a >= b ? A_IS_GREATER_THAN_B : B_IS_GREATER_THAN_A
}
