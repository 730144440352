import { SUPPORTED_PROTOCOL_SUBDOMAINS } from '@/config/subdomains'
import type { Subdomain } from '@/types/Subdomain'
import { SubdomainType } from '@/types/Subdomain'
import type { Network } from '@vaultsfyi/common'
import { NETWORKS } from '@vaultsfyi/common'
import { arrayIncludes } from '../arrayIncludes'

const subdomainException: Record<string, Network> = {
  op: 'optimism',
}

const defaultHost = location.host

export function getSubdomain(host = defaultHost): Subdomain | undefined {
  const subdomain = host.replace('www.', '').split('.')[0]

  const name = subdomainException[subdomain] ?? subdomain

  if (arrayIncludes(NETWORKS, name)) {
    return { name, type: SubdomainType.Network, prefix: subdomain }
  }

  if (arrayIncludes(SUPPORTED_PROTOCOL_SUBDOMAINS, name)) {
    return { name, type: SubdomainType.Protocol, prefix: subdomain }
  }

  return undefined
}
