import type { VaultListColumn } from '@/pages/VaultsListPage/types'

export function getColumnSize(column: VaultListColumn) {
  switch (column) {
    case 'protocol':
    case 'network':
      return 'w-10'
    case 'token':
      return 'w-24'
    case 'name':
      return 'w-80'
    case 'tvl':
      return 'w-20'
    case 'apy':
      return 'w-32'
    case 'opener':
      return 'w-14'
    case 'apy trend':
      return 'w-16'
    default:
      return 'w-10'
  }
}
