import type { ChartData } from './interpolateChartData'

interface LabeledChartData<T extends string> {
  label: string
  data: ChartData<T>[]
}

export function mergeChartData<T extends string>(chartData: LabeledChartData<T>[], deltaKey: T): ChartData<T>[] {
  const dataRecord: Record<number, ChartData<T>> = {}
  for (const { label, data } of chartData) {
    for (const dataPoint of data) {
      const date = dataPoint[deltaKey]
      if (!dataRecord[date]) dataRecord[date] = {} as ChartData<T>
      dataRecord[date][deltaKey] = date
      for (const key in dataPoint) if (key !== deltaKey) (dataRecord[date][`${label}-${key}`] as any) = dataPoint[key]
    }
  }
  return Object.values(dataRecord).sort((a, b) => a[deltaKey] - b[deltaKey])
}
