import { useState } from 'react'

interface ChartInteractionReturn {
  activeLine: string | null
  hiddenLines: string[]
  onChartClick: (key: string) => void
  onChartHover: (key: string) => void
  onChartLeave: () => void
}

export const useChartInteraction = (): ChartInteractionReturn => {
  const [activeLine, setActiveLine] = useState<string | null>(null)
  const [hiddenLines, setHiddenLines] = useState<string[]>([])

  function onChartClick(key: string) {
    if (hiddenLines.includes(key)) {
      setHiddenLines(hiddenLines.filter((line) => line !== key))
    } else {
      setHiddenLines([...hiddenLines, key])
    }
  }

  function onChartHover(key: string) {
    if (!hiddenLines.includes(key)) {
      setActiveLine(key)
    }
  }

  function onChartLeave() {
    setActiveLine(null)
  }

  return {
    activeLine,
    hiddenLines,
    onChartClick,
    onChartHover,
    onChartLeave,
  }
}
