import type { HTMLAttributes, ReactNode } from 'react'

import { Label } from '@/components/atoms/Label'
import { cn } from '@/utils/cn'

export interface LabeledFieldProps {
  id?: string
  label: ReactNode
  headerContent?: ReactNode
}

export const LabeledField = ({
  id,
  label,
  headerContent,
  children,
  className,
}: LabeledFieldProps & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex w-fit items-center gap-2 font-base text-textSecondary leading-none">
        <Label className="mr-auto" htmlFor={id}>
          {label}
        </Label>
        {headerContent}
      </div>
      <div className="mt-2 flex flex-col gap-2">{children}</div>
    </div>
  )
}
