import * as DialogPrimitive from '@radix-ui/react-dialog'
import type { ComponentPropsWithoutRef, ElementRef, HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { CrossIcon } from './Icons'

import { cn } from '@/utils/cn'

export const Dialog = DialogPrimitive.Root

export const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = (props: DialogPrimitive.DialogPortalProps) => <DialogPrimitive.Portal {...props} />

const DialogOverlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>((props, ref) => (
  <DialogPrimitive.Overlay
    className="data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-black/30 data-[state=closed]:animate-out data-[state=open]:animate-in"
    ref={ref}
    {...props}
  />
))

export const DialogContent = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>((props, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "grid grid-rows-[auto_1fr_auto] [grid-template-areas:'dialogHeader'_'dialogBody'_'dialogFooter']",
        '-translate-x-1/2 -translate-y-1/2 fixed top-1/2 left-1/2 duration-200',
        'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] z-50 data-[state=closed]:animate-out data-[state=open]:animate-in',
        'max-h-[calc(100%-1rem)] w-[calc(100%-1rem)] max-w-lg sm:max-h-[calc(100%-2rem)] sm:w-[calc(100%-2rem)]',
        'overflow-hidden rounded-2xl bg-white shadow-big outline-none'
      )}
      {...props}
    />
  </DialogPortal>
))

const DialogCrossCloseButton = forwardRef<
  ElementRef<typeof DialogPrimitive.Close>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>((props, ref) => (
  <DialogPrimitive.Close
    className="absolute top-2 right-4 flex size-6 items-center justify-center text-textSecondary hover:text-decorationPrimary focus-visible:text-decorationPrimary sm:top-4"
    ref={ref}
    {...props}
  >
    <CrossIcon />
  </DialogPrimitive.Close>
))

interface DialogHeaderProps {
  closable?: boolean
}

export const DialogHeader = ({ children, closable, ...props }: HTMLAttributes<HTMLDivElement> & DialogHeaderProps) => (
  <header
    className={cn('relative flex items-center px-4 py-2 [grid-area:dialogHeader] sm:py-4', closable && 'pr-12')}
    {...props}
  >
    {children}
    {closable && <DialogCrossCloseButton />}
  </header>
)

export const DialogTitle = forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>((props, ref) => <DialogPrimitive.Title className="text-xl leading-none sm:text-2xl" ref={ref} {...props} />)

export const DialogClose = forwardRef<
  ElementRef<typeof DialogPrimitive.Close>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>((props, ref) => <DialogPrimitive.Close ref={ref} {...props} />)

export const DialogBody = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('max-h-full overflow-hidden border-y border-y-gray [grid-area:dialogBody]', className)} {...props}>
    {children}
  </div>
)

export const DialogFooter = (props: HTMLAttributes<HTMLDivElement>) => (
  <footer className="p-4 [grid-area:dialogFooter]" {...props} />
)
