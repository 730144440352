import { Button } from '@/components/atoms/Button'
import { GoogleIcon } from '@/components/atoms/Icons'
import { Panel } from '@/components/atoms/Panel'
import { Page } from '@/components/molecules'
import { BACKEND_URL } from '@/constants'

function WallbreakerLogin() {
  return (
    <Page title="Wallbreaker">
      <Panel className="mx-auto flex w-fit min-w-56 flex-col items-center justify-center gap-4">
        You need to login to access Wallbreaker
        <Button asChild>
          <a href={`${BACKEND_URL}/oauth2/google`}>
            <GoogleIcon size={20} />
            Login with Google
          </a>
        </Button>
      </Panel>
    </Page>
  )
}

export const Component = () => WallbreakerLogin()
