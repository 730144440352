import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'

import { BOOKMARKED_VAULTS } from '@/constants'
import { getLocalStorageValue, setLocalStorageValue } from '@/utils'

interface Bookmark {
  address: string
  network: string
}

interface BookmarkContextType {
  bookmarks: Bookmark[]
  addBookmark: (bookmark: Bookmark) => void
  removeBookmark: (bookmark: Bookmark) => void
}

const BookmarkContext = createContext<BookmarkContextType | undefined>(undefined)

interface BookmarksProviderProps {
  children: ReactNode
}

function getBookmarks(): Bookmark[] {
  const storedBookmarks = getLocalStorageValue(BOOKMARKED_VAULTS)
  return storedBookmarks ? JSON.parse(storedBookmarks) : []
}

export function BookmarksProvider({ children }: BookmarksProviderProps) {
  const [bookmarks, setBookmarks] = useState<Bookmark[]>(getBookmarks())

  useEffect(() => {
    setLocalStorageValue(BOOKMARKED_VAULTS, JSON.stringify(bookmarks))
  }, [bookmarks])

  const addBookmark = (bookmark: Bookmark) => {
    setBookmarks((prevBookmarks) => {
      if (
        prevBookmarks.some(
          (prevBookmark) => prevBookmark.address === bookmark.address && prevBookmark.network === bookmark.network
        )
      ) {
        return prevBookmarks
      }
      return [...prevBookmarks, bookmark]
    })
  }

  const removeBookmark = (bookmark: Bookmark) => {
    setBookmarks((prevBookmarks) =>
      prevBookmarks.filter(
        (prevBookmark) => prevBookmark.address !== bookmark.address || prevBookmark.network !== bookmark.network
      )
    )
  }

  return (
    <BookmarkContext.Provider value={{ bookmarks, addBookmark, removeBookmark }}>{children}</BookmarkContext.Provider>
  )
}

export function useBookmarks() {
  const context = useContext(BookmarkContext)
  if (!context) {
    throw new Error('useBookmarkContext must be used within a BookmarksProvider')
  }
  return context
}
