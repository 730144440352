export const PROTOCOL_CURATED_ONLY_KEY = 'protocolCuratedOnly'
export const TVL_IN_NATIVE_ASSET_KEY = 'tvlInNativeAsset'
export const EXCLUDE_REWARDS_KEY = 'excludeRewards'
export const TVL_UNSTACKED_KEY = 'tvlUnstacked'
export const SORT_BY_KEY = 'sortBy'
export const ASCENDING_KEY = 'asc'
export const APY_MODE_KEY = 'apyMode'
export const SEARCH_FOR_KEY = 'searchFor'
export const SELECTED_TAGS_KEY = 'selectedTags'
export const NETWORK_KEY = 'network'
export const ADDRESS_KEY = 'address'
export const SELECTED_NETWORKS_KEY = 'selectedNetworks'
export const SELECTED_PROTOCOLS_KEY = 'selectedProtocols'
export const SELECTED_TOKENS_KEY = 'selectedTokens'
export const TVL_UPPER_BOUND_KEY = 'tvlUpperBound'
export const TVL_LOWER_BOUND_KEY = 'tvlLowerBound'
export const NAME_KEY = 'name'
export const VERSION_KEY = 'version'
export const PRODUCT_KEY = 'product'
export const AMOUNT_KEY = 'amount'
export const PERIOD_KEY = 'period'
