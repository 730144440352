import type { ApyChartData } from '@/types'

export const APY_CHART_DATA_MOCK: ApyChartData[] = [
  {
    date: 1717497161,
    rawApy: 170,
    rangeApy: 736,
    rewardsApy: 143,
  },
  {
    date: 1717583561,
    rawApy: 171,
    rangeApy: 843,
    rewardsApy: 167,
  },
  {
    date: 1717669961,
    rawApy: 172,
    rangeApy: 779,
    rewardsApy: 120,
  },
  {
    date: 1717756361,
    rawApy: 173,
    rangeApy: 758,
    rewardsApy: 252,
  },
  {
    date: 1717842761,
    rawApy: 174,
    rangeApy: 713,
    rewardsApy: 312,
  },
  {
    date: 1717929161,
    rawApy: 175,
    rangeApy: 675,
    rewardsApy: 348,
  },
  {
    date: 1718015561,
    rawApy: 176,
    rangeApy: 689,
    rewardsApy: 365,
  },
  {
    date: 1718101961,
    rawApy: 177,
    rangeApy: 693,
    rewardsApy: 210,
  },
  {
    date: 1718188361,
    rawApy: 178,
    rangeApy: 678,
    rewardsApy: 180,
  },
  {
    date: 1718274761,
    rawApy: 179,
    rangeApy: 715,
    rewardsApy: 178,
  },
  {
    date: 1718361161,
    rawApy: 180,
    rangeApy: 830,
    rewardsApy: 99,
  },
  {
    date: 1718447561,
    rawApy: 197,
    rangeApy: 906,
    rewardsApy: 326,
  },
  {
    date: 1718533961,
    rawApy: 207,
    rangeApy: 1003,
    rewardsApy: 310,
  },
  {
    date: 1718620361,
    rawApy: 223,
    rangeApy: 1115,
    rewardsApy: 429,
  },
  {
    date: 1718706761,
    rawApy: 226,
    rangeApy: 1151,
    rewardsApy: 516,
  },
  {
    date: 1718793161,
    rawApy: 238,
    rangeApy: 1253,
    rewardsApy: 493,
  },
  {
    date: 1718879561,
    rawApy: 263,
    rangeApy: 1340,
    rewardsApy: 488,
  },
  {
    date: 1718965961,
    rawApy: 273,
    rangeApy: 1332,
    rewardsApy: 528,
  },
  {
    date: 1719052361,
    rawApy: 275,
    rangeApy: 1441,
    rewardsApy: 461,
  },
  {
    date: 1719138761,
    rawApy: 305,
    rangeApy: 1514,
    rewardsApy: 435,
  },
  {
    date: 1719225161,
    rawApy: 307,
    rangeApy: 1495,
    rewardsApy: 418,
  },
  {
    date: 1719311561,
    rawApy: 301,
    rangeApy: 1576,
    rewardsApy: 372,
  },
  {
    date: 1719397961,
    rawApy: 314,
    rangeApy: 1576,
    rewardsApy: 357,
  },
  {
    date: 1719484361,
    rawApy: 316,
    rangeApy: 1565,
    rewardsApy: 381,
  },
  {
    date: 1719570761,
    rawApy: 305,
    rangeApy: 1487,
    rewardsApy: 343,
  },
  {
    date: 1719657161,
    rawApy: 316,
    rangeApy: 1453,
    rewardsApy: 323,
  },
  {
    date: 1719743561,
    rawApy: 326,
    rangeApy: 1466,
    rewardsApy: 315,
  },
  {
    date: 1719829961,
    rawApy: 316,
    rangeApy: 1456,
    rewardsApy: 284,
  },
  {
    date: 1719916361,
    rawApy: 324,
    rangeApy: 1471,
    rewardsApy: 274,
  },
  {
    date: 1720002761,
    rawApy: 335,
    rangeApy: 1448,
    rewardsApy: 261,
  },
  {
    date: 1720089161,
    rawApy: 339,
    rangeApy: 1518,
    rewardsApy: 287,
  },
]
