import type { ProtocolName } from '@/config/subdomains'
import type { Network } from '@vaultsfyi/common'

export enum SubdomainType {
  Network = 'network',
  Protocol = 'protocol',
}

export type Subdomain = {
  name: Network | ProtocolName
  type: SubdomainType
  prefix: string
}
