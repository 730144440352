import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const VisibleIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M480-326q72.5 0 123.25-50.75T654-500q0-72.5-50.75-123.25T480-674q-72.5 0-123.25 50.75T306-500q0 72.5 50.75 123.25T480-326Zm.118-68Q436-394 405-424.882q-31-30.883-31-75Q374-544 404.882-575q30.883-31 75-31Q524-606 555-575.118q31 30.883 31 75Q586-456 555.118-425q-30.883 31-75 31Zm-.043 186Q349.5-208 241.75-278.5T71-464q-5-8.5-7.25-17.513-2.25-9.013-2.25-18.5t2.25-18.487Q66-527.5 71-536q63-115 170.675-185.5Q349.351-792 479.925-792q130.575 0 238.325 70.5T889-536q5 8.5 7.25 17.513 2.25 9.013 2.25 18.5t-2.25 18.487q-2.25 9-7.25 17.5-63 115-170.675 185.5Q610.649-208 480.075-208ZM480-500Zm-.002 217q111.502 0 204.752-58.5Q778-400 827-500q-49-100-142.248-158.5-93.247-58.5-204.75-58.5-111.502 0-204.752 58.5Q182-600 133-500q49 100 142.248 158.5 93.247 58.5 204.75 58.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
