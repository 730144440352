import { MultiApyHistoryChart } from '@/components/organisms/Charts/MultiApyHistoryChart'
import { MultiTvlHistoryChart } from '@/components/organisms/Charts/MultiTvlHistoryChart'
import type { ApyModes, BackendVault } from '@/types'
import type { ChartPeriod } from '@/utils'
import type { MultiChartDataGroup } from '@/utils/chartUtils/composeMultiChartData'
import type { VaultsBenchmark } from '@vaultsfyi/common'

interface Props {
  vaults: BackendVault[]
  benchmarks: VaultsBenchmark[]
  multiChartData: MultiChartDataGroup
  apyMode: ApyModes
  period: ChartPeriod
  excludeRewards?: boolean
}

export const MultiCharts = ({ vaults, benchmarks, multiChartData, apyMode, period, excludeRewards }: Props) => {
  return (
    <>
      <MultiApyHistoryChart
        vaults={vaults}
        benchmarks={benchmarks}
        multiChartData={multiChartData}
        apyMode={apyMode}
        period={period}
        excludeRewards={excludeRewards}
      />
      <MultiTvlHistoryChart vaults={vaults} benchmarks={benchmarks} multiChartData={multiChartData} period={period} />
    </>
  )
}
