import { format, fromUnixTime } from 'date-fns'

import { ChartTooltip } from '@/components/atoms/Charts/ChartTooltip'
import { EqualIcon } from '@/components/atoms/Icons'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import { formatNumber } from '@/utils'
import type { Asset } from '@vaultsfyi/common'

export const MultiTvlChartTooltip = (props: Record<string, any>) => {
  const { label, payload, descriptors, tvlInNativeAsset } = props
  if (!payload) {
    return <></>
  }

  const dateLabel = label && `${format(fromUnixTime(Number(label)), 'MMM dd, yyyy, HH:mm, O')}`
  const descriptorKeys: string[] = descriptors.map((descriptor: Record<string, string | number>) => descriptor.key)
  const payloadDataItem: Record<string, any> = payload.find((payload: Record<string, number>) => {
    const payloadKeys = Object.keys(payload.payload)
    return descriptorKeys.every((key: any) => payloadKeys.includes(key))
  })
  const totalTvl: number | undefined =
    payloadDataItem &&
    descriptorKeys.reduce((total: number, key: string) => {
      if (key in payloadDataItem.payload) {
        return total + payloadDataItem.payload[key]
      } else {
        return total
      }
    }, 0)
  return (
    <ChartTooltip>
      <LabeledValueHeader className="!text-xs">{dateLabel}</LabeledValueHeader>
      <LabeledValueContent>
        <LabeledValueValue className="!text-base">
          <div className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap ">
            {payload
              .map((item: Record<string, any>, index: number) => {
                const key = item.dataKey
                const descriptor = descriptors.find((descriptor: any) => descriptor.key === key)
                return (
                  <div className="flex items-center gap-2" key={index}>
                    <div className="size-3 rounded" style={{ backgroundColor: descriptor.colors }} />
                    <p className="capitalize">
                      {descriptor.name}:{' '}
                      {formatTooltipValue(tvlInNativeAsset, descriptor.asset, formatNumber(item.payload[key]))}
                    </p>
                  </div>
                )
              })
              .reverse()}
          </div>
        </LabeledValueValue>
        {totalTvl && (
          <TotalTvlValue tvlInNativeAsset={tvlInNativeAsset} descriptors={descriptors} totalTvl={totalTvl} />
        )}
      </LabeledValueContent>
    </ChartTooltip>
  )
}

function formatTooltipValue(tvlInNativeAsset: boolean, asset: Asset, value: string) {
  if (tvlInNativeAsset) {
    return `${value} ${asset.symbol}`
  } else {
    return `$${value}`
  }
}

const TotalTvlValue = ({ tvlInNativeAsset, descriptors, totalTvl }: Record<string, any>) => {
  if (tvlInNativeAsset) {
    const allSameAddress = descriptors.every(
      (descriptor: any) =>
        descriptor.asset.assetAddress !== undefined &&
        descriptor.asset.assetAddress === descriptors[0].asset.assetAddress
    )
    if (allSameAddress) {
      return (
        <LabeledValueValue className="!text-base mt-1">
          <div className="flex items-center gap-2">
            <EqualIcon size={12} />
            <p className="capitalize">
              {formatNumber(totalTvl)} {descriptors[0].asset.symbol}
            </p>
          </div>
        </LabeledValueValue>
      )
    } else {
      return <></>
    }
  } else {
    return (
      <LabeledValueValue className="!text-base mt-1">
        <div className="flex items-center gap-2">
          <EqualIcon size={12} />
          <p className="capitalize">${formatNumber(totalTvl)}</p>
        </div>
      </LabeledValueValue>
    )
  }
}
