import * as DropdownPrimitives from '@radix-ui/react-popover'
import type { ComponentPropsWithoutRef, ElementRef, HTMLAttributes, InputHTMLAttributes, ReactNode } from 'react'
import { forwardRef } from 'react'

import { ArrowIcon } from './Icons'

import { cn } from '@/utils/cn'
import { Checkbox } from './Checkbox'
import { Input } from './Input'

export const Dropdown = DropdownPrimitives.Root

export const DropdownTrigger = forwardRef<
  ElementRef<typeof DropdownPrimitives.Trigger>,
  ComponentPropsWithoutRef<typeof DropdownPrimitives.Trigger>
>(({ children, className, ...props }, ref) => (
  <DropdownPrimitives.Trigger
    className={cn(
      'group flex h-10 cursor-pointer items-center rounded-lg border border-gray bg-white px-4 text-textPrimary leading-none outline-none transition-all',
      'hover:border-decorationPrimary focus-visible:border-decorationPrimary',
      'data-[state=open]:border-decorationPrimary data-[state=open]:text-decorationPrimary',
      className
    )}
    ref={ref}
    {...props}
  >
    <div className="mr-4 w-full max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-left leading-normal">
      {children}
    </div>
    <div className="group-data[state=open]:-scale-y-100 ml-auto transition-transform">
      <ArrowIcon direction="down" />
    </div>
  </DropdownPrimitives.Trigger>
))

export const DropdownContent = forwardRef<
  ElementRef<typeof DropdownPrimitives.Content>,
  ComponentPropsWithoutRef<typeof DropdownPrimitives.Content>
>(({ className, align = 'center', sideOffset = 8, ...props }, ref) => (
  <DropdownPrimitives.Portal>
    <DropdownPrimitives.Content
      className={cn(
        'z-50 flex w-[var(--radix-popover-trigger-width)] flex-col overflow-hidden rounded-lg border border-gray bg-white outline-none',
        'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 data-[state=closed]:animate-out data-[state=open]:animate-in',
        '[&_[data-radix-scroll-area-viewport]>div]:!block',
        className
      )}
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      {...props}
    />
  </DropdownPrimitives.Portal>
))

export const DropdownPlaceholder = ({ className, children, ...props }: HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span className={cn('text-textSecondary', className)} {...props}>
      {children}
    </span>
  )
}

export const DropdownList = ({ className, children, ...props }: HTMLAttributes<HTMLUListElement>) => {
  return (
    <ul className={cn('flex w-full flex-col gap-2 p-4', className)} {...props}>
      {children}
    </ul>
  )
}

export const DropdownListItem = ({ className, children, ...props }: HTMLAttributes<HTMLLIElement>) => {
  return (
    <li className={cn('flex w-full flex-col', className)} {...props}>
      {children}
    </li>
  )
}

export const DropdownSeparator = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn('h-px w-full bg-gray', className)} {...props} />
}

export const DropdownInput = ({ className, ...props }: InputHTMLAttributes<HTMLInputElement>) => {
  return <Input className={cn('border-none', className)} {...props} />
}

interface DropdownItemProps {
  children: ReactNode
  onClick: () => void
  isActive: boolean
  className?: string
}

export const DropdownItem = ({ children, onClick, isActive, className }: DropdownItemProps) => {
  return (
    <label
      className={cn(
        'flex w-full max-w-full cursor-pointer items-center justify-between overflow-auto text-ellipsis whitespace-nowrap transition-all',
        'focus-within:text-decorationPrimary hover:text-decorationPrimary focus-visible:text-decorationPrimary',
        className
      )}
    >
      <div className={cn('mr-2 max-w-full overflow-auto text-ellipsis whitespace-nowrap', isActive && 'font-bold')}>
        {children}
      </div>
      <Checkbox onCheckedChange={onClick} checked={isActive} />
    </label>
  )
}

export const DropdownItemContent = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('flex w-fit max-w-full items-center gap-2', className)} {...props}>
      {children}
    </div>
  )
}

export const DropdownItemText = ({ children, className, ...props }: HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span className={cn('max-w-full overflow-hidden text-ellipsis whitespace-nowrap', className)} {...props}>
      {children}
    </span>
  )
}
