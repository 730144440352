import mixpanel from 'mixpanel-browser'
import ReactGA from 'react-ga4'

import { getSubdomain } from '@/utils/subdomain/getSubdomain'

interface Props {
  label: string
}

const EVENT_NAME = 'Page event'
const EVENT_ACTION = 'Interaction with element'

export const trackPageEvents = ({ label }: Props) => {
  const subdomain = getSubdomain()?.prefix ?? 'N/A'

  ReactGA.event(
    {
      category: EVENT_NAME,
      action: EVENT_ACTION,
      label,
    },
    {
      subdomain,
    }
  )
  mixpanel.track(EVENT_NAME, {
    Action: EVENT_ACTION,
    Details: label,
    Subdomain: subdomain,
  })
}
