import { ChartTooltip } from '@/components/atoms/Charts/ChartTooltip'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import { formatNumber } from '@/utils'
import { format, fromUnixTime } from 'date-fns'

export const TvlChartTooltip = (props: Record<string, any>) => {
  const { label, payload, tvlInNativeAsset, asset } = props

  if (!payload) {
    return <></>
  }

  const dateLabel = label && `${format(fromUnixTime(Number(label)), 'MMM dd, yyyy, HH:mm, O')}`

  return (
    <ChartTooltip>
      <LabeledValueHeader className="!text-xs">{dateLabel}</LabeledValueHeader>
      <LabeledValueContent>
        <LabeledValueValue className="!text-base">
          <div className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
            {payload.map((item: Record<string, any>, index: number) => (
              <p className="capitalize" key={index}>
                {tvlInNativeAsset ? (
                  <>
                    {formatNumber(item.payload.tvlNative)} {asset.symbol}
                  </>
                ) : (
                  <>${formatNumber(item.payload.tvl)}</>
                )}
              </p>
            ))}
          </div>
        </LabeledValueValue>
      </LabeledValueContent>
    </ChartTooltip>
  )
}
