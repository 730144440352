import type { Network } from '@vaultsfyi/common'
import type { ReactNode } from 'react'

import { InfoIcon } from '@/components/atoms/Icons'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/atoms/Tooltip'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import type { BackendVaultDetailed } from '@/types'
import { formatNumber, formatUnits } from '@/utils'

interface Props {
  tvl: BackendVaultDetailed['tvl']
  asset: BackendVaultDetailed['asset']
  network?: Network
  address?: string
}

export const TVLStatistic = ({ tvl, asset, network, address }: Props) => {
  const tvlText = formatNumber(formatUnits(BigInt(tvl), asset.decimals))

  return (
    <div>
      {getTitleWithExceptions(`${network}/${address}`)}
      <LabeledValueContent>
        <LabeledValueValue>
          <span>
            {tvlText} {asset.symbol}
          </span>
        </LabeledValueValue>
      </LabeledValueContent>
    </div>
  )
}

type VaultIdentifier = `${Network | undefined}/${string | undefined}`

const getTitleWithExceptions = (identifier?: VaultIdentifier): ReactNode => {
  switch (identifier) {
    case 'mainnet/0x856c4Efb76C1D1AE02e20CEB03A2A6a08b0b8dC3': // Origin Ether (oETH)
      return (
        <LabeledValueHeader>
          <div className="flex w-fit items-center gap-1">
            <span>Current TVL</span>
            <Tooltip>
              <TooltipTrigger>
                <InfoIcon />
              </TooltipTrigger>
              <TooltipContent>Does not include non rebasing TVL</TooltipContent>
            </Tooltip>
          </div>
        </LabeledValueHeader>
      )
    default:
      return <LabeledValueHeader>Current TVL</LabeledValueHeader>
  }
}
