import { useEffect } from 'react'

export function useOnMouseUp(listener: (event: MouseEvent) => void, deps: any[] = []) {
  useEffect(() => {
    window.addEventListener('mouseup', listener)
    return () => {
      window.removeEventListener('mouseup', listener)
    }
  }, [deps])
}
