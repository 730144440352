import type { Network } from '@vaultsfyi/common'

import { Panel } from '@/components/atoms/Panel'
import { Separator } from '@/components/atoms/Separator'
import {
  CurrentYieldStatistic,
  HoldersStatistic,
  NetworkStatistic,
  ProtocolStatistic,
  RewardsStatistic,
  TVLStatistic,
  TokenPriceStatistic,
  TokenStatistic,
} from '@/components/organisms/VaultStatistics'
import type { ApyModes, BackendVaultDetailed } from '@/types'

interface Props {
  vault: BackendVaultDetailed
  apyMode: ApyModes
}

export const PreviewStats = ({ vault, apyMode }: Props) => {
  const {
    address,
    network,
    tvl,
    asset,
    baseApy,
    rewardsApy,
    metadata,
    assetPriceInUsd: tokenPriceWithPrecision,
    holdersCount,
  } = vault

  return (
    <Panel className="flex flex-wrap gap-x-3 gap-y-4 p-4 sm:p-6 [&_[data-role='labeled-value-value']]:text-xl [&_[data-role='labeled-value-value']]:leading-6 [&_[data-role='separator']]:h-12">
      <ProtocolStatistic metadata={metadata} />
      <Separator orientation="vertical" />
      <CurrentYieldStatistic baseApy={baseApy} apyMode={apyMode} />
      <Separator orientation="vertical" />
      <RewardsStatistic rewardsApy={rewardsApy} />
      <Separator orientation="vertical" />
      <TVLStatistic tvl={tvl} asset={asset} network={network as Network} address={address} />
      <Separator orientation="vertical" />
      <TokenPriceStatistic tokenPriceWithPrecision={tokenPriceWithPrecision} />
      <Separator orientation="vertical" />
      <TokenStatistic asset={asset} metadata={metadata} />
      <Separator orientation="vertical" />
      <NetworkStatistic network={network} />
      <Separator orientation="vertical" />
      <HoldersStatistic holders={holdersCount} />
    </Panel>
  )
}
