import { formatBips } from '@vaultsfyi/common'

import { ValueIndicator, getValueIndicatorView } from '@/components/atoms/ValueIndicator'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import type { ApyModes, BackendVaultDetailed } from '@/types'
import { getShortApyModeText } from '@/utils'

interface Props {
  baseApy: BackendVaultDetailed['baseApy']
  apyMode: ApyModes
}

export const CurrentYieldStatistic = ({ baseApy, apyMode }: Props) => {
  return (
    <div>
      <LabeledValueHeader>Base yield, {getShortApyModeText(apyMode)}</LabeledValueHeader>
      <LabeledValueContent>
        <LabeledValueValue>
          <span>
            <ValueIndicator variant={getValueIndicatorView(baseApy)}>
              {baseApy > 0 ? '+' : ''}
              {formatBips(baseApy)}
            </ValueIndicator>
          </span>
        </LabeledValueValue>
      </LabeledValueContent>
    </div>
  )
}
