import type { ComponentProps } from 'react'
import type { NumberFormatValues } from 'react-number-format'
import { NumericFormat } from 'react-number-format'

import { Input } from '@/components/atoms/Input'

interface FormattedNumberInputProps extends ComponentProps<typeof Input> {
  setValue: (val: number | undefined) => void
  value: number | undefined
  maxValue?: number | undefined
}

export function FormattedNumberInput({ setValue, value, maxValue, className, ...props }: FormattedNumberInputProps) {
  return (
    <NumericFormat
      thousandSeparator
      value={value ?? ''}
      allowNegative={false}
      isAllowed={({ floatValue }) => (floatValue && maxValue ? floatValue >= 0 && floatValue <= maxValue : true)}
      customInput={Input}
      onValueChange={(values: NumberFormatValues) => setValue(values.floatValue)}
      placeholder={props.placeholder}
      inputMode="numeric"
      className={className}
    />
  )
}
