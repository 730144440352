import { cn } from '@/utils/cn'
import type { HTMLAttributes } from 'react'

export const Container = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('mx-auto block w-full max-w-6xl px-4', className)} {...props}>
      {children}
    </div>
  )
}

export const PageContainer = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <Container className={cn('mx-auto mt-4 mb-14', className)} {...props}>
      {children}
    </Container>
  )
}
