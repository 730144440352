import { DAY_IN_SECONDS } from '@vaultsfyi/common'
import { useMemo } from 'react'
import type { AxisDomain } from 'recharts/types/util/types'

interface ChartData {
  date: number
}

export function useChartZoomedIn<T extends ChartData>(chartData: T[], xAxisDomain: AxisDomain) {
  if (typeof xAxisDomain === 'function') return false
  return useMemo(() => {
    const minDate = typeof xAxisDomain[0] === 'number' ? xAxisDomain[0] : chartData[0]?.date
    const maxDate = typeof xAxisDomain[1] === 'number' ? xAxisDomain[1] : chartData[chartData.length - 1]?.date
    return maxDate - minDate < 31 * DAY_IN_SECONDS
  }, [xAxisDomain])
}
