import { getDisplayedAssetSymbol } from '../tokenUtils'

import type { BackendVault, BackendVaultDetailed } from '@/types'

export function transformVault<T extends BackendVaultDetailed | BackendVault | undefined>(vault: T): T {
  if (!vault) return vault
  return {
    ...vault,
    asset: { ...vault.asset, symbol: getDisplayedAssetSymbol(vault.asset) },
  }
}
