import Fuse from 'fuse.js'

import type { BackendVault } from '@/types'

const SEARCH_KEYS = [
  'name',
  'asset.name',
  'asset.symbol',
  'network',
  'protocolName',
  'protocolVersion',
] satisfies Fuse.FuseOptionKey<BackendVault>[]

const SEARCH_OPTIONS: Fuse.IFuseOptions<BackendVault> = { ignoreLocation: true, threshold: 0.2, keys: SEARCH_KEYS }

export function fuzzySearchVaults(vaults: BackendVault[], searchFor: string) {
  const searchTerms = searchFor.split(' ')
  const searchResult = searchTerms.reduce((searchResults, searchTerm) => {
    if (!searchTerm || searchTerm === ' ') return searchResults
    const fuse = new Fuse(searchResults, SEARCH_OPTIONS)
    return fuse.search(searchTerm).map((e) => e.item)
  }, vaults)
  return searchResult
}
