import type { Network } from '@vaultsfyi/common'
import { createSearchParams, generatePath } from 'react-router-dom'

import { Button } from '@/components/atoms/Button'
import { BookmarkButton } from '@/components/molecules/BookmarkButton'
import { CurationTooltip } from '@/components/molecules/CurationTooltip'
import { EditVaultButton } from '@/components/molecules/EditVaultButton'
import { ShareButton } from '@/components/molecules/ShareButton'
import { VaultTag } from '@/components/molecules/VaultTag'
import { routes } from '@/config'
import { VAULTS_FYI_URL } from '@/constants'
import { useIsAuthorized } from '@/hooks/useIsAuthorized'
import { useBookmarks } from '@/providers/BookmarksProvider'
import type { BackendVaultDetailed } from '@/types'
import { getExplorerLinkFromValue, shortenString } from '@/utils'
import { getSubdomain } from '@/utils/subdomain'

interface Props {
  vault: BackendVaultDetailed
}

export const PreviewHeader = ({ vault }: Props) => {
  const isAuthorized = useIsAuthorized()
  const wallbreakerUrl = `${generatePath(routes.dataUpdater)}?${createSearchParams({
    network: vault.network,
    address: vault.address,
  })}`
  const { address, network, name, tags, lendLink, isFeatured } = vault
  const vaultUrl = VAULTS_FYI_URL + generatePath(routes.vaultPreview, { chainName: network, address }).replace('/', '')
  const { bookmarks, addBookmark, removeBookmark } = useBookmarks()
  const isBookmarked = bookmarks.some((bookmark) => bookmark.address === address && bookmark.network === network)
  const subdomain = getSubdomain()

  const handleBookmark = () => {
    if (isBookmarked) {
      removeBookmark({ address, network })
    }
    if (!isBookmarked) {
      addBookmark({ address, network })
    }
  }

  const explorerLink = getExplorerLinkFromValue(address, network as Network)

  const shouldShowIsFeatured = subdomain?.name === 'yearn'

  return (
    <header className="flex flex-col justify-between gap-x-6 gap-y-4 sm:flex-row">
      <div className="flex flex-grow flex-col gap-2">
        <div className="flex items-center gap-2">
          <BookmarkButton onClick={handleBookmark} isActive={isBookmarked} />
          <ShareButton iconSize={24} title="vaults.fyi" text={name} url={vaultUrl} />
          {shouldShowIsFeatured && <CurationTooltip isFeatured={isFeatured} side="bottom" />}
          <h2 className="text-3xl">{name}</h2>
          {isAuthorized && <EditVaultButton href={wallbreakerUrl} />}
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <Button variant="text" asChild>
            <a href={explorerLink} target="_blank" rel="noopener noreferrer">
              {shortenString(address)}
            </a>
          </Button>
          {subdomain?.name !== 'yearn' && tags && tags.map((tag) => <VaultTag tag={tag} key={tag.id} />)}
        </div>
      </div>
      {lendLink && (
        <Button variant="primary" className="min-w-32" asChild>
          <a href={lendLink} target="_blank" rel="noopener noreferrer">
            Deposit
          </a>
        </Button>
      )}
    </header>
  )
}
