import { useState } from 'react'

import { AccordionTrigger } from '@/components/atoms/Accordion'
import { Button } from '@/components/atoms/Button'
import { CheckIcon, EditNoteIcon } from '@/components/atoms/Icons'
import { LabeledInputField } from '@/components/molecules'
import { trpcQueryClient } from '@/config/trpc'
import type { BackendWarning, EditResultProps, EditWarningIdProps, RevalidatorProps } from '@/types'
import { PostResult } from './PostResult'

interface EditWarningProps extends BackendWarning, RevalidatorProps, EditWarningIdProps {
  updateWarning: (warning: BackendWarning) => void
}

export const EditWarning = ({ revalidate, updateWarning: updateWarningState, ...props }: EditWarningProps) => {
  const [newWarningContent, setNewWarningContent] = useState<string>(props.content)
  const [newWarningStartTime, setNewWarningStartTime] = useState<string>(props.start.replace('Z', ''))
  const [newWarningEndTime, setNewWarningEndTime] = useState<string>(props.end.replace('Z', ''))

  const [isUpdateDisabled, setUpdateDisabled] = useState<boolean>(false)

  const [postResult, setPostResult] = useState<EditResultProps>()
  const updateWarning = async () => {
    try {
      setUpdateDisabled(true)
      setPostResult({ message: 'Posting', status: 'Pending' })
      const { message, warning } = await trpcQueryClient.updateWarning.mutate({
        content: newWarningContent,
        start: new Date(newWarningStartTime).toISOString(),
        end: new Date(newWarningEndTime).toISOString(),
        warningId: props.warningId,
      })
      setPostResult({ message: message, status: 'Success' })
      updateWarningState(warning)
      revalidate()
    } catch (error) {
      setPostResult({ message: `Error: ${error}`, status: 'Failed' })
    } finally {
      setUpdateDisabled(false)
    }
  }

  return (
    <div className="flex flex-col gap-2 px-4 pb-4">
      <LabeledInputField
        label="Update warning text"
        id="newWarningContent"
        name="newWarningContent"
        value={newWarningContent}
        onChange={(e) => setNewWarningContent(e.target.value)}
      />
      <LabeledInputField
        label="Update warning start time"
        id="newWarningStartTime"
        name="newWarningStartTime"
        type="datetime-local"
        value={newWarningStartTime}
        onChange={(e) => setNewWarningStartTime(e.target.value)}
      />
      <LabeledInputField
        label="Update warning end time"
        id="newWarningEndTime"
        name="newWarningEndTime"
        type="datetime-local"
        value={newWarningEndTime}
        onChange={(e) => setNewWarningEndTime(e.target.value)}
      />
      <Button variant="secondary" onClick={updateWarning} disabled={isUpdateDisabled}>
        <CheckIcon />
        Apply
      </Button>
      {postResult && <PostResult {...postResult} />}
    </div>
  )
}

export const EditWarningButton = () => {
  return (
    <Button variant="icon" asChild>
      <AccordionTrigger title="Edit this post">
        <EditNoteIcon />
      </AccordionTrigger>
    </Button>
  )
}
