import { Image } from '@/components/atoms/Image'
import { TableCell, type TableCellProps } from '@/components/atoms/Table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/atoms/Tooltip'
import type { VaultForList } from '@/types'
import { getNetworkImage } from '@/utils'

interface NetworkCellProps extends Pick<VaultForList, 'network'> {
  testId?: string
}

export const NetworkCell = ({ network, testId, ...props }: NetworkCellProps & TableCellProps) => {
  return (
    <TableCell data-cell="Network" data-testid={`${testId}-network-name`} data-testvalue={network} {...props}>
      <Tooltip>
        <TooltipTrigger>
          <Image
            src={getNetworkImage(network)}
            alt={`${network} network image`}
            title={`${network} network image`}
            loading="lazy"
          />
        </TooltipTrigger>
        <TooltipContent>
          <p>
            <b>Vault network:</b> <span className="inline-block first-letter:uppercase">{network}</span>
          </p>
        </TooltipContent>
      </Tooltip>
    </TableCell>
  )
}
