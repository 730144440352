import { formatBips } from '@vaultsfyi/common'

import { ValueIndicator, getValueIndicatorView } from '@/components/atoms/ValueIndicator'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import type { BackendVaultDetailed } from '@/types'

interface Props {
  rewardsApy: BackendVaultDetailed['rewardsApy']
}

export const RewardsStatistic = ({ rewardsApy }: Props) => {
  return (
    <div>
      <LabeledValueHeader>Rewards</LabeledValueHeader>
      <LabeledValueContent>
        <LabeledValueValue>
          <ValueIndicator variant={getValueIndicatorView(rewardsApy)}>
            {rewardsApy && (rewardsApy > 0 ? '+' : '')}
            {formatBips(rewardsApy)}
          </ValueIndicator>
        </LabeledValueValue>
      </LabeledValueContent>
    </div>
  )
}
