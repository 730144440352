import type { BackendTag } from '@/types'
import { hexToHSL } from '@/utils/colorUtils'
import { Badge } from '../atoms/Badge'

interface VaultTagProps {
  tag: BackendTag
}

export const VaultTag = ({ tag }: VaultTagProps) => {
  return <Badge color={hexToHSL(tag.color)}>{tag.name}</Badge>
}
