import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const OptimizerIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M480.053-441q-16.553 0-27.803-11.197Q441-463.395 441-479.947q0-16.553 11.197-27.803Q463.395-519 479.947-519q16.553 0 27.803 11.197Q519-496.605 519-480.053q0 16.553-11.197 27.803Q496.605-441 480.053-441ZM480-90q-80.907 0-152.065-30.763-71.159-30.763-123.797-83.5Q151.5-257 120.75-328.087 90-399.175 90-480q0-80.907 30.763-152.065 30.763-71.159 83.5-123.797Q257-808.5 328.087-839.25 399.175-870 480-870q80.907 0 152.065 30.763 71.159 30.763 123.797 83.5Q808.5-703 839.25-631.913 870-560.825 870-480q0 80.907-30.763 152.065-30.763 71.159-83.5 123.797Q703-151.5 631.913-120.75 560.825-90 480-90Zm0-75q131.5 0 223.25-91.75T795-480q0-131.5-91.75-223.25T480-795q-131.5 0-223.25 91.75T165-480q0 131.5 91.75 223.25T480-165Zm0-315ZM303.5-283.5l241.5-114q5.5-2.5 10.25-7.25T562.5-415l114-241.5q5-10-2.5-17.5t-17.5-2.5L415-562.5q-5.5 2.5-10.25 7.25T397.5-545l-114 241.5q-5 10 2.5 17.5t17.5 2.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
