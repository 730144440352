import { type VaultsBenchmark, getBenchmarkKey, getVaultKey } from '@vaultsfyi/common'
import type { MultiChartData } from './composeMultiChartData'

export function addBenchmarksChartData(points: MultiChartData[], benchmarks: VaultsBenchmark[]): MultiChartData[] {
  for (const benchmark of benchmarks) {
    for (let i = 0; i < points.length; i++) {
      let tvlSum = 0
      let tvlNativeSum = 0
      let apyAvg = 0
      let apyWithRewardsAvg = 0
      for (const vault of benchmark.vaults) {
        const tvl = points[i][getVaultKey(vault, 'tvl')]
        const tvlNative = points[i][getVaultKey(vault, 'tvlNative')]
        if (!tvl) continue
        tvlSum += tvl
        tvlNativeSum += tvlNative
        apyAvg += points[i][getVaultKey(vault, 'apy')] * tvl
        apyWithRewardsAvg += points[i][getVaultKey(vault, 'apyWithRewards')] * tvl
      }
      apyAvg /= tvlSum
      apyWithRewardsAvg /= tvlSum
      points[i][getBenchmarkKey(benchmark, 'apy')] = apyAvg
      points[i][getBenchmarkKey(benchmark, 'apyWithRewards')] = apyWithRewardsAvg
      points[i][getBenchmarkKey(benchmark, 'tvl')] = tvlSum
      points[i][getBenchmarkKey(benchmark, 'tvlNative')] = tvlNativeSum
    }
  }
  return points
}
