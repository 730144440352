import { type ReactNode, useMemo } from 'react'

import { PanelContentWrapper, PanelHeader, PanelTitle } from '@/components/atoms/Panel'
import { NativeAssetTvlSwitch } from '@/components/molecules/NativeAssetTvlSwitch/NativeAssetTvlSwitch'
import { StackingSwitch } from '@/components/molecules/StackingSwitch/StackingSwitch'
import { MultiTvlChart } from '@/components/molecules/TvlChart/MultiTvlChart'
import { TVL_IN_NATIVE_ASSET_KEY, TVL_UNSTACKED_KEY } from '@/constants/stateConstants'
import { useSearchState } from '@/hooks/useSearchState'
import { trackVaultsListEvents } from '@/pages/VaultsListPage/hooks'
import type { BackendVault } from '@/types'
import type { ChartPeriod } from '@/utils'
import { getMultiChartDataByPeriod } from '@/utils'
import type { MultiChartDataGroup } from '@/utils/chartUtils/composeMultiChartData'
import type { VaultsBenchmark } from '@vaultsfyi/common'

interface Props {
  vaults: BackendVault[]
  benchmarks: VaultsBenchmark[]
  multiChartData: MultiChartDataGroup
  period: ChartPeriod
}

export const MultiTvlHistoryChart = ({ vaults, benchmarks, multiChartData, period }: Props) => {
  const { searchState } = useSearchState()
  const unstacked = searchState.get(TVL_UNSTACKED_KEY) === 'true'
  const tvlInNativeAsset = searchState.get(TVL_IN_NATIVE_ASSET_KEY) === 'true'

  const chartDataByPeriod = useMemo(() => getMultiChartDataByPeriod(multiChartData, period), [multiChartData, period])

  const handleStacking = (checked: boolean) => {
    trackVaultsListEvents({
      action: 'TVL stacking toggled',
      label: `TVL stacking switched ${checked ? 'on' : 'off'}`,
    })
  }

  const handleNativeAsset = (checked: boolean) => {
    trackVaultsListEvents({
      action: 'Native asset toggled',
      label: `Native asset switched ${checked ? 'on' : 'off'}`,
    })
  }

  return (
    <PanelContentWrapper>
      <MultiTvlHistoryChartHeader>
        <nav className="flex flex-wrap gap-2">
          <StackingSwitch onChangeCallback={handleStacking} />
          <NativeAssetTvlSwitch onChangeCallback={handleNativeAsset} />
        </nav>
      </MultiTvlHistoryChartHeader>
      <MultiTvlChart
        vaults={vaults}
        benchmarks={benchmarks}
        multiChartData={chartDataByPeriod}
        unstacked={unstacked}
        tvlInNativeAsset={tvlInNativeAsset}
        zoomable
      />
    </PanelContentWrapper>
  )
}

export const MultiTvlHistoryChartHeader = ({ children }: { children?: ReactNode }) => {
  return (
    <PanelHeader>
      <PanelTitle>TVL History</PanelTitle>
      {children}
    </PanelHeader>
  )
}
