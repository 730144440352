import type { BackendVault } from '@/types'
import { getLatestBenchmarks } from '@/utils/getLatestBenchmarks'
import { Await, useLoaderData } from 'react-router-dom'
import { InfoIcon } from '../atoms/Icons'
import { Tooltip, TooltipContent, TooltipTrigger } from '../atoms/Tooltip'

export const Benchmarks = () => {
  const { vaults } = useLoaderData() as { vaults: BackendVault[] }
  return (
    <Await resolve={vaults}>
      {(vaults) => {
        if (!vaults) return undefined
        const benchmarks = getLatestBenchmarks(vaults)
        return (
          <Tooltip>
            <div className="relative flex min-h-8 flex-wrap items-center justify-center overflow-hidden rounded-2xl border-2 border-white bg-darkGray/15">
              <TooltipTrigger className="text-textSecondary before:absolute before:top-0 before:left-0 before:h-full before:w-full">
                <div className="mx-3 my-1 flex items-center gap-2">
                  <h6 className="text-center font-normal text-sm text-textSecondary">vaults.fyi benchmark rates</h6>
                  <InfoIcon />
                </div>
              </TooltipTrigger>
              <ul className="flex h-full flex-grow bg-white">
                {benchmarks.map(({ label, value }) => (
                  <li
                    key={label}
                    className="flex flex-grow items-center justify-center gap-1 border-white border-s-2 bg-black/5 px-2 py-1"
                  >
                    <span className="text-textSecondary uppercase">{label}:</span>
                    <span className="font-bold text-textPrimary">{value}</span>
                  </li>
                ))}
              </ul>
            </div>
            <TooltipContent className="w-52 text-left md:w-96" sideOffset={12}>
              Benchmark rates are weighted avgs. composed of the following:
              <br />
              <span className="font-semibold">USD:</span> Aave v3 USDC, Aave v3 USDT, sDAI, Compound v3 USDC
              <br />
              <span className="font-semibold">ETH:</span> Lido stETH, ether.fi eETH, Coinbase cbETH, Rocket Pool rETH
            </TooltipContent>
          </Tooltip>
        )
      }}
    </Await>
  )
}
