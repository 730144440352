import type { ChartData } from '@vaultsfyi/common'

export const MULTI_TVL_CHART_DATA_MOCK: ChartData<'date'>[] = [
  {
    date: 1717497795,
    '0x0-ethereum-tvl': 17464,
    '0x1-ethereum-tvl': 0,
    '0x2-ethereum-tvl': 0,
  },
  {
    date: 1717584195,
    '0x0-ethereum-tvl': 10386,
    '0x1-ethereum-tvl': 0,
    '0x2-ethereum-tvl': 0,
  },
  {
    date: 1717670595,
    '0x0-ethereum-tvl': 2789,
    '0x1-ethereum-tvl': 119,
    '0x2-ethereum-tvl': 5296,
  },
  {
    date: 1717756995,
    '0x0-ethereum-tvl': 0,
    '0x1-ethereum-tvl': 0,
    '0x2-ethereum-tvl': 0,
  },
  {
    date: 1717843395,
    '0x0-ethereum-tvl': 0,
    '0x1-ethereum-tvl': 2270,
    '0x2-ethereum-tvl': 0,
  },
  {
    date: 1717929795,
    '0x0-ethereum-tvl': 0,
    '0x1-ethereum-tvl': 3775,
    '0x2-ethereum-tvl': 1039,
  },
  {
    date: 1718016195,
    '0x0-ethereum-tvl': 22755,
    '0x1-ethereum-tvl': 18823,
    '0x2-ethereum-tvl': 27577,
  },
  {
    date: 1718102595,
    '0x0-ethereum-tvl': 34040,
    '0x1-ethereum-tvl': 45422,
    '0x2-ethereum-tvl': 20160,
  },
  {
    date: 1718188995,
    '0x0-ethereum-tvl': 43036,
    '0x1-ethereum-tvl': 64125,
    '0x2-ethereum-tvl': 33062,
  },
  {
    date: 1718275395,
    '0x0-ethereum-tvl': 30429,
    '0x1-ethereum-tvl': 73733,
    '0x2-ethereum-tvl': 35865,
  },
  {
    date: 1718361795,
    '0x0-ethereum-tvl': 19799,
    '0x1-ethereum-tvl': 56164,
    '0x2-ethereum-tvl': 26213,
  },
  {
    date: 1718448195,
    '0x0-ethereum-tvl': 32461,
    '0x1-ethereum-tvl': 60294,
    '0x2-ethereum-tvl': 49711,
  },
  {
    date: 1718534595,
    '0x0-ethereum-tvl': 58456,
    '0x1-ethereum-tvl': 41835,
    '0x2-ethereum-tvl': 58151,
  },
  {
    date: 1718620995,
    '0x0-ethereum-tvl': 49941,
    '0x1-ethereum-tvl': 66307,
    '0x2-ethereum-tvl': 54515,
  },
  {
    date: 1718707395,
    '0x0-ethereum-tvl': 64705,
    '0x1-ethereum-tvl': 80487,
    '0x2-ethereum-tvl': 64295,
  },
  {
    date: 1718793795,
    '0x0-ethereum-tvl': 59516,
    '0x1-ethereum-tvl': 97061,
    '0x2-ethereum-tvl': 78762,
  },
  {
    date: 1718880195,
    '0x0-ethereum-tvl': 67784,
    '0x1-ethereum-tvl': 123700,
    '0x2-ethereum-tvl': 94582,
  },
  {
    date: 1718966595,
    '0x0-ethereum-tvl': 77139,
    '0x1-ethereum-tvl': 146285,
    '0x2-ethereum-tvl': 114261,
  },
  {
    date: 1719052995,
    '0x0-ethereum-tvl': 109066,
    '0x1-ethereum-tvl': 169412,
    '0x2-ethereum-tvl': 137963,
  },
  {
    date: 1719139395,
    '0x0-ethereum-tvl': 127685,
    '0x1-ethereum-tvl': 199654,
    '0x2-ethereum-tvl': 123309,
  },
  {
    date: 1719225795,
    '0x0-ethereum-tvl': 118106,
    '0x1-ethereum-tvl': 189487,
    '0x2-ethereum-tvl': 134784,
  },
  {
    date: 1719312195,
    '0x0-ethereum-tvl': 129636,
    '0x1-ethereum-tvl': 168713,
    '0x2-ethereum-tvl': 143821,
  },
  {
    date: 1719398595,
    '0x0-ethereum-tvl': 139489,
    '0x1-ethereum-tvl': 147751,
    '0x2-ethereum-tvl': 143982,
  },
  {
    date: 1719484995,
    '0x0-ethereum-tvl': 143603,
    '0x1-ethereum-tvl': 153240,
    '0x2-ethereum-tvl': 165209,
  },
  {
    date: 1719571395,
    '0x0-ethereum-tvl': 149212,
    '0x1-ethereum-tvl': 176335,
    '0x2-ethereum-tvl': 164334,
  },
  {
    date: 1719657795,
    '0x0-ethereum-tvl': 164579,
    '0x1-ethereum-tvl': 195103,
    '0x2-ethereum-tvl': 181776,
  },
  {
    date: 1719744195,
    '0x0-ethereum-tvl': 150946,
    '0x1-ethereum-tvl': 190118,
    '0x2-ethereum-tvl': 178565,
  },
  {
    date: 1719830595,
    '0x0-ethereum-tvl': 173838,
    '0x1-ethereum-tvl': 185863,
    '0x2-ethereum-tvl': 174598,
  },
  {
    date: 1719916995,
    '0x0-ethereum-tvl': 172655,
    '0x1-ethereum-tvl': 185464,
    '0x2-ethereum-tvl': 181230,
  },
  {
    date: 1720003395,
    '0x0-ethereum-tvl': 164660,
    '0x1-ethereum-tvl': 175340,
    '0x2-ethereum-tvl': 189640,
  },
  {
    date: 1720089795,
    '0x0-ethereum-tvl': 173466,
    '0x1-ethereum-tvl': 169987,
    '0x2-ethereum-tvl': 179044,
  },
]
