import type { ChangeEvent, ReactNode } from 'react'
import { useCallback, useState } from 'react'

import type { ImageGetterType, SetSelectedItemsType } from '../common/types'

import {
  Dropdown,
  DropdownContent,
  DropdownInput,
  DropdownItem,
  DropdownItemContent,
  DropdownItemText,
  DropdownList,
  DropdownListItem,
  DropdownPlaceholder,
  DropdownSeparator,
  DropdownTrigger,
} from '@/components/atoms/Dropdown'
import { Image } from '@/components/atoms/Image'
import { ScrollArea } from '@/components/atoms/ScrollArea'
import { DescribedImage } from '@/components/molecules/DescribedImage'
import { cn } from '@/utils/cn'

export interface SelectFilterProps {
  items: string[]
  selectedItems: string[]
  setSelectedItems: SetSelectedItemsType
  name: string
  dropdownPrefix: string
  imageGetter?: ImageGetterType
}

interface SelectItemCapitalize {
  capitalize?: boolean
}

export const SelectFilter = ({ items, ...props }: SelectFilterProps & SelectItemCapitalize) => {
  const { selectedItems, name, dropdownPrefix, imageGetter, capitalize } = props

  const [search, setSearch] = useState<string>('')
  const onSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value), [])

  const filteredItems = items.filter((item) => {
    if (search === '') return true
    return item.toLowerCase().includes(search.toLowerCase())
  })

  return (
    <Dropdown>
      <DropdownTrigger>
        {FilterTriggerText({ selectedItems, name, dropdownPrefix, imageGetter, capitalize })}
      </DropdownTrigger>
      <DropdownContent>
        <DropdownInput placeholder={`Find ${name}`} onChange={onSearchChange} value={search} inputMode="text" />
        {filteredItems.length > 0 && (
          <>
            <DropdownSeparator />
            <ScrollArea className="h-48">
              <DropdownList>
                {filteredItems.map((item) => (
                  <SelectItem key={item} item={item} {...props} />
                ))}
              </DropdownList>
            </ScrollArea>
          </>
        )}
      </DropdownContent>
    </Dropdown>
  )
}

interface SelectItemProps extends Omit<SelectFilterProps, 'items' | 'dropdownPrefix'> {
  item: string
}

const SelectItem = ({
  item,
  selectedItems,
  setSelectedItems,
  imageGetter,
  name,
  capitalize,
}: SelectItemProps & SelectItemCapitalize) => {
  const isItemSelected = selectedItems.includes(item)
  const handleItemClick = () =>
    setSelectedItems(
      isItemSelected ? selectedItems.filter((itemInArray) => itemInArray !== item) : [...selectedItems, item]
    )

  return (
    <DropdownListItem>
      <DropdownItem onClick={handleItemClick} isActive={isItemSelected}>
        <DropdownItemContent>
          {imageGetter ? (
            <>
              <Image src={imageGetter(item)} alt={`${item} ${name} image`} loading="lazy" className="min-w-6" />
              <DropdownItemText className={cn(capitalize && 'first-letter:uppercase')}>{item}</DropdownItemText>
            </>
          ) : (
            <DropdownItemText className={cn(capitalize && 'first-letter:uppercase')}>{item}</DropdownItemText>
          )}
        </DropdownItemContent>
      </DropdownItem>
    </DropdownListItem>
  )
}

const FilterTriggerText = (
  props: Omit<SelectFilterProps, 'items' | 'setSelectedItems'> & SelectItemCapitalize
): ReactNode => {
  const { selectedItems, name, dropdownPrefix, imageGetter, capitalize } = props
  const itemsLength = selectedItems.length

  if (itemsLength === 0) return <DropdownPlaceholder>{`${dropdownPrefix} ${name}`}</DropdownPlaceholder>

  if (itemsLength > 1) return <span className={cn(capitalize && 'first-letter:uppercase')}>{itemsLength} selected</span>

  if (imageGetter) {
    return (
      <DescribedImage src={imageGetter(selectedItems[0])} alt={`${selectedItems[0]} ${name} image`}>
        <span className={cn(capitalize && 'first-letter:uppercase')}>{selectedItems[0]}</span>
      </DescribedImage>
    )
  }

  return <span className={cn(capitalize && 'first-letter:uppercase')}>{selectedItems[0]}</span>
}
