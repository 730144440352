import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const DeleteIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M283-130q-31 0-53-22t-22-53v-512h-1.5q-15.5 0-26.5-11t-11-26.5q0-15.5 11-26.5t26.5-11H362v-.5q0-15.5 11-26.5t26.5-11h162q15.5 0 26.5 11t11 26.5v.5h155.5q15.5 0 26.5 11t11 26.5q0 15.5-11 26.5t-26.5 11H753v512q0 31-22 53t-53 22H283Zm395-587H283v512h395v-512ZM402.5-283.5q15.5 0 26.5-11t11-26.5v-280q0-15.5-11-26.5t-26.5-11q-15.5 0-26.5 11T365-601v280q0 15.5 11 26.5t26.5 11Zm156 0q15.5 0 26.5-11t11-26.5v-280q0-15.5-11-26.5t-26.5-11q-15.5 0-26.5 11T521-601v280q0 15.5 11 26.5t26.5 11ZM283-717v512-512Z"
        fill="currentColor"
      />
    </svg>
  )
}
