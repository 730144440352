import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const ZoomOutIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M321-541.5q-15.5 0-26.5-11t-11-26.5q0-15.5 11-26.5t26.5-11h121q15.5 0 26.5 11t11 26.5q0 15.5-11 26.5t-26.5 11H321ZM381.658-326q-106.132 0-179.645-73.454t-73.513-179.5Q128.5-685 201.954-758.5q73.454-73.5 179.5-73.5T561-758.487q73.5 73.513 73.5 179.645 0 42.842-13.5 81.592T584-429l221.617 221.14Q816.5-197 816.5-181.5t-11 26.5q-11 11-26.5 11t-26.375-10.87L531.16-376.5q-29.66 24-68.16 37.25T381.658-326Zm-.158-75q74.5 0 126.25-51.75T559.5-579q0-74.5-51.75-126.25T381.5-757q-74.5 0-126.25 51.75T203.5-579q0 74.5 51.75 126.25T381.5-401Z"
        fill="currentColor"
      />
    </svg>
  )
}
