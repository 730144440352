import { Button, type ButtonProps } from '@/components/atoms/Button'
import { Dropdown, DropdownContent, DropdownList, DropdownListItem, DropdownTrigger } from '@/components/atoms/Dropdown'
import { SortIcon } from '@/components/atoms/Icons'
import { ScrollArea } from '@/components/atoms/ScrollArea'
import { useIsMobile } from '@/hooks/useIsMobile'
import type { SortBySchema } from '@/pages/VaultsListPage/types'
import { vaultNonSortableColumns } from '@/pages/VaultsListPage/types'
import type { Subdomain } from '@/types/Subdomain'
import { arrayIncludes, getFilteredTableColumns, getSortTriggerName } from '@/utils'
import { cn } from '@/utils/cn'

interface SelectSortProps {
  sortBy: SortBySchema
  setSortBy: (val: SortBySchema['sortBy']) => void
  subdomain: Subdomain | undefined
}

export const SelectSort = ({ sortBy, setSortBy, subdomain }: SelectSortProps) => {
  const isMobile = useIsMobile()
  const sortsToRender = getFilteredTableColumns({ subdomain, isMobile }).filter(
    (column) => !arrayIncludes(vaultNonSortableColumns, column)
  )

  return (
    <Dropdown>
      <DropdownTrigger>
        Sort by {getSortTriggerName(sortBy.sortBy)}, {sortBy.asc ? 'asc.' : 'desc.'}
      </DropdownTrigger>
      <DropdownContent>
        <ScrollArea className="h-48">
          <DropdownList>
            {sortsToRender.map((sort) => (
              <SelectItem key={sort} sort={sort} sortBy={sortBy} setSortBy={setSortBy} />
            ))}
          </DropdownList>
        </ScrollArea>
      </DropdownContent>
    </Dropdown>
  )
}

interface SelectItemProps extends Pick<SelectSortProps, 'sortBy' | 'setSortBy'> {
  sort: SortBySchema['sortBy']
}

const SelectItem = ({ sort, sortBy, setSortBy }: SelectItemProps) => {
  const isActive = sortBy.sortBy === sort

  return (
    <DropdownListItem>
      <SortButton className={cn(isActive && 'font-bold')} onClick={() => setSortBy(sort)}>
        <span className="mr-2 block leading-6 first-letter:uppercase">{getSortTriggerName(sort)}</span>
        <SortIcon
          className={cn(
            'transition-all',
            isActive ? 'opacity-100' : 'opacity-0',
            sortBy.asc ? '-scale-y-100' : 'scale-y-100'
          )}
        />
      </SortButton>
    </DropdownListItem>
  )
}

const SortButton = ({ className, ...props }: ButtonProps) => {
  return (
    <Button
      variant="default"
      className={cn(
        'flex w-full cursor-pointer items-center justify-between leading-none transition-all hover:text-decorationPrimary focus-visible:text-decorationPrimary',
        className
      )}
      {...props}
    />
  )
}
