import type { Network } from '@vaultsfyi/common'

const RPC_URLS: Record<Network, string> = {
  mainnet: 'https://mainnet.infura.io/v3',
  arbitrum: 'https://arbitrum-mainnet.infura.io/v3',
  optimism: 'https://optimism-mainnet.infura.io/v3',
  polygon: 'https://polygon-mainnet.infura.io/v3',
  base: 'https://base-mainnet.g.alchemy.com/v2',
  gnosis: 'https://rpc.ankr.com/gnosis',
}

const RPC_KEYS: Record<Network, string> = {
  mainnet: '1d0c559b1f6b4bcf9fe9b29b458b79e3',
  arbitrum: '1d0c559b1f6b4bcf9fe9b29b458b79e3',
  optimism: '1d0c559b1f6b4bcf9fe9b29b458b79e3',
  polygon: '1d0c559b1f6b4bcf9fe9b29b458b79e3',
  base: 'af_BKuV5nSe_XzT94goORxtmxj4QDqlQ',
  gnosis: '0a007ea69fddc3f2f2332c5626ce5d2fc3f5e5c46406d6d3482871f354e20964',
}

export const getRpcUrl = (network: Network): string => {
  return `${RPC_URLS[network]}/${RPC_KEYS[network]}`
}
