import { Suspense, useMemo } from 'react'

import { DataChanger, ProtocolList } from './components'

import { Panel } from '@/components/atoms/Panel'
import { Page } from '@/components/molecules'
import { trpc } from '@/config/trpc'
import { NAME_KEY, PRODUCT_KEY, VERSION_KEY } from '@/constants/stateConstants'
import { useSearchState } from '@/hooks/useSearchState'
import { cn } from '@/utils/cn'

type ProtocolSearchState = { name: string; version: string | null; product: string }

function ChangeProtocolDataPage() {
  const [protocols] = trpc.getAllProtocols.useSuspenseQuery()

  const { searchState, updateSearchState } = useSearchState()
  const handleProtocolChange = ({ name, version, product }: ProtocolSearchState) =>
    updateSearchState([NAME_KEY, name], [VERSION_KEY, version], [PRODUCT_KEY, product])
  const selectedProtocol = useMemo(
    () => ({
      name: searchState.get(NAME_KEY) ?? '',
      version: searchState.get(VERSION_KEY) ?? '',
      product: searchState.get(PRODUCT_KEY) ?? '',
    }),
    [searchState]
  )

  const selectedProtocolWithData = useMemo(
    () =>
      protocols.find(
        (protocol) =>
          protocol.name === selectedProtocol.name &&
          protocol.version === selectedProtocol.version &&
          protocol.product === selectedProtocol.product
      ),
    [selectedProtocol]
  )

  return (
    <Page title="Update data">
      <div
        className={cn(
          'grid grid-cols-[288px,1fr] gap-6',
          '[&:has([data-role=left-column]:hover)]:grid-cols-[488px,1fr] [&:has([data-role=left-column]:hover)_[data-role=vault-info]]:grid-cols-[1fr,24px,82px] [&:has([data-role=left-column]:hover)_td>div]:w-full',
          '[&:has([data-role=left-column]:focus-visible)]:grid-cols-[488px,1fr] [&:has([data-role=left-column]:focus-visible)_[data-role=vault-info]]:grid-cols-[1fr,24px,82px] [&:has([data-role=left-column]:focus-visible)_td>div]:w-full'
        )}
      >
        <div className="flex h-fit flex-col gap-6" data-role="left-column">
          <ProtocolList
            selectedProtocol={selectedProtocol}
            setSelectedProtocol={handleProtocolChange}
            protocols={protocols}
          />
        </div>
        <Panel className="flex flex-col p-4">
          {!selectedProtocolWithData ? (
            <p>
              <b>Please select protocol</b>
            </p>
          ) : (
            <Suspense
              fallback={
                <p>
                  <b>Loading protocol...</b>
                </p>
              }
            >
              <DataChanger selectedProtocol={selectedProtocolWithData} />
            </Suspense>
          )}
        </Panel>
      </div>
    </Page>
  )
}

export const Component = () => ChangeProtocolDataPage()
