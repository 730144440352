import { Panel, PanelTitle } from '@/components/atoms/Panel'
import { Page } from '@/components/molecules'
import { ProtocolsTable } from '@/components/organisms/ProtocolsTable/ProtocolsTable'
import { PROTOCOLS } from '@/constants/protocols'
import { usePageTracking } from '@/hooks'
import { useProtocols } from '@/hooks/list/useProtocols'
import type { BackendVault } from '@/types'
import { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router-dom'

export const Component = () => {
  const { vaults } = useLoaderData() as { vaults: BackendVault[] }

  usePageTracking()

  return (
    <Page title="Protocols">
      <Suspense fallback={<Fallback />}>
        <Await resolve={vaults}>
          {(vaults) => {
            const protocols = useProtocols(vaults)
            const filteredProtocols = protocols.filter((protocol) => PROTOCOLS.includes(protocol.name))
            return <ProtocolsTable protocols={filteredProtocols} />
          }}
        </Await>
      </Suspense>
    </Page>
  )
}

function Fallback() {
  return (
    <Panel>
      <PanelTitle>Loading protocols...</PanelTitle>
    </Panel>
  )
}
