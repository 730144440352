import * as AccordionPrimitive from '@radix-ui/react-accordion'

import { cn } from '@/utils/cn'
import { type VariantProps, cva } from 'class-variance-authority'
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react'

export const Accordion = AccordionPrimitive.Root
Accordion.displayName = 'Accordion'

export const AccordionItem = AccordionPrimitive.Item
AccordionItem.displayName = 'AccordionItem'

export const AccordionTrigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Trigger
    ref={ref}
    className={cn('inline-flex items-center gap-2 font-normal text-base transition-colors', className)}
    {...props}
  />
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const accordionContentVariants = cva(cn('overflow-hidden transition-all'), {
  variants: {
    duration: {
      default: 'data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      long: 'data-[state=closed]:animate-accordion-up-long data-[state=open]:animate-accordion-down-long',
    },
  },
  defaultVariants: {
    duration: 'default',
  },
})

export const AccordionContent = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Content> & VariantProps<typeof accordionContentVariants>
>(({ className, children, duration, ...props }, ref) => (
  <AccordionPrimitive.Content ref={ref} className={cn(accordionContentVariants({ className, duration }))} {...props}>
    {children}
  </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName
