import { format, fromUnixTime } from 'date-fns'

import { ChartTooltip } from '@/components/atoms/Charts/ChartTooltip'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import { formatNumber } from '@/utils'

export const MultiApyChartTooltip = (props: Record<string, any>) => {
  const { label, payload, descriptors } = props

  if (!payload) {
    return <></>
  }

  const dateLabel = label && `${format(fromUnixTime(Number(label)), 'MMM dd, yyyy, HH:mm, O')}`

  return (
    <ChartTooltip>
      <LabeledValueHeader className="!text-xs">{dateLabel}</LabeledValueHeader>
      <LabeledValueContent>
        <LabeledValueValue className="!text-base">
          {payload.map((item: Record<string, any>, index: number) => {
            const key = item.dataKey
            const descriptor = descriptors.find((descriptor: any) => descriptor.key === key)
            return (
              <div className="flex items-center gap-2" key={index}>
                <div className="size-3 rounded" style={{ backgroundColor: descriptor.colors }} />
                <p className="capitalize">
                  {descriptor.name}: {formatNumber(item.payload[key])}%
                </p>
              </div>
            )
          })}
        </LabeledValueValue>
      </LabeledValueContent>
    </ChartTooltip>
  )
}
