import type { Network } from '../types'
import { NETWORKS } from '../types'

export function isNetwork(value: string): value is Network {
  return NETWORKS.includes(value as Network)
}

export function filterNetworks(networks: string[]): Network[] {
  return networks.filter(isNetwork) as Network[]
}
