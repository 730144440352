import { cn } from '@/utils/cn'
import type { HTMLAttributes } from 'react'

export const VaultItemContent = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('flex flex-col flex-wrap gap-x-2 gap-y-4 p-4 md:flex-row md:justify-between md:p-6', className)}
      data-role="vault-item-content"
      {...props}
    />
  )
}

export const VaultTags = ({ className, ...props }: HTMLAttributes<HTMLUListElement>) => {
  return <ul className={cn('flex flex-wrap gap-1', className)} {...props} />
}

export const VaultStatisticsWrapper = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        'flex flex-grow flex-wrap gap-x-2 gap-y-2',
        '[&_[data-role=tile]]:flex-grow md:[&_[data-role=tile]]:max-w-64',
        className
      )}
      data-role="vault-statistics-wrapper"
      {...props}
    />
  )
}
