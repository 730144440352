import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const ShareAppleIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M245-55q-30.938 0-52.969-22.031Q170-99.062 170-130v-432q0-30.938 22.031-52.969Q214.062-637 245-637h85q15.5 0 26.5 11t11 26.5q0 15.5-11 26.5T330-562h-85v432h470v-432h-85q-15.5 0-26.5-11t-11-26.5q0-15.5 11-26.5t26.5-11h85q30.938 0 52.969 22.031Q790-592.938 790-562v432q0 30.938-22.031 52.969Q745.938-55 715-55H245Zm197.5-705.5L404-722q-11.5 11.5-26.5 11.25t-26.478-11.75Q340.5-734 340.25-749t11.25-26.5l102-102q11.318-11.5 26.409-11.5t26.591 11.5l102.643 102.643Q620-764 619.75-749t-10.772 26.5Q597.5-711 582.25-711q-15.25 0-26.75-11.5l-38-38V-365q0 15.5-11 26.5t-26.5 11q-15.5 0-26.5-11t-11-26.5v-395.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
