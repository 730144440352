import { Label } from '@/components/atoms/Label'
import { Switch } from '@/components/atoms/Switch'
import { TVL_UNSTACKED_KEY } from '@/constants/stateConstants'
import { useSearchState } from '@/hooks/useSearchState'

interface StackingSwitchProps {
  onChangeCallback?: (checked: boolean) => void
}

export function StackingSwitch({ onChangeCallback }: StackingSwitchProps) {
  const { searchState, updateSearchState } = useSearchState()

  const tvlUnstacked = searchState.get(TVL_UNSTACKED_KEY) === 'true'
  const checked = !tvlUnstacked
  const handleRewardsToggle = (checked: boolean) => {
    updateSearchState([TVL_UNSTACKED_KEY, !checked])
    onChangeCallback?.(checked)
  }

  return (
    <Label>
      <Switch aria-label="Stack TVL" checked={checked} onCheckedChange={handleRewardsToggle} />
      Stack TVL
    </Label>
  )
}
