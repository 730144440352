import type { BackendVaultDetailedWithChartData } from '@/types'
import type { ChartData, VaultsBenchmark } from '@vaultsfyi/common'
import {
  DAY_IN_SECONDS,
  HOUR_IN_SECONDS,
  getVaultKey,
  interpolateChartData,
  mergeChartData,
  truncateChartData,
} from '@vaultsfyi/common'
import { formatUnits, safeNumber } from '../valueUtils'
import { addBenchmarksChartData } from './addBenchmarksChartData'

export type MultiChartData = ChartData<'date'>
export interface MultiChartDataGroup {
  highResolution: MultiChartData[]
  daily: MultiChartData[]
}

export function composeMultiChartData(
  vaults: BackendVaultDetailedWithChartData[],
  benchmarks: VaultsBenchmark[],
  resolution: number = HOUR_IN_SECONDS
) {
  const transformedChartData = vaults
    .map((vault) => ({
      label: getVaultKey(vault),
      data: vault.chartData.map((data) => ({
        date: data.date,
        apy: data.rangeApy,
        apyWithRewards: data.rangeApy + data.rewardsApy,
        tvl: data.tvl,
        tvlNative: safeNumber(formatUnits(BigInt(data.tvlNative), vault.asset.decimals)) ?? 0,
      })),
    }))
    .map(({ label, data }) => ({
      label,
      data: truncateChartData(data, 'date', resolution),
    }))

  const mergedChartData = mergeChartData(transformedChartData, 'date')
  const interpolatedChartData = interpolateChartData(
    mergedChartData,
    'date',
    ['apy', 'apyWithRewards', 'tvl', 'tvlNative'].flatMap((key) => vaults.map((vault) => getVaultKey(vault, key)))
  )
  addBenchmarksChartData(interpolatedChartData, benchmarks)
  const daily = truncateChartData(interpolatedChartData, 'date', DAY_IN_SECONDS)

  return {
    highResolution: interpolatedChartData,
    daily,
  }
}
