import { filterNetworks } from '@vaultsfyi/common'

import {
  APY_MODE_KEY,
  SEARCH_FOR_KEY,
  SELECTED_NETWORKS_KEY,
  SELECTED_PROTOCOLS_KEY,
  SELECTED_TAGS_KEY,
  SELECTED_TOKENS_KEY,
} from '@/constants/stateConstants'
import { useSearchState } from '@/hooks/useSearchState'
import type { ApyModes } from '@/types'
import { isApyMode } from '@/types'
import { SubdomainType } from '@/types/Subdomain'
import { getSubdomain } from '@/utils/subdomain/getSubdomain'
import { useTvlSearchParams } from '../searchState/useTvlSearchParams'

export function useVaultsSearchParams() {
  const { searchState, updateSearchState } = useSearchState()
  const { selectedTvlRange } = useTvlSearchParams()

  const searchFor = searchState.get(SEARCH_FOR_KEY) ?? ''

  const apyModeRaw = searchState.get(APY_MODE_KEY) ?? ''
  const apyMode: ApyModes = isApyMode(apyModeRaw) ? apyModeRaw : '7day'

  const selectedTags = searchState.getAll(SELECTED_TAGS_KEY)

  const selectedNetworksRaw = searchState.getAll(SELECTED_NETWORKS_KEY)

  const subdomain = getSubdomain()
  const selectedNetworks =
    subdomain?.type === SubdomainType.Network ? [subdomain.name] : filterNetworks(selectedNetworksRaw)
  const selectedProtocols =
    subdomain?.type === SubdomainType.Protocol ? [subdomain.name] : searchState.getAll(SELECTED_PROTOCOLS_KEY)

  const selectedTokens = searchState.getAll(SELECTED_TOKENS_KEY)

  return {
    searchState,
    updateSearchState,
    searchFor,
    apyMode,
    selectedTags,
    selectedNetworks,
    selectedProtocols,
    selectedTokens,
    selectedTvlRange,
  } as const
}
