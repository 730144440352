import { QuestionCircleIcon } from '@/components/atoms/Icons'
import { Markdown } from '@/components/atoms/Markdown'
import type { BackendVaultDetailed } from '@/types'
import { parseVaultDescription } from '@/utils'
import { useMemo } from 'react'
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'

interface Props {
  vault: Pick<BackendVaultDetailed, 'yieldSource' | 'asset'>
}

export function PreviewYieldSource({ vault }: Props) {
  const description = useMemo(() => parseVaultDescription(vault), [vault])
  return (
    <div className="flex flex-col items-center gap-3 sm:flex-row sm:items-start">
      <div className="flex size-12 min-w-12 items-center justify-center rounded-full bg-warning/15 text-warning">
        <QuestionCircleIcon size={24} />
      </div>
      <div className="flex flex-col items-start justify-center gap-2">
        <h4 className="text-base text-textSecondary leading-none">Where does the yield come from?</h4>
        <Markdown remarkPlugins={[remarkGfm, remarkBreaks]}>{description}</Markdown>
      </div>
    </div>
  )
}
