import type { Network } from '@vaultsfyi/common'
import { useMemo } from 'react'

import type { NetworksGasPrices } from '../gas'

import { DAYS_IN_YEAR, ETH_IN_GWEI, ONE_IN_BPS } from '@/constants'
import type { BackendVault, ChainTokenPrices, VaultForOptimizer } from '@/types'
import { getNativeCurrency } from '@/utils/chainUtils'
import { CHAINLINK_PRECISION, sortObjectsByKey } from '@vaultsfyi/common'

export const useVaultsForOptimizer = <T extends BackendVault>(
  vaults: T[],
  assetSymbols: string[],
  amount: number | undefined,
  period: number | undefined,
  gasPrices: NetworksGasPrices,
  chainTokenPrices: ChainTokenPrices | undefined,
  excludeRewards: boolean,
  selectedTags: string[],
  selectedNetworks: string[],
  selectedProtocols: string[],
  selectedTvlRange: [number, number]
): VaultForOptimizer[] => {
  const sortedOptimizerVaults = useMemo(() => {
    const transformFunction = (vault: T) =>
      transformVault(vault, amount, period, chainTokenPrices, gasPrices, excludeRewards)
    const assetFilter = (vault: T) => assetSymbols.includes(vault.asset.symbol)
    const maxDepositFilter = (vault: T) =>
      BigInt(vault.maxDeposit) >= BigInt((amount ?? 0) * 10 ** vault.asset.decimals)
    const tagFilter = (vault: T) => {
      if (selectedTags.length === 0) return true
      return selectedTags.every((tag) => vault.tags?.map((tag) => tag.name).includes(tag))
    }
    const networkFilter = (vault: T) => {
      if (selectedNetworks.length === 0) return true
      return selectedNetworks.includes(vault.network)
    }
    const protocolFilter = (vault: T) => {
      if (selectedProtocols.length === 0) return true
      return selectedProtocols.includes(vault.protocolName)
    }
    const tvlFilter = (vault: T) => {
      const tvl = vault.tvlInUsd
      if (!tvl) return false
      return tvl >= selectedTvlRange[0] && tvl <= selectedTvlRange[1]
    }

    const OptimizerVaults = vaults
      .filter(assetFilter)
      .filter(maxDepositFilter)
      .filter(tagFilter)
      .filter(networkFilter)
      .filter(protocolFilter)
      .filter(tvlFilter)
      .map(transformFunction)

    return sortObjectsByKey(OptimizerVaults, ['yieldInUsd'], false)
  }, [vaults, amount, period, assetSymbols, gasPrices, chainTokenPrices, excludeRewards])

  return sortedOptimizerVaults
}

function transformVault(
  vault: BackendVault,
  amount: number | undefined,
  period: number | undefined,
  chainTokenPrices: ChainTokenPrices | undefined,
  gasPrices: NetworksGasPrices,
  excludeRewards: boolean
) {
  const {
    name,
    address,
    network,
    asset,
    rawApy,
    assetPriceInUsd: assetPriceInUsdWithPrecision,
    transactionCost: transactionCosts,
    lendLink,
    tags,
    rewards: dbRewards,
  } = vault
  const filteredRewards = dbRewards.filter(({ asset, assetPriceInUsd }) => asset && Number(assetPriceInUsd))
  const rewardsApy = filteredRewards.reduce((acc, { apyRaw }) => acc + apyRaw, 0)
  const apyWithRewards = excludeRewards ? rawApy : rawApy + rewardsApy

  const networkCurrencyPrice = chainTokenPrices && chainTokenPrices[network as Network]

  const assetPriceInUsd = Number(assetPriceInUsdWithPrecision) / CHAINLINK_PRECISION
  const amountInUsd = amount && amount * assetPriceInUsd
  const amountScaledByPeriod = amountInUsd && period && (amountInUsd * period) / DAYS_IN_YEAR
  const rewards =
    period && amountScaledByPeriod
      ? filteredRewards.map(({ asset, apyRaw, assetPriceInUsd }) => {
          const rewardPrice = Number(assetPriceInUsd) / CHAINLINK_PRECISION
          return { asset, yieldInToken: (amountScaledByPeriod * apyRaw) / ONE_IN_BPS / rewardPrice }
        })
      : undefined

  const gasPrice = gasPrices[network as Network]
  const txCostsInNativeCurrency =
    gasPrice &&
    Object.entries(transactionCosts).reduce((acc, [transaction, cost]) => {
      if (transaction === 'claim' && (rewardsApy || excludeRewards)) {
        return acc
      }
      return acc + (cost * gasPrice) / ETH_IN_GWEI
    }, 0)
  const txCostsInUsd = txCostsInNativeCurrency && networkCurrencyPrice && txCostsInNativeCurrency * networkCurrencyPrice

  const apyWithRewardsPercentage = apyWithRewards ? apyWithRewards / 100 : 0
  const yieldInToken = amount && period && (amount * (rawApy / 100) * period) / DAYS_IN_YEAR
  const yieldInTokenWithRewards =
    amount && period && (amount * (apyWithRewardsPercentage / 100) * period) / DAYS_IN_YEAR
  const yieldInUsdWithRewards = assetPriceInUsd && yieldInTokenWithRewards && yieldInTokenWithRewards * assetPriceInUsd
  const yieldInUsdWithRewardsAfterFees =
    (yieldInUsdWithRewards && txCostsInUsd ? yieldInUsdWithRewards - txCostsInUsd : undefined) ?? yieldInUsdWithRewards

  const yieldInTokenWithRewardsAfterFees =
    yieldInUsdWithRewardsAfterFees && yieldInUsdWithRewardsAfterFees / assetPriceInUsd

  const apyWithRewardsAfterTxCost =
    period &&
    yieldInTokenWithRewardsAfterFees &&
    amount &&
    (yieldInTokenWithRewardsAfterFees / amount) * ONE_IN_BPS * (DAYS_IN_YEAR / period)

  const annualPercentageLossOnTxCosts =
    txCostsInUsd && amountInUsd && period && (txCostsInUsd / amountInUsd) * ONE_IN_BPS * (DAYS_IN_YEAR / period)

  const chartData = vault.sparklineChartData.map(({ date, apy }) => ({
    date,
    rangeApy: apy,
    rawApy: 0,
    rewardsApy: 0,
  }))

  return {
    name,
    address,
    network,
    asset,
    apy: apyWithRewardsAfterTxCost && Math.round(apyWithRewardsAfterTxCost),
    yieldInUsd: yieldInUsdWithRewardsAfterFees,
    rawApy,
    rawYieldInToken: yieldInToken && yieldInToken / 100,
    rewardsApy,
    rewards: excludeRewards ? undefined : rewards,
    txCostsInNativeCurrency,
    annualPercentageLossOnTxCosts: annualPercentageLossOnTxCosts && Math.round(annualPercentageLossOnTxCosts),
    nativeCurrency: getNativeCurrency(network),
    lendLink,
    tags,
    chartData,
  }
}
