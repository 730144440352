import { useMemo } from 'react'

import type { SortBySchema } from '@/pages/VaultsListPage/types'

import type { VaultForList } from '@/types'
import { sortVaults } from '@/utils'

export function useSortedVaults(vaults: VaultForList[], sortState: SortBySchema): VaultForList[] {
  return useMemo(() => sortVaults(vaults, sortState), [vaults, sortState])
}
