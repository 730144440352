export const formatNumber = (value: number | string, minDigits?: number, maxDigits?: number) => {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    minimumFractionDigits: minDigits ?? 0,
    maximumFractionDigits: maxDigits ?? 2,
  }).format(Number(value))
}

const EXPONENTS: Record<string, number> = {
  K: 1e3,
  M: 1e6,
  B: 1e9,
  T: 1e12,
}

export const parseFormattedNumber = (formattedValue: string) => {
  if (!/^[0-9,]+(\.[0-9]+)?([KMBTkmbt])?$/.test(formattedValue)) {
    return undefined // Invalid format
  }

  const cleanedValue = formattedValue.replace(/,/g, '')

  const compactMatch = cleanedValue.match(/(\d+(?:\.\d+)?)([KMBTkmbt])/)
  if (compactMatch) {
    const numberPart = Number(compactMatch[1])
    const multiplier = EXPONENTS[compactMatch[2].toUpperCase()]
    return numberPart * multiplier
  }

  const parsedValue = parseFloat(cleanedValue)
  return Number.isNaN(parsedValue) ? undefined : parsedValue
}
