const ERROR_MESSAGE = 'Failed to copy to clipboard'

export const copyToClipboard = async (valueToCopy: string) => {
  try {
    if (!navigator.clipboard) {
      throw new Error('Browser do not have support for native clipboard.')
    }
    await navigator.clipboard.writeText(valueToCopy)
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`${ERROR_MESSAGE}: ${error.message}`)
    } else {
      throw new Error(`${ERROR_MESSAGE}.`)
    }
  }
}
