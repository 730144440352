import { Button, type ButtonProps } from './Button'
import { ArrowIcon } from './Icons'

import { cn } from '@/utils/cn'
import { forwardRef } from 'react'

export interface TableSortButtonProps {
  isActive: boolean
  asc: boolean
}

export const TableSortButton = forwardRef<HTMLButtonElement, ButtonProps & TableSortButtonProps>(
  ({ isActive, asc, children, ...props }, ref) => {
    return (
      <Button
        variant="default"
        className={cn(
          'flex items-center capitalize hover:text-decorationPrimary focus-visible:text-decorationPrimary',
          isActive ? 'font-bold text-textPrimary' : 'font-normal text-textSecondary'
        )}
        ref={ref}
        {...props}
      >
        {children}
        <div className="ml-1">
          <ArrowIcon
            size={8}
            direction="up"
            className={cn(isActive && asc ? 'opacity-100' : 'opacity-50', 'h-2 transition-opacity')}
          />
          <ArrowIcon
            size={8}
            direction="down"
            className={cn(isActive && !asc ? 'opacity-100' : 'opacity-50', 'h-2 transition-opacity')}
          />
        </div>
      </Button>
    )
  }
)
