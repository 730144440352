import { Button } from '@/components/atoms/Button'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'

interface Props {
  lendLink: string
  onClick?: () => void
}

export const LendLinkStatistic = ({ lendLink, onClick }: Props) => (
  <div>
    <LabeledValueHeader>Vault link</LabeledValueHeader>
    <LabeledValueContent>
      <LabeledValueValue>
        <Button variant="primary" className="min-w-32" onClick={onClick} asChild>
          <a href={lendLink} target="_blank" rel="noopener noreferrer">
            Deposit
          </a>
        </Button>
      </LabeledValueValue>
    </LabeledValueContent>
  </div>
)
