import type { Network } from '@vaultsfyi/common'

import { formatGasPriceToGwei } from './formatGasPriceToGwei'

import { getRpcUrl } from '@/utils'

export const getGasPrice = async (networkName: Network): Promise<number | undefined> => {
  const url = getRpcUrl(networkName)
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: `{"jsonrpc":"2.0","method":"eth_gasPrice","id":1}`,
  })
  const { result: gasPrice } = await response.json()
  return formatGasPriceToGwei(gasPrice)
}
