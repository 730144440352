import { queryClient } from '@/config/queryClient'
import { getBackendFilters } from '@/config/subdomains'
import { trpcQueryClient } from '@/config/trpc'
import type { BackendProtocol } from '@/types'
import { getSubdomain } from '@/utils/subdomain/getSubdomain'

export async function getProtocol(): Promise<BackendProtocol | null> {
  const subdomain = getSubdomain()
  const filters = getBackendFilters(subdomain?.name)

  if (filters?.protocol) {
    return await queryClient.ensureQueryData({
      queryKey: ['getProtocol', filters.protocol.name, filters.protocol.version],
      queryFn: () => (filters?.protocol ? trpcQueryClient.getProtocol.query({ protocol: filters.protocol }) : null),
    })
  }
  return null
}
