import type { HTMLAttributes } from 'react'

import { WarningIcon } from '@/components/atoms/Icons'
import { Markdown } from '@/components/atoms/Markdown'
import { Panel } from '@/components/atoms/Panel'
import { cn } from '@/utils/cn'
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'

export const Warning = ({ content, className, ...props }: { content: string } & HTMLAttributes<HTMLDivElement>) => {
  return (
    <Panel className={cn('flex flex-col gap-4 bg-warning p-4', className)} {...props}>
      <div className="flex items-center gap-2 text-justify">
        <WarningIcon />
        <Markdown remarkPlugins={[remarkGfm, remarkBreaks]}>{content}</Markdown>
      </div>
    </Panel>
  )
}
