import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const DatabaseIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M480-122.5q-150 0-253.75-45.5T122.5-280v-400q0-65 104.75-111.25T480-837.5q147.5 0 252.5 46.25T837.5-680v400q0 66-104 111.75T480-122.5Zm0-482q86.5 0 175-24.75T760.5-683q-17-29-105.25-54.25T480-762.5q-88.5 0-175 24.5t-105.5 55Q219-652.5 305-628.5t175 24Zm0 203q40.5 0 79.5-4t75-11.5q36-7.5 68.5-18.25T762.5-460v-131.5q-27 14.5-59.25 26T635-546q-36 8-75 12t-80 4q-41 0-80.75-4t-76-12q-36.25-8-68-19.5t-57.75-26V-460q26.5 13.5 58.5 24.5t68.25 18.5q36.25 7.5 75.75 11.5t80 4Zm0 204q46 0 94-7.25t88.25-19Q702.5-235.5 730-250.5t32.5-30v-104Q735.5-371 703-360t-68.75 18.25q-36.25 7.25-75 11.25t-79.25 4q-41 0-80.75-4T323.5-342q-36-7.5-68-18.25t-58-24.75v105.5q5 15 31.75 29.5t67 26.25q40.25 11.75 88.5 19T480-197.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
