import { getSubdomain } from '@/utils/subdomain'

export const OnChainSummerDecoration = () => {
  const subdomain = getSubdomain()

  if (subdomain?.name === 'base') {
    return (
      <div className="pointer-events-none fixed top-0 left-0 h-full w-full select-none before:absolute before:z-negative before:h-full before:w-full before:bg-onchainSummer before:bg-fixed before:bg-no-repeat before:[background-position:1%_16px,100.5%_16px] before:[background-size:auto_97.5vmin,_auto_97.5vmin]" />
    )
  }

  return null
}
