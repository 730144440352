import { routes } from '@/config'
import { BACKEND_URL } from '@/constants'
import { redirect } from 'react-router-dom'

export type IsAuthorizedResponse = { authorized: boolean; image?: string; name?: string }

export async function checkIsAuthorized(): Promise<IsAuthorizedResponse> {
  const json = await fetchAuthorizedData()
  if (!json.authorized) {
    throw redirect(routes.wallbreakerLogin)
  }
  return json
}

export async function fetchAuthorizedData() {
  const response = await fetch(`${BACKEND_URL}/oauth2/isAuthorized`, { credentials: 'include' })
  return response.json()
}
