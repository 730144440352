import { useQuery } from '@tanstack/react-query'
import type { Network } from '@vaultsfyi/common'
import { minutesToMilliseconds } from 'date-fns'

import { getGasPricesPerNetwork } from './getGasPricesPerNetwork'

const REFETCH_INTERVAL = minutesToMilliseconds(1)

export function useGasPrices(networks: readonly Network[]) {
  const { data: gasPrices } = useQuery(['gasPrices', networks], () => getGasPricesPerNetwork(networks), {
    refetchInterval: REFETCH_INTERVAL,
  })

  if (!gasPrices) throw new Error('Gas prices not fetched')

  return { gasPrices }
}
