import { Area, CartesianGrid, ComposedChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { ChartLegend } from '../ChartLegend'

import { ChartContainer } from '@/components/atoms/Charts/ChartContainer'
import { ChartWatermark } from '@/components/atoms/Charts/ChartWatermark'
import { ZoomOutButton } from '@/components/atoms/Charts/ZoomOutButton'
import { useChartZoom } from '@/hooks'
import { useChartDescriptors } from '@/hooks/charts/useChartDescriptors'
import { useChartInteraction } from '@/hooks/charts/useChartInteraction'
import { useChartZoomedIn } from '@/hooks/charts/useChartZoomedIn'
import { useOnMouseUp } from '@/hooks/charts/useOnMouseUp'
import type { ApyModes, BackendVault } from '@/types'
import { formatNumber, getChartColor, getChartDateLabel } from '@/utils'
import type { MultiChartDataGroup } from '@/utils/chartUtils/composeMultiChartData'
import { getBenchmarkKey } from '@vaultsfyi/common'
import { getVaultKey } from '@vaultsfyi/common'
import type { VaultsBenchmark } from '@vaultsfyi/common'
import { useState } from 'react'
import { MultiApyChartTooltip } from './MultiApyChartTooltip'

interface ApyChartProps {
  vaults: BackendVault[]
  benchmarks: VaultsBenchmark[]
  multiChartData: MultiChartDataGroup
  hideLegend?: boolean
  hideXAxis?: boolean
  apyMode: ApyModes
  excludeRewards?: boolean
  zoomable: boolean
}

export const MultiApyChart = ({
  vaults,
  benchmarks,
  multiChartData,
  hideLegend,
  hideXAxis,
  excludeRewards,
  zoomable,
}: ApyChartProps) => {
  const {
    isZoomEnabled,
    state,
    allowDataOverflow,
    xAxisDomain,
    yAxisDomain,
    onChartMouseDown,
    onChartMouseMove,
    onChartMouseUp,
    onChartMouseLeave,
    zoomOut,
  } = useChartZoom({
    initialData: multiChartData.daily as unknown as Record<string, number>[],
    zoomable,
  })

  const { activeLine, hiddenLines, onChartClick, onChartHover, onChartLeave } = useChartInteraction()

  const zoomedIn = useChartZoomedIn(multiChartData.daily, xAxisDomain)
  const chartData = zoomedIn ? multiChartData.highResolution : multiChartData.daily

  const apyKey = excludeRewards ? 'apy' : 'apyWithRewards'

  const chartDescriptors = useChartDescriptors({ key: apyKey, vaults, benchmarks, hiddenDescriptors: hiddenLines })

  const [mouseOut, setMouseOut] = useState(true)
  useOnMouseUp(() => mouseOut && onChartMouseUp(), [mouseOut])

  return (
    <div className="relative p-2">
      <ChartContainer className="md:aspect-auto md:h-64 md:min-h-64">
        <ResponsiveContainer>
          <ComposedChart
            data={chartData}
            onMouseDown={onChartMouseDown}
            onMouseMove={onChartMouseMove}
            onMouseUp={onChartMouseUp}
            onMouseEnter={() => setMouseOut(false)}
            onMouseLeave={() => {
              setMouseOut(true)
              onChartMouseLeave()
            }}
            margin={{ top: 8, right: 0, bottom: 0, left: 6 }}
          >
            <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={!hideXAxis} />
            <XAxis
              dataKey="date"
              scale="time"
              tick={!hideXAxis}
              tickFormatter={getChartDateLabel}
              minTickGap={16}
              tickLine={false}
              height={hideXAxis ? 0 : undefined}
              domain={xAxisDomain}
              allowDataOverflow={allowDataOverflow}
              type={isZoomEnabled ? 'number' : 'category'}
            />
            <YAxis
              tickFormatter={(tick) => `${formatNumber(tick)}%`}
              width={38}
              tickLine={false}
              domain={yAxisDomain}
              allowDataOverflow={allowDataOverflow}
            />
            {chartData.length > 0 && <Tooltip content={<MultiApyChartTooltip descriptors={chartDescriptors} />} />}
            {[...vaults, ...benchmarks].map((entry, index) => {
              const chartKey = 'address' in entry ? getVaultKey(entry, apyKey) : getBenchmarkKey(entry, apyKey)
              const lineStroke = hiddenLines.includes(chartKey)
                ? 'transparent'
                : getChartColor(index, activeLine && activeLine !== chartKey ? '20%' : '100%')

              return (
                <Area
                  key={chartKey}
                  type="monotone"
                  dot={false}
                  dataKey={chartKey}
                  stroke={lineStroke}
                  strokeWidth={1.5}
                  fill="transparent"
                  isAnimationActive={false}
                  onMouseEnter={() => onChartHover(chartKey)}
                  onMouseLeave={() => onChartLeave()}
                />
              )
            })}
            {isZoomEnabled && state.dataKeyAreaLeft && state.dataKeyAreaRight ? (
              <ReferenceArea
                x1={state.dataKeyAreaLeft}
                x2={state.dataKeyAreaRight}
                fill="hsl(var(--gray)/30%)"
                stroke="hsl(var(--gray))"
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
        {isZoomEnabled && (
          <ZoomOutButton onClick={zoomOut} isVisible={state.left !== 'dataMin' && state.right !== 'dataMax'} />
        )}
        <ChartWatermark variant="square" />
      </ChartContainer>
      {!hideLegend && (
        <ChartLegend
          legendElements={chartDescriptors}
          onChartItemClick={onChartClick}
          onChartHover={onChartHover}
          onChartLeave={onChartLeave}
        />
      )}
    </div>
  )
}
