import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const GlobeIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M479.761-90q-80.261 0-151.31-30.658-71.048-30.658-124.092-83.701-53.043-53.044-83.701-124.092Q90-399.5 90-479.761 90-561 120.658-631.67q30.658-70.671 83.701-123.795 53.044-53.125 124.092-83.83Q399.5-870 479.761-870 561-870 631.67-839.287q70.671 30.712 123.795 83.849 53.125 53.138 83.83 123.826Q870-560.925 870-480q0 80.5-30.705 151.549-30.705 71.048-83.83 124.092-53.124 53.043-123.795 83.701Q561-90 479.761-90Zm.239-74q26.609-36.789 46.055-76.645Q545.5-280.5 557.5-325h-155q12 44.5 31.445 84.355Q453.391-200.789 480-164Zm-98-17q-19-34.5-33.25-70.25T325.408-325H206.5q29 51.5 73.5 88.25T382-181Zm196 0q57.5-19 102-55.75T753.5-325H634.592q-9.092 38-23.342 73.75Q597-215.5 578-181ZM175.5-400H310q-3-20.5-4.5-40t-1.5-40q0-20.5 1.5-40t4.5-40H175.5q-5.5 20.5-8 39.838Q165-500.825 165-480t2.5 40.163q2.5 19.337 8 39.837Zm210 0h189q3.5-20.5 5-39.837Q581-459.175 581-480q0-20.825-1.5-40.162-1.5-19.338-5-39.838h-189q-3.5 20.5-5 39.838Q379-500.825 379-480t1.5 40.163q1.5 19.337 5 39.837Zm264.5 0h134.5q5.5-20.5 8-39.837Q795-459.175 795-480q0-20.825-2.5-40.162-2.5-19.338-8-39.838H650q3 20.5 4.5 40t1.5 40q0 20.5-1.5 40t-4.5 40Zm-15.408-235H753.5q-29-51.5-73.5-88.25T578-779q19 34.5 33.25 70.25T634.592-635ZM402.5-635h155q-12-44.5-31.445-84.355Q506.609-759.211 480-796q-26.609 36.789-46.055 76.645Q414.5-679.5 402.5-635Zm-196 0h118.908q9.092-38 23.342-73.75Q363-744.5 382-779q-57.5 19-102 55.75T206.5-635Z"
        fill="currentColor"
      />
    </svg>
  )
}
