import { SELECTED_TAGS_KEY } from '@/constants/stateConstants'
import { useVaultsSearchParams } from '@/hooks'
import type { BackendVault } from '@/types'
import { useMemo } from 'react'
import { SelectFilter } from './SelectFilter'

interface SelectTagFilterProps {
  vaults: Array<BackendVault>
}

export function SelectTagFilter({ vaults }: SelectTagFilterProps) {
  const { selectedTags, updateSearchState } = useVaultsSearchParams()
  const tags = useMemo(
    () => [...new Set(vaults.flatMap((vault) => (vault.tags ? vault.tags.map((tag) => tag.name) : [])))],
    [vaults]
  )

  return (
    <SelectFilter
      items={tags}
      selectedItems={selectedTags}
      setSelectedItems={(selectedTags) => {
        updateSearchState([SELECTED_TAGS_KEY, selectedTags])
      }}
      name={'tag'}
      dropdownPrefix="Filter by"
    />
  )
}
