export function hexToHSL(hex: string) {
  // Convert hex to RGB first
  const red = Number(`0x${hex[1]}${hex[2]}`) / 255
  const green = Number(`0x${hex[3]}${hex[4]}`) / 255
  const blue = Number(`0x${hex[5]}${hex[6]}`) / 255

  const cmin = Math.min(red, green, blue)
  const cmax = Math.max(red, green, blue)
  const delta = cmax - cmin

  let h = 0
  let s = 0
  let l = 0

  if (delta === 0) h = 0
  else if (cmax === red) h = ((green - blue) / delta) % 6
  else if (cmax === green) h = (blue - red) / delta + 2
  else h = (red - green) / delta + 4

  h = Math.round(h * 60)

  if (h < 0) h += 360

  l = (cmax + cmin) / 2
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return `${h} ${s}% ${l}%`
}
