import type { AppRouter } from '@vaultsfyi/backend/src/router'

import { BACKEND_URL } from '@/constants'
import type { CreateTRPCClientOptions } from '@trpc/react-query'
import { createTRPCProxyClient, createTRPCReact, httpLink } from '@trpc/react-query'

export const trpc = createTRPCReact<AppRouter>()
const options: CreateTRPCClientOptions<AppRouter> = {
  links: [
    httpLink({
      url: BACKEND_URL,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: 'include',
        })
      },
    }),
  ],
}

export const trpcClient = trpc.createClient(options)

export const trpcQueryClient = createTRPCProxyClient<AppRouter>(options)
