import mixpanel from 'mixpanel-browser'
import ReactGA from 'react-ga4'

import { getSubdomain } from '@/utils/subdomain/getSubdomain'

export interface EventTrackingProps {
  event: string
  params?: {
    [key: string]: string | number
  }
}

export const trackEvent = ({ event, params }: EventTrackingProps) => {
  const subdomain = getSubdomain()?.prefix ?? 'N/A'

  ReactGA.event(
    {
      category: 'Custom Event',
      action: event,
      ...params,
    },
    {
      subdomain,
    }
  )
  mixpanel.track(event, { ...params, Subdomain: subdomain })
}
