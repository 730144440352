import { formatBips } from '@vaultsfyi/common'

import { Tile } from '@/components/atoms/Tile'
import { ValueIndicator, getValueIndicatorView } from '@/components/atoms/ValueIndicator'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import type { VaultForOptimizer } from '@/types'
import { formatNumber, getPeriodText, isUndefined } from '@/utils'

interface VaultBasicStatisticsProps {
  vault: VaultForOptimizer
  period: number | undefined
  isHighTvlImpact?: boolean
}

export function VaultBasicStatistics({ vault, period, isHighTvlImpact }: VaultBasicStatisticsProps) {
  const { apy, yieldInUsd } = vault

  if (isUndefined(apy) || isUndefined(yieldInUsd)) return null

  const returnsMainContent = `${yieldInUsd > 0 ? '+' : ''}$${formatNumber(yieldInUsd)}`
  const apyMainContent = `${apy > 0 ? '+' : ''}${formatBips(apy)}`

  const periodText = period ? getPeriodText(period) : ''
  const returnsValueIndicator = isHighTvlImpact ? 'mute' : getValueIndicatorView(yieldInUsd)

  return (
    <Tile variant="primary" spacing="medium">
      <div>
        <LabeledValueHeader>Est. Return / APY for {periodText}</LabeledValueHeader>
        <LabeledValueContent className="mt-4">
          <LabeledValueValue>
            <ValueIndicator variant={returnsValueIndicator}>
              {returnsMainContent} / {apyMainContent}
            </ValueIndicator>
          </LabeledValueValue>
        </LabeledValueContent>
      </div>
    </Tile>
  )
}
