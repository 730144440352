import { Await, useLoaderData } from 'react-router-dom'

import { YieldOptimizer } from './components'
import { DEFAULT_PERIOD } from './defaultValues'

import { Image } from '@/components/atoms/Image'
import { Loader, Page } from '@/components/molecules'
import { usePageTracking, useVaultsSearchParams } from '@/hooks'
import type { BackendVault } from '@/types'
import { safeNumber, useTrackInvestmentPanelEvent } from '@/utils'

import { AMOUNT_KEY, EXCLUDE_REWARDS_KEY, PERIOD_KEY, PROTOCOL_CURATED_ONLY_KEY } from '@/constants/stateConstants'
import { Suspense } from 'react'
import bankEmoji from '/images/emojis/bankEmoji.png'

function useOptimizerSearchParams() {
  const { searchState, ...vaultsSearchParams } = useVaultsSearchParams()

  const amount = safeNumber(searchState.get(AMOUNT_KEY) ?? '')
  const period = safeNumber(searchState.get(PERIOD_KEY) ?? '', DEFAULT_PERIOD)
  const excludeRewards = searchState.get(EXCLUDE_REWARDS_KEY) === 'true'
  const protocolCuratedOnly = searchState.get(PROTOCOL_CURATED_ONLY_KEY) === 'true'

  return {
    ...vaultsSearchParams,
    amount,
    period,
    excludeRewards,
    protocolCuratedOnly,
  } as const
}

export const Component = () => {
  const { vaults } = useLoaderData() as { vaults: BackendVault[] }

  usePageTracking()

  const { amount, period, excludeRewards, protocolCuratedOnly, apyMode, selectedTokens, ...props } =
    useOptimizerSearchParams()

  useTrackInvestmentPanelEvent({ amount, selectedTokens, period, excludeRewards, protocolCuratedOnly, apyMode })

  return (
    <Page title="Optimizer">
      <div className="flex flex-col gap-4">
        <Suspense fallback={<Loader />}>
          <Await resolve={vaults}>
            {(vaults) => (
              <>
                <div className="mx-auto flex max-w-52 flex-col items-center text-center sm:max-w-lg">
                  <h1 className="font-normal text-2xl sm:text-3xl">
                    Find the <Image src={bankEmoji} className="inline-block h-8 w-8 translate-y-1 rounded-none" />{' '}
                    <b>vault</b> that's right for you.
                  </h1>
                </div>
                <YieldOptimizer
                  amount={amount}
                  period={period}
                  excludeRewards={excludeRewards}
                  apyMode={apyMode}
                  vaults={vaults}
                  selectedTokens={selectedTokens}
                  {...props}
                />
              </>
            )}
          </Await>
        </Suspense>
      </div>
    </Page>
  )
}
