import type { Asset } from '@vaultsfyi/common'

export const getDisplayedAssetSymbol = (asset: Asset) => {
  switch (asset.symbol) {
    case 'WXDAI':
      return 'DAI'
    default:
      return asset.symbol
  }
}
