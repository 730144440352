import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const CrossedShieldIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="m754-318-60-62q12-32 19-66.5t7-69.5v-189l-240-90-146 55-62-62 180-67q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 51-11.5 101T754-318ZM480-84q-4 0-25-4-135-45-215-166.5T160-516v-172l-76-76q-11-11-11-28t11-28q11-11 28-11t28 11l680 680q11 11 11 28t-11 28q-11 11-28 11t-28-11L662-186q-33 35-72.5 59T505-88q-6 2-12 3t-13 1Zm-57-341Zm91-135Zm-34 396q35-11 67-31t59-47L240-608v92q0 121 68 220t172 132Z"
        fill="currentColor"
      />
    </svg>
  )
}
