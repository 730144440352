import { useState } from 'react'

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/atoms/Accordion'
import { ArrowIcon } from '@/components/atoms/Icons'
import { SelectNetworkFilter, SelectProtocolsFilter, SelectTagFilter } from '@/components/molecules'
import { SelectTvlRange } from '@/components/molecules/filters/SelectTvlRange'
import type { BackendVault } from '@/types'
import { trackInvestmentFiltersToggleEvent } from '@/utils'

export interface InvestmentPickerFiltersProps {
  vaults: Array<BackendVault>
}

export const InvestmentPickerFilters = ({ vaults }: InvestmentPickerFiltersProps) => {
  const [isFiltersOpen, setFiltersOpen] = useState(false)

  const handleFiltersOpen = () => {
    setFiltersOpen((prevState) => !prevState)
    trackInvestmentFiltersToggleEvent(isFiltersOpen)
  }

  return (
    <Accordion type="multiple">
      <AccordionItem value="Investment picker filters" className="bg-darkGray/12 ">
        <AccordionContent>
          <div className="grid grid-cols-1 gap-2 px-4 py-2 md:grid-cols-2">
            <SelectTagFilter vaults={vaults} />
            <SelectProtocolsFilter vaults={vaults} />
            <SelectNetworkFilter vaults={vaults} />
            <SelectTvlRange />
          </div>
        </AccordionContent>
        <AccordionTrigger
          className="group flex h-6 w-full items-center justify-center bg-white px-8 py-0.5 text-center hover:text-decorationPrimary focus-visible:text-decorationPrimary"
          onClick={handleFiltersOpen}
        >
          {isFiltersOpen ? 'Hide' : 'Show'} filters{' '}
          <ArrowIcon className="group-data-[state=open]:-scale-y-100 transition-transform" direction="down" />
        </AccordionTrigger>
      </AccordionItem>
    </Accordion>
  )
}
