import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/atoms/Accordion'
import { Button } from '@/components/atoms/Button'
import { ArrowIcon } from '@/components/atoms/Icons'
import { cn } from '@/utils/cn'
import type { ButtonHTMLAttributes } from 'react'
import { MenuLink, type MenuLinksBasicProps, menuLinkStyles } from './MenuLink'

export const ExpandableLinkGroup = ({
  links,
  title,
  icon,
  className,
}: MenuLinksBasicProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Accordion type="multiple">
      <AccordionItem value={title}>
        <AccordionTrigger className="group" asChild>
          <Button className={menuLinkStyles}>
            {icon}
            {title}
            <ArrowIcon
              direction="down"
              className={cn('ml-2 transition-transform', 'group-data-[state=open]:-scale-y-100', className)}
            />
          </Button>
        </AccordionTrigger>
        <AccordionContent className="ml-3 border-gray border-l pl-3">
          {links.map((linkItem, index) => (
            <MenuLink key={linkItem.name + index} {...linkItem} />
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
