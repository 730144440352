import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const LoadingIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M479.761-90q-80.261 0-151.31-30.658-71.048-30.658-124.092-83.701-53.043-53.044-83.701-124.092Q90-399.5 90-479.761 90-561 120.658-631.67q30.658-70.671 83.701-123.795 53.044-53.125 124.092-83.83Q399.5-870 480-870q15.938 0 26.719 10.794t10.781 26.75q0 15.956-10.781 26.706T480-795q-131.5 0-223.25 91.75T165-480q0 131.5 91.75 223.25T480-165q131.5 0 223.25-91.75T795-480q0-15.938 10.794-26.719t26.75-10.781q15.956 0 26.706 10.781T870-480q0 80.5-30.705 151.549-30.705 71.048-83.83 124.092-53.124 53.043-123.795 83.701Q561-90 479.761-90Z"
        fill="currentColor"
      />
    </svg>
  )
}
