import type { BackendVaultDetailed } from '@/types'
import * as Sentry from '@sentry/react'
import { getDisplayedAssetSymbol } from '../tokenUtils'

const validTemplateSymbols = ['token']
const templateSymbolRegex = /\{\{([^}]+)}}/g

interface TemplateValues {
  token: string
}

export function validateTemplateString(text: string) {
  const invalidPlaceholders =
    text.match(templateSymbolRegex)?.filter((key) => !validTemplateSymbols.includes(key.slice(2, -2))) ?? []
  if (invalidPlaceholders.length > 0) {
    throw new Error(`Invalid template placeholders: ${invalidPlaceholders.join(', ')}`)
  }
}

export function replaceTemplateValues(text: string, data: TemplateValues, logFailure = true): string {
  return text.replace(templateSymbolRegex, (_, key) => {
    const value = data[key as keyof TemplateValues]
    if (logFailure && value === undefined) Sentry.captureMessage(`Template value not found for key: ${key}`, 'warning')
    return value ?? ''
  })
}

export function parseVaultDescription(vault: Pick<BackendVaultDetailed, 'yieldSource' | 'asset'>, logFailure = true) {
  let description = vault.yieldSource

  const TemplateValues: TemplateValues = {
    token: getDisplayedAssetSymbol(vault.asset),
  }

  description = replaceTemplateValues(description, TemplateValues, logFailure)

  // Replace <br/> with new line
  return description.replace(/<br\/>/g, '\n')
}
