import type { SortBySchema } from '@/pages/VaultsListPage/types'

import { TableHead, TableHeader, TableRow } from '@/components/atoms/Table'
import { TableSortButton } from '@/components/atoms/TableSortButton'

interface VaultsHeaderProps {
  sortBy: SortBySchema
  setSortBy: (val: SortBySchema['sortBy']) => void
}

export function ProtocolsTableHeader({ setSortBy, sortBy }: VaultsHeaderProps) {
  return (
    <TableHeader>
      <TableRow>
        <TableHead>
          <TableSortButton
            onClick={() => setSortBy('protocol')}
            isActive={sortBy.sortBy === 'protocol'}
            asc={sortBy.asc}
          >
            Protocol
          </TableSortButton>
        </TableHead>
      </TableRow>
    </TableHeader>
  )
}
