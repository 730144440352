import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const DocumentIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M362-248h236.5q15.5 0 26.5-11t11-26.5q0-15.5-11-26.5t-26.5-11H362q-15.5 0-26.5 11t-11 26.5q0 15.5 11 26.5t26.5 11Zm0-158h236.5q15.5 0 26.5-11t11-26.5q0-15.5-11-26.5t-26.5-11H362q-15.5 0-26.5 11t-11 26.5q0 15.5 11 26.5t26.5 11ZM244-90q-30.938 0-52.969-22.031Q169-134.062 169-165v-630q0-30.938 22.031-52.969Q213.062-870 244-870h285q14.933 0 28.467 5.5Q571-859 581.618-848.407l187.264 186.814Q779.5-651 785.25-637.306 791-623.611 791-608.5V-165q0 30.938-22.031 52.969Q746.938-90 716-90H244Zm279-549.5V-795H244v630h472v-437H560.5q-15.5 0-26.5-11t-11-26.5ZM244-795v193-193 630-630Z"
        fill="currentColor"
      />
    </svg>
  )
}
