import { TableCell, type TableCellProps } from '@/components/atoms/Table'
import { CurationTooltip } from '@/components/molecules/CurationTooltip'
import type { VaultForList } from '@/types'

export const FeaturedCell = ({ isFeatured, ...props }: Pick<VaultForList, 'isFeatured'> & TableCellProps) => {
  return (
    <TableCell data-cell="Featured" {...props}>
      <CurationTooltip isFeatured={isFeatured} side="left" isColored />
    </TableCell>
  )
}
