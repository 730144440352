import { ChartTooltip } from '@/components/atoms/Charts/ChartTooltip'
import { LabeledValueHeader } from '@/components/molecules/LabeledValue'

export const HoldersMissingChartTooltip = () => {
  return (
    <ChartTooltip>
      <LabeledValueHeader>No holder data</LabeledValueHeader>
    </ChartTooltip>
  )
}
