import type { DbWarning } from '@vaultsfyi/common'
import { useState } from 'react'

import { Button } from '@/components/atoms/Button'
import { DeleteIcon } from '@/components/atoms/Icons'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/atoms/Tooltip'
import { trpcQueryClient } from '@/config/trpc'
import type { EditResultProps, EditWarningIdProps, RevalidatorProps } from '@/types'
import { PostResult } from './PostResult'

interface DeleteWarningProps extends EditWarningIdProps, RevalidatorProps {
  deleteWarning: (warning: DbWarning) => void
}

export const DeleteWarning = ({ warningId, revalidate, deleteWarning: deleteWarningState }: DeleteWarningProps) => {
  const [isDeleteDisabled, setDeleteDisabled] = useState<boolean>(false)
  const [isConfirmDisabled, setConfirmDisabled] = useState<boolean>(false)
  const [postResult, setPostResult] = useState<EditResultProps>()
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  const handleDeleteButtonClick = () => {
    setTooltipOpen(true)
  }

  const deleteWarning = async () => {
    try {
      setDeleteDisabled(true)
      setConfirmDisabled(true)
      setPostResult({ message: 'Posting', status: 'Pending' })
      const { message, warning } = await trpcQueryClient.deleteWarning.mutate({
        warningId,
      })
      setPostResult({ message: message, status: 'Success' })
      deleteWarningState(warning)
      revalidate()
    } catch (error) {
      setPostResult({ message: `Error: ${error}`, status: 'Failed' })
    } finally {
      setDeleteDisabled(false)
      setConfirmDisabled(false)
    }
  }

  return (
    <Tooltip open={tooltipOpen} onOpenChange={(open) => setTooltipOpen(open)}>
      <TooltipTrigger asChild>
        <Button variant="icon" onClick={handleDeleteButtonClick} title="Delete this post" disabled={isDeleteDisabled}>
          <DeleteIcon />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <DeleteWarningTooltip
          deleteWarning={deleteWarning}
          postResult={postResult}
          isConfirmDisabled={isConfirmDisabled}
        />
      </TooltipContent>
    </Tooltip>
  )
}

interface DeleteWarningTooltipProps {
  deleteWarning: () => void
  postResult: EditResultProps | undefined
  isConfirmDisabled: boolean
}

const DeleteWarningTooltip = ({ deleteWarning, postResult, isConfirmDisabled }: DeleteWarningTooltipProps) => {
  return (
    <div className="flex w-60 max-w-60 flex-col gap-2">
      <Button variant="secondary" onClick={deleteWarning} disabled={isConfirmDisabled}>
        <DeleteIcon />
        Confirm deletion
      </Button>
      {postResult && <PostResult message={postResult.message} status={postResult.status} />}
    </div>
  )
}
