import type { ImgHTMLAttributes } from 'react'

import { Image } from '@/components/atoms/Image'
import { cn } from '@/utils/cn'

export const DescribedImage = ({ className, children, src, ...props }: ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <div className={cn('flex w-fit items-center gap-2', className)}>
      <Image src={src} {...props} />
      {children}
    </div>
  )
}
