import type { VaultListColumn } from '@/pages/VaultsListPage/types'
import { vaultColumns } from '@/pages/VaultsListPage/types'
import type { Subdomain } from '@/types/Subdomain'
import { SubdomainType } from '@/types/Subdomain'
import { arrayIncludes } from '@/utils'

const removableColumns = {
  [SubdomainType.Network]: ['network', 'favorite', 'featured'],
  [SubdomainType.Protocol]: ['protocol', 'favorite'],
} satisfies Record<SubdomainType, VaultListColumn[]>

const mobileRemovableColumns = ['apy trend'] satisfies VaultListColumn[]

type GetFilteredTableColumnsParams = {
  subdomain: Subdomain | undefined
  isMobile: boolean
}

export function getFilteredTableColumns({ subdomain, isMobile }: GetFilteredTableColumnsParams) {
  return vaultColumns
    .filter((column) => subdomain?.name === 'yearn' || column !== 'featured')
    .filter((column) => !isMobile || !arrayIncludes(mobileRemovableColumns, column))
    .filter((column) => !subdomain || !arrayIncludes(removableColumns[subdomain.type], column))
}
