import type { VaultListColumn } from '@/pages/VaultsListPage/types'

export function getColumnAlign(column: VaultListColumn) {
  switch (column) {
    case 'tvl':
    case 'apy':
    case 'apy trend':
      return 'right'
    default:
      return 'left'
  }
}
