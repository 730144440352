import { useMemo } from 'react'

import { HIGH_TVL_IMPACT_PERCENTAGE } from '@/constants'
import type { BackendVaultDetailed } from '@/types'
import { formatUnits, safeNumber } from '@/utils'

interface VaultTvlImpactProps {
  tvl: BackendVaultDetailed['tvl'] | undefined
  asset: BackendVaultDetailed['asset'] | undefined
  amount: number | undefined
}

interface VaultTvlImpactReturn {
  isHighTvlImpact: boolean
  impactPercentage: number
}

export const useVaultTvlImpact = ({ tvl, asset, amount }: VaultTvlImpactProps): VaultTvlImpactReturn => {
  const { isHighTvlImpact, impactPercentage } = useMemo(() => {
    const vaultTVL = tvl && asset?.decimals ? safeNumber(formatUnits(BigInt(tvl), asset?.decimals)) : undefined
    const isHighTvlImpact = vaultTVL && amount ? amount > (vaultTVL / 100) * HIGH_TVL_IMPACT_PERCENTAGE : undefined
    const impactPercentage = vaultTVL && amount ? (100 / vaultTVL) * amount : undefined
    return { isHighTvlImpact, impactPercentage }
  }, [tvl, asset, amount])

  if (isHighTvlImpact && impactPercentage) {
    return {
      isHighTvlImpact: isHighTvlImpact,
      impactPercentage: impactPercentage,
    }
  }
  return {
    isHighTvlImpact: false,
    impactPercentage: 0,
  }
}
