export const vaultColumns = [
  'favorite',
  'protocol',
  'name',
  'featured',
  'network',
  'token',
  'tvl',
  'apy',
  'apy trend',
  'opener',
] as const

export const vaultNonSortableColumns = ['favorite', 'featured', 'opener', 'apy trend']

export type VaultListColumn = (typeof vaultColumns)[number]

export function isVaultColumn(key: string): key is VaultListColumn {
  return vaultColumns.includes(key as VaultListColumn)
}

export function isVaultSortableColumn(key: string): key is VaultListColumn {
  return !vaultNonSortableColumns.includes(key as VaultListColumn)
}
