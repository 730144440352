import { truncate } from '../valueUtils'
import type { ChartData } from './interpolateChartData'

export function truncateChartData<T extends string>(
  chartData: ChartData<T>[],
  deltaKey: T,
  truncInterval: number
): ChartData<T>[] {
  const truncatedData: ChartData<T>[] = []
  for (let i = 0; i < chartData.length; i++) {
    const lastDataPoint = truncatedData[truncatedData.length - 1]
    const dataPoint = chartData[i]
    const truncatedDate = truncate(dataPoint[deltaKey], truncInterval)
    if (!lastDataPoint || lastDataPoint[deltaKey] !== truncatedDate) {
      truncatedData.push({ ...dataPoint, [deltaKey]: truncatedDate })
    }
  }
  return truncatedData
}
