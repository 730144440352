import { useMemo } from 'react'

import { PanelContentWrapper, PanelHeader, PanelTitle } from '@/components/atoms/Panel'
import { MultiApyChart } from '@/components/molecules/ApyChart/MultiApyChart'
import type { ApyModes, BackendVault } from '@/types'
import type { ChartPeriod } from '@/utils'
import { getChartApyText, getMultiChartDataByPeriod } from '@/utils'
import type { MultiChartDataGroup } from '@/utils/chartUtils/composeMultiChartData'
import type { VaultsBenchmark } from '@vaultsfyi/common'

interface Props {
  vaults: BackendVault[]
  benchmarks: VaultsBenchmark[]
  multiChartData: MultiChartDataGroup
  apyMode: ApyModes
  period: ChartPeriod
  excludeRewards?: boolean
}

export const MultiApyHistoryChart = ({
  vaults,
  benchmarks,
  multiChartData,
  apyMode,
  period,
  excludeRewards,
}: Props) => {
  const chartDataByPeriod = useMemo(() => getMultiChartDataByPeriod(multiChartData, period), [multiChartData, period])

  return (
    <PanelContentWrapper>
      <MultiApyHistoryChartHeader apyMode={apyMode} />
      <MultiApyChart
        vaults={vaults}
        benchmarks={benchmarks}
        multiChartData={chartDataByPeriod}
        apyMode={apyMode}
        excludeRewards={excludeRewards}
        zoomable
      />
    </PanelContentWrapper>
  )
}

export const MultiApyHistoryChartHeader = ({ apyMode }: { apyMode: ApyModes }) => {
  return (
    <PanelHeader>
      <PanelTitle>{getChartApyText(apyMode)}</PanelTitle>
    </PanelHeader>
  )
}
