import { useCallback, useEffect, useState } from 'react'

import { trackVaultsListEvents } from '../hooks'
import { FilterPresets } from './FilterPresets'
import { FloatingFilters } from './Filters'

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/atoms/Accordion'
import { ArrowIcon } from '@/components/atoms/Icons'
import { SelectProtocolsFilter, SelectSort, SelectTagFilter } from '@/components/molecules'
import { CuratedSwitch } from '@/components/molecules/CuratedSwitch'
import { RewardsSwitch } from '@/components/molecules/RewardsSwitch/RewardsSwitch'
import { VAULTS_LIST_FILTERS_OPEN, VAULTS_LIST_FILTERS_TOUCHED } from '@/constants'
import { SEARCH_FOR_KEY, SELECTED_TOKENS_KEY } from '@/constants/stateConstants'
import { useVaultsSortState, useWidthBreakpoint } from '@/hooks'
import { Breakpoints } from '@/styles/breakpoints'
import type { BackendVault } from '@/types'
import type { Subdomain } from '@/types/Subdomain'
import { SubdomainType } from '@/types/Subdomain'
import { getLocalStorageValue, setLocalStorageValue } from '@/utils'
import { useTrackFiltersTrayEvent } from '@/utils/analytics/eventsTracking/filtersTray'
import { cn } from '@/utils/cn'
import { SearchFilter } from './Filters/SearchFilter'

interface Props {
  searchFor: string
  selectedTokens: string[]
  vaults: Array<BackendVault>
  subdomain: Subdomain | undefined
  updateSearchState: (...args: [key: string, value: any][]) => void
}

export const VaultsListFiltersTray = ({ searchFor, selectedTokens, vaults, subdomain, updateSearchState }: Props) => {
  const isSmallDesktop = useWidthBreakpoint(Breakpoints.smallDesktop)
  const { sortState, handleSetSortBy } = useVaultsSortState()

  const tokens = [...new Set(vaults.map((vault) => vault.asset.symbol))]

  const isYearnProtocolSubdomain = subdomain?.type === SubdomainType.Protocol && subdomain.name === 'yearn'
  const isProtocolSubdomain = subdomain?.type === SubdomainType.Protocol

  const onSearchChange = useCallback((value: string) => updateSearchState([SEARCH_FOR_KEY, value]), [updateSearchState])

  useTrackFiltersTrayEvent()

  const FILTERS_ACCORDION_VALUE = 'Vaults list filters'

  const [isFiltersOpen, setFiltersOpen] = useState<boolean>(
    Boolean(getLocalStorageValue(VAULTS_LIST_FILTERS_OPEN)) || isYearnProtocolSubdomain
  )
  const [isFiltersTouched, setFiltersTouched] = useState<boolean>(
    Boolean(getLocalStorageValue(VAULTS_LIST_FILTERS_TOUCHED))
  )

  const handleFiltersOpen = () => {
    setFiltersOpen((prevState) => !prevState)
    setLocalStorageValue(VAULTS_LIST_FILTERS_TOUCHED, true)
    setFiltersTouched?.(true)
  }

  useEffect(() => {
    setLocalStorageValue(VAULTS_LIST_FILTERS_OPEN, isFiltersOpen)
  }, [isFiltersOpen])

  return (
    <Accordion type="multiple" defaultValue={isFiltersOpen ? [FILTERS_ACCORDION_VALUE] : []}>
      <AccordionItem className="overflow-hidden rounded-2xl bg-darkGray/10" value={FILTERS_ACCORDION_VALUE}>
        {!subdomain && <FilterPresets />}
        <div className="grid grid-cols-[repeat(auto-fit,minmax(15rem,1fr))] gap-2 px-4 py-2 lg:grid-cols-4">
          <SearchFilter
            className="col-span-[1/-1] sm:col-auto"
            searchValue={searchFor}
            onSearchChange={onSearchChange}
            data-filter-type="search"
          />
          {!isSmallDesktop && (
            <FloatingFilters
              subdomain={subdomain}
              vaults={vaults}
              tokens={tokens}
              selectedTokens={selectedTokens}
              setSelectedTokens={(selectedTokens) => updateSearchState([SELECTED_TOKENS_KEY, selectedTokens])}
            />
          )}
        </div>
        <AccordionContent>
          <div className="grid grid-cols-[repeat(auto-fit,minmax(15rem,1fr))] gap-2 px-4 pb-2 lg:grid-cols-4">
            {isSmallDesktop && (
              <FloatingFilters
                subdomain={subdomain}
                vaults={vaults}
                tokens={tokens}
                selectedTokens={selectedTokens}
                setSelectedTokens={(selectedTokens) => updateSearchState([SELECTED_TOKENS_KEY, selectedTokens])}
              />
            )}
            {!isProtocolSubdomain && <SelectTagFilter vaults={vaults} />}
            <SelectSort sortBy={sortState} setSortBy={handleSetSortBy} subdomain={subdomain} />
            {!isProtocolSubdomain && <SelectProtocolsFilter vaults={vaults} />}
            <div className={cn('flex flex-wrap items-center gap-2', isYearnProtocolSubdomain && 'md:col-span-2')}>
              {isYearnProtocolSubdomain && (
                <CuratedSwitch
                  onChangeCallback={(checked) => {
                    return trackVaultsListEvents({
                      action: 'Exclude community curated toggled',
                      label: `Community curated switched ${checked ? 'on' : 'off'}`,
                    })
                  }}
                />
              )}
              <RewardsSwitch
                onChangeCallback={(checked) =>
                  trackVaultsListEvents({
                    action: 'Include rewards toggled',
                    label: `Rewards switched ${checked ? 'on' : 'off'}`,
                  })
                }
              />
            </div>
          </div>
        </AccordionContent>
        <AccordionTrigger
          className={cn(
            'group flex h-6 w-full items-center justify-center bg-white px-8 py-0.5 text-center',
            'hover:text-decorationPrimary focus-visible:text-decorationPrimary',
            !isFiltersTouched && 'repeat-infinite animate-button-glow'
          )}
          onClick={handleFiltersOpen}
        >
          {isFiltersOpen ? 'Hide' : 'Show'} filters
          <ArrowIcon direction="down" className="group-data-[state=open]:-scale-y-100 transition-transform" />
        </AccordionTrigger>
      </AccordionItem>
    </Accordion>
  )
}
