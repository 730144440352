import { cn } from '@/utils/cn'
import type { HTMLAttributes } from 'react'

export const ChartContainer = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('relative flex justify-center', className)} data-role="chart-container" {...props}>
      {children}
    </div>
  )
}
