import { PanelContentWrapper, PanelHeader, PanelTitle } from '@/components/atoms/Panel'
import { AssetDetailsChart } from '@/components/molecules/AssetDetailsChart/AssetDetailsChart'
import { ChartLegend } from '@/components/molecules/ChartLegend'
import type { BackendVaultDetailed } from '@/types'
import { getProportionsChartData } from '@/utils'

interface Props {
  vault: BackendVaultDetailed
  tvlInNativeAsset: boolean
}

export const AssetsChart = ({ vault, tvlInNativeAsset }: Props) => {
  const { tvlInUsd, tvl: tvlInToken, liquidity: liquidityInToken, asset } = vault
  const chartData = getProportionsChartData({ tvlInUsd, tvlInToken, liquidityInToken, asset })

  return (
    <PanelContentWrapper>
      <PanelHeader>
        <PanelTitle>Assets</PanelTitle>
      </PanelHeader>
      <div className="mx-auto flex w-full flex-col gap-6">
        <AssetDetailsChart
          chartData={chartData}
          asset={asset}
          tvlInToken={tvlInToken}
          tvlInUsd={tvlInUsd}
          tvlInNativeAsset={tvlInNativeAsset}
        />
        <ChartLegend legendElements={chartData} />
      </div>
    </PanelContentWrapper>
  )
}
