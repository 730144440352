import { useMemo } from 'react'

import { PanelContentWrapper } from '@/components/atoms/Panel'
import { StackingSwitch } from '@/components/molecules/StackingSwitch/StackingSwitch'
import { MultiProtocolTvlChart } from '@/components/molecules/TvlChart/MultiProtocolTvlChart'
import { TVL_IN_NATIVE_ASSET_KEY, TVL_UNSTACKED_KEY } from '@/constants/stateConstants'
import { useSearchState } from '@/hooks/useSearchState'
import { trackVaultsListEvents } from '@/pages/VaultsListPage/hooks'
import type { BackendProtocol } from '@/types'
import type { ChartPeriod } from '@/utils'
import { getMultiChartDataByPeriod } from '@/utils'
import type { MultiChartDataGroup } from '@/utils/chartUtils/composeMultiChartData'
import { MultiTvlHistoryChartHeader } from './MultiTvlHistoryChart'

interface Props {
  protocolData: NonNullable<BackendProtocol>
  multiChartData: MultiChartDataGroup
  period: ChartPeriod
}

export const MultiProtocolTvlHistoryChart = ({ protocolData, multiChartData, period }: Props) => {
  const { searchState } = useSearchState()
  const unstacked = searchState.get(TVL_UNSTACKED_KEY) === 'true'
  const tvlInNativeAsset = searchState.get(TVL_IN_NATIVE_ASSET_KEY) === 'true'

  const chartDataByPeriod = useMemo(() => getMultiChartDataByPeriod(multiChartData, period), [multiChartData, period])

  const handleStacking = (checked: boolean) => {
    trackVaultsListEvents({
      action: 'TVL stacking toggled',
      label: `TVL stacking switched ${checked ? 'on' : 'off'}`,
    })
  }

  const protocolVersions = [...Object.keys(protocolData.versions), ...(protocolData.historicalData ? 'main' : [])]

  return (
    <PanelContentWrapper>
      <MultiTvlHistoryChartHeader>
        {protocolVersions.length > 1 && (
          <nav className="flex flex-wrap gap-2">
            <StackingSwitch onChangeCallback={handleStacking} />
          </nav>
        )}
      </MultiTvlHistoryChartHeader>
      <MultiProtocolTvlChart
        protocolData={protocolData}
        protocolVersions={protocolVersions}
        multiChartData={chartDataByPeriod}
        unstacked={unstacked}
        tvlInNativeAsset={tvlInNativeAsset}
        zoomable
      />
    </PanelContentWrapper>
  )
}
