import { useCallback, useEffect, useState } from 'react'
import type { AxisDomain } from 'recharts/types/util/types'

import { getDeviceType } from '@/utils'

interface ZoomState {
  dataKeyAreaLeft: string
  dataKeyAreaRight: string
  left: string
  right: string
  top: string | number
  bottom: string | number
}

interface ChartZoomProps {
  initialData: Record<string, number>[]
  dataKeys?: string[]
  zoomable: boolean
}

export const useChartZoom = ({ initialData, dataKeys, zoomable }: ChartZoomProps) => {
  const isMobileDevice = getDeviceType() === 'Android' || getDeviceType() === 'iOS'
  const isZoomEnabled = zoomable && !isMobileDevice

  const [state, setState] = useState<ZoomState>({
    dataKeyAreaLeft: '',
    dataKeyAreaRight: '',
    left: 'dataMin',
    right: 'dataMax',
    top: 'auto',
    bottom: 0,
  })

  const zoom = useCallback(() => {
    let { dataKeyAreaLeft, dataKeyAreaRight } = state

    if (dataKeyAreaLeft === dataKeyAreaRight || dataKeyAreaRight === '') {
      setState({
        ...state,
        dataKeyAreaLeft: '',
        dataKeyAreaRight: '',
      })
      return
    }

    if (dataKeyAreaLeft > dataKeyAreaRight) {
      ;[dataKeyAreaLeft, dataKeyAreaRight] = [dataKeyAreaRight, dataKeyAreaLeft]
    }

    const [bottom, top] = getAxisYDomain({
      from: Number(dataKeyAreaLeft),
      to: Number(dataKeyAreaRight),
      dataKeys: dataKeys,
      initialData: initialData,
    })

    setState({
      dataKeyAreaLeft: '',
      dataKeyAreaRight: '',
      left: dataKeyAreaLeft,
      right: dataKeyAreaRight,
      bottom: bottom,
      top: top,
    })
  }, [dataKeys, initialData, state])

  const zoomOut = useCallback(() => {
    setState({
      dataKeyAreaLeft: '',
      dataKeyAreaRight: '',
      left: 'dataMin',
      right: 'dataMax',
      top: 'auto',
      bottom: 0,
    })
  }, [])

  useEffect(() => {
    zoomOut()
  }, [initialData, zoomOut])

  const onChartMouseDown = (e: any): void => {
    if (isZoomEnabled) {
      setState({ ...state, dataKeyAreaLeft: e.activeLabel })
    }
  }

  const onChartMouseMove = (e: any): void => {
    if (isZoomEnabled && e.activeLabel) {
      state.dataKeyAreaLeft && setState({ ...state, dataKeyAreaRight: e.activeLabel })
    }
  }

  const onChartMouseUp = (): void => {
    if (isZoomEnabled) {
      zoom()
    }
  }

  const onChartMouseLeave = (): void => {
    if (isZoomEnabled) {
      const { dataKeyAreaLeft, dataKeyAreaRight } = state

      if (dataKeyAreaRight === '' || dataKeyAreaLeft === '') return

      if (dataKeyAreaRight > dataKeyAreaLeft) {
        setState({
          ...state,
          dataKeyAreaRight: Number.isNaN(Number(state.right))
            ? Math.max(...initialData.map((point) => point.date)).toString()
            : state.right,
        })
      } else {
        setState({
          ...state,
          dataKeyAreaRight: Number.isNaN(Number(state.left))
            ? Math.min(...initialData.map((point) => point.date)).toString()
            : state.left,
        })
      }
    }
  }

  const xAxisDomain: AxisDomain = isZoomEnabled ? [state.left, state.right] : [0, 'auto']
  const yAxisDomain: AxisDomain = isZoomEnabled ? [state.bottom, state.top] : [0, 'auto']

  return {
    isZoomEnabled,
    state,
    allowDataOverflow: Boolean(isZoomEnabled),
    xAxisDomain,
    yAxisDomain,
    onChartMouseDown,
    onChartMouseMove,
    onChartMouseUp,
    onChartMouseLeave,
    zoomOut,
  }
}

interface GetAxisDomainProps {
  from: number
  to: number
  dataKeys?: string[]
  initialData: Record<string, number>[]
}

function getAxisYDomain({ from, to, dataKeys, initialData }: GetAxisDomainProps) {
  if (!dataKeys) return [0, 'auto']
  const domainOffset = 1.1 // 10% threshold
  const chartPeriodTopIndex = initialData.findIndex((element) => element.date >= from)
  const chartPeriodBottomIndex = initialData.findLastIndex((element) => element.date <= to)

  const dataKeyData = initialData.slice(chartPeriodTopIndex, chartPeriodBottomIndex)

  if (dataKeyData.length === 0) {
    return [0, 0]
  }

  let bottom = Infinity
  let top = -Infinity
  for (const dataKey of dataKeys) {
    const { min, max } = findMinMax(dataKeyData, dataKey)
    if (min < bottom) bottom = min
    if (max > top) top = max
  }
  return [Math.floor(bottom / domainOffset), Math.ceil(top * domainOffset)]
}

function findMinMax(data: Record<string, number>[], key: string) {
  let min = data[0][key]
  let max = data[0][key]

  for (let i = 0; i < data.length; i++) {
    const dataPoint = data[i]
    const value = dataPoint[key]
    if (value > max) max = value
    if (value < min) min = value
  }

  return { min, max }
}
