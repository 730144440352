import { trackVaultsListEvents } from '@/pages/VaultsListPage/hooks/trackVaultsListEvents'
import type { VaultListColumn } from '@/pages/VaultsListPage/types'
import { isVaultColumn } from '@/pages/VaultsListPage/types'
import type { SortBySchema } from '@/pages/VaultsListPage/types/VaultsSortBy'

import { ASCENDING_KEY, SORT_BY_KEY } from '@/constants/stateConstants'
import { useSearchState } from '@/hooks/useSearchState'

const DEFAULT_SORT_BY: VaultListColumn = 'apy'

export function useVaultsSortState() {
  const { searchState, updateSearchState } = useSearchState()

  const handleSetSortBy = (name: SortBySchema['sortBy']): void => {
    const descendingByDefault = name === 'tvl' || name === 'apy'
    const sortBy = searchState.get(SORT_BY_KEY)
    const previousAsc = searchState.get(ASCENDING_KEY) === 'true'
    const asc = sortBy === name ? !previousAsc : !descendingByDefault

    updateSearchState([SORT_BY_KEY, name], [ASCENDING_KEY, asc])

    trackVaultsListEvents({
      action: 'Sort state updated',
      label: `${asc ? 'Ascending' : 'Descending'}, by ${name}`,
    })
  }

  const asc = searchState.get(ASCENDING_KEY) === 'true'
  const sortByRaw = searchState.get(SORT_BY_KEY) ?? ''
  const sortBy = isVaultColumn(sortByRaw) ? sortByRaw : DEFAULT_SORT_BY

  const sortState = {
    sortBy,
    asc,
  }

  return { handleSetSortBy, sortState }
}
