export const routes = {
  index: '/',
  optimizer: '/optimizer',
  chartExplorer: '/chart-explorer',
  vaults: '/vaults',
  protocols: '/protocols',
  vaultPreview: '/vaults/:chainName/:address',
  favorites: '/favorites',
  about: '/about',
  wallbreaker: '/wallbreaker',
  wallbreakerLogin: '/wallbreaker/login',
  termsOfService: '/terms-of-service',
  dataUpdater: '/wallbreaker/data-update',
  protocolUpdater: '/wallbreaker/protocol-update',
  aboutChange: '/wallbreaker/about-update',
  wallbreakerCharts: '/wallbreaker/charts/:apyMode',
  protocol: '/protocol/:protocolName',
} as const

export const outdatedRoutes = {
  yieldExplorer: '/yield-explorer',
  explore: '/explore',
  vaultsList: '/vaults-list',
  portfolio: '/portfolio',
} as const
