import { NETWORK_KEY, SELECTED_NETWORKS_KEY } from '@/constants/stateConstants'
import { useVaultsSearchParams } from '@/hooks'
import type { BackendVault } from '@/types'
import { getNetworkImage } from '@/utils'
import { useMemo } from 'react'
import { SelectFilter } from './SelectFilter'

interface SelectNetworkFilterProps {
  vaults: Array<BackendVault>
}

export function SelectNetworkFilter({ vaults }: SelectNetworkFilterProps) {
  const { selectedNetworks, updateSearchState } = useVaultsSearchParams()
  const networks = useMemo(() => [...new Set(vaults.map((vault) => vault.network))], [vaults])

  return (
    <SelectFilter
      items={networks}
      selectedItems={selectedNetworks}
      setSelectedItems={(selectedNetworks) => updateSearchState([SELECTED_NETWORKS_KEY, selectedNetworks])}
      name={NETWORK_KEY}
      dropdownPrefix="Filter by"
      imageGetter={getNetworkImage}
      capitalize
    />
  )
}
