import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const StarIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M480-232 312.5-131q-10.5 6.5-21.75 5.5T271-133q-8.5-6.5-13-16.5t-1.5-22L301-362 153-490q-9.5-8.5-12-19.25t1-20.75q3.5-10 11.75-17t20.75-8.5l195-17 76-179.5q5-11.5 14.5-17t20-5.5q10.5 0 20 5.5t14.5 17l76 179.5 195 17q12.5 1.5 20.75 8.5T818-530q3.5 10 1 20.75T807-490L659-362l44.5 190.5q3 12-1.5 22T689-133q-8.5 6.5-19.75 7.5T647.5-131L480-232Z"
        fill="transparent"
        data-fillable-path
      />
      <path
        d="M350-241.5 480-320l130 79-34.5-148 115-99-151-13.5-59.5-140L420.5-502l-151 13 115 99.5-34.5 148Zm130 9.5L312.5-131q-10.5 6.5-21.75 5.5T271-133q-8.5-6.5-13-16.5t-1.5-22L301-362 153-490q-9.5-8.5-12-19.25t1-20.75q3.5-10 11.75-17t20.75-8.5l195-17 76-179.5q5-11.5 14.5-17t20-5.5q10.5 0 20 5.5t14.5 17l76 179.5 195 17q12.5 1.5 20.75 8.5T818-530q3.5 10 1 20.75T807-490L659-362l44.5 190.5q3 12-1.5 22T689-133q-8.5 6.5-19.75 7.5T647.5-131L480-232Zm0-199Z"
        fill="currentColor"
      />
    </svg>
  )
}
