import { cn } from '@/utils/cn'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react'

export const Switch = forwardRef<
  ElementRef<typeof SwitchPrimitives.Root>,
  ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'peer inline-flex h-6 w-12 shrink-0 cursor-pointer items-center rounded-full border border-gray bg-white p-1 outline-none transition-colors',
      'hover:border-decorationPrimary focus-visible:border-decorationPrimary',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'data-[state=checked]:border-decorationPrimary data-[state=checked]:bg-decorationPrimary',
      className
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      data-role="thumb"
      className={cn(
        'pointer-events-none block size-4 rounded-full bg-gray transition-transform',
        'data-[state=unchecked]:-translate-x-px data-[state=checked]:translate-x-[23px] data-[state=checked]:bg-white'
      )}
    />
  </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName
