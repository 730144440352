import type { Subdomain } from '@/types/Subdomain'
import { SubdomainType } from '@/types/Subdomain'
import { getNetworkImage } from '../chainUtils'
import { getVaultProtocolImage } from '../vaultUtils'

export function getSubdomainImage(subdomain: Subdomain) {
  switch (subdomain.type) {
    case SubdomainType.Network:
      return getNetworkImage(subdomain.name)
    case SubdomainType.Protocol:
      return getVaultProtocolImage(subdomain.name)
  }
}
