import { NETWORKS } from '@vaultsfyi/common'

import { trackYieldOptimizerEvents } from '../hooks'
import type { InvestmentOptimizerProps } from './InvestmentOptimizer'
import { InvestmentOptimizer, InvestmentOptimizerFallback } from './InvestmentOptimizer'
import { InvestmentPicker } from './InvestmentPicker'

import { ApySelectGroup } from '@/components/molecules/ApySelectGroup'
import type { InvestmentData } from '@/components/organisms/VaultItem'
import { trpc } from '@/config/trpc'
import { AMOUNT_KEY, APY_MODE_KEY, PERIOD_KEY, SELECTED_TOKENS_KEY } from '@/constants/stateConstants'
import { useGasPrices } from '@/hooks/gas/useGasPrice'
import { useSearchState } from '@/hooks/useSearchState'
import type { ApyModes, BackendVault } from '@/types'
import type { InvestmentPickerFiltersProps } from './InvestmentPicker/InvestmentPickerFilters'

interface Props {
  amount: number | undefined
  period: number | undefined
  excludeRewards: boolean
  apyMode: ApyModes
  vaults: BackendVault[]
  selectedTokens: string[]
  selectedTags: string[]
  selectedNetworks: string[]
  selectedProtocols: string[]
  selectedTvlRange: [number, number]
  updateSearchState: (...args: [key: string, value: any][]) => void
}

export const YieldOptimizer = ({
  amount,
  period,
  excludeRewards,
  apyMode,
  selectedTokens,
  vaults,
  ...props
}: Props) => {
  const assetSymbols = [...new Set(vaults.map((vault) => vault.asset.symbol))]
  const { updateSearchState } = useSearchState()

  return (
    <>
      <InvestmentPicker
        amount={amount}
        period={period}
        assetSymbols={assetSymbols}
        selectedTokens={selectedTokens}
        setSelectedTokens={(selectedTokens) => updateSearchState([SELECTED_TOKENS_KEY, selectedTokens])}
        setAmount={(amount) => updateSearchState([AMOUNT_KEY, amount])}
        setPeriod={(period) => updateSearchState([PERIOD_KEY, period])}
        vaults={vaults}
        {...props}
      />
      <ApySelectGroup
        value={apyMode}
        setValue={(apyMode) => {
          updateSearchState([APY_MODE_KEY, apyMode])
          trackYieldOptimizerEvents({ action: 'APY range changed', label: `${apyMode} range selected` })
        }}
      />
      <section className="flex w-full max-w-full flex-col gap-4">
        <OptimizerLoader
          apyMode={apyMode}
          assetSymbols={selectedTokens}
          amount={amount}
          period={period}
          excludeRewards={excludeRewards}
          vaults={vaults}
          {...props}
        />
      </section>
    </>
  )
}

type OptimizerLoaderProps = Omit<InvestmentOptimizerProps, 'gasPrices' | 'networkTokenPrices' | 'vaults'> &
  InvestmentData & {
    apyMode: ApyModes
    vaults: BackendVault[]
  } & InvestmentPickerFiltersProps

function OptimizerLoader({ assetSymbols, amount, period, ...props }: OptimizerLoaderProps) {
  const { gasPrices } = useGasPrices(NETWORKS)
  const { data: networkTokenPrices } = trpc.getChainTokenPrices.useQuery()

  const shouldDisplayVaults = assetSymbols.length > 0 && amount && period

  if (!shouldDisplayVaults) {
    return <InvestmentOptimizerFallback />
  }

  return (
    <InvestmentOptimizer
      period={period}
      assetSymbols={assetSymbols}
      amount={amount}
      gasPrices={gasPrices}
      networkTokenPrices={networkTokenPrices}
      {...props}
    />
  )
}
