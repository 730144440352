import { BlurOverlay } from '@/components/atoms/BlurOverlay'
import { LoadingSpinner } from '@/components/atoms/LoadingSpinner'
import { Tile } from '@/components/atoms/Tile'
import { ApyChart } from '@/components/molecules'
import { LabeledValueContent, LabeledValueHeader } from '@/components/molecules/LabeledValue'
import type { ApyChartData, ApyModes } from '@/types'
import { getChartApyText, getChartDataByPeriod } from '@/utils'

interface VaultStatisticsProps {
  apyChartData: ApyChartData[]
  apyMode: ApyModes
}

export function VaultApyTile({ apyChartData, apyMode }: VaultStatisticsProps) {
  const firstNonZeroIndex = apyChartData.findIndex((dataPoint) => dataPoint.rangeApy !== 0 || dataPoint.rawApy !== 0)
  const chartDataByPeriod = getChartDataByPeriod(apyChartData, 'Max', firstNonZeroIndex)

  return (
    <Tile variant="secondary" spacing="medium">
      <div>
        <LabeledValueHeader>Base {getChartApyText(apyMode)}, w/o rewards</LabeledValueHeader>
        <LabeledValueContent className="mt-4">
          <div className="-mx-4 -mb-4">
            {apyChartData ? (
              <ApyChart
                chartData={chartDataByPeriod}
                hideXAxis
                hideLegend
                apyMode={apyMode}
                tooltipView="transparent"
                zoomable={false}
                variant="miniature"
                className="h-20 min-h-20"
              />
            ) : (
              <div className="relative overflow-hidden rounded-lg [&_[data-role=loader]]:h-fit">
                <ApyChart
                  chartData={chartDataByPeriod}
                  hideXAxis
                  hideLegend
                  apyMode={apyMode}
                  tooltipView="transparent"
                  zoomable={false}
                  variant="miniature"
                  className="h-20 min-h-20"
                />
                <BlurOverlay className="absolute inset-0 flex flex-col items-center justify-center gap-4 overflow-hidden rounded-lg backdrop-grayscale-0">
                  <LoadingSpinner />
                  <span>Loading...</span>
                </BlurOverlay>
              </div>
            )}
          </div>
        </LabeledValueContent>
      </div>
    </Tile>
  )
}
