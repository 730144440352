import type { Network } from '@vaultsfyi/common'
import { isAddress } from '@vaultsfyi/common'

import { isTransactionHash } from '../valueUtils'

import { networks } from '@/constants'

const getExplorerLink = (networkName: Network): string | undefined => networks[networkName]

export const getExplorerAddressLink = (address: string, networkName: Network): string => {
  const suffix = `/address/${address}`
  const explorerLink = getExplorerLink(networkName)

  return explorerLink ? explorerLink + suffix : ''
}

const getExplorerBlockLink = (block: number, networkName: Network): string => {
  const suffix = `/block/${block}`
  const explorerLink = getExplorerLink(networkName)

  return explorerLink ? explorerLink + suffix : ''
}

const getExplorerTxLink = (tx: string, networkName: Network): string => {
  const suffix = `/tx/${tx}`
  const explorerLink = getExplorerLink(networkName)

  return explorerLink ? explorerLink + suffix : ''
}

export const getExplorerLinkFromValue = (value: string | number, networkName: Network): string => {
  if (typeof value === 'number' && Number.isSafeInteger(value)) {
    return getExplorerBlockLink(value, networkName)
  }
  if (typeof value === 'string' && isAddress(value)) {
    return getExplorerAddressLink(value, networkName)
  }
  if (typeof value === 'string' && isTransactionHash(value)) {
    return getExplorerTxLink(value, networkName)
  }
  return ''
}
