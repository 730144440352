import { VaultChartsItem } from './VaultChartsItem'

import { useVaultsForList } from '@/hooks'
import type { ApyModes, BackendVault } from '@/types'

interface Props {
  vaults: BackendVault[]
  apyMode: ApyModes
}

export const VaultsChartList = ({ vaults, apyMode }: Props) => {
  const vaultsForList = useVaultsForList(vaults)

  return (
    <>
      {vaultsForList.map((vault) => (
        <VaultChartsItem key={vault.address + vault.network} vault={vault} apyMode={apyMode} />
      ))}
    </>
  )
}
