import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const PlusCircleIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M443-442v119.5q0 15.5 11 26.5t26.5 11q15.5 0 26.5-11t11-26.5V-442h119.5q15.5 0 26.5-11t11-26.5q0-15.5-11-26.5t-26.5-11H518v-120.5q0-15.5-11-26.5t-26.5-11q-15.5 0-26.5 11t-11 26.5V-517H322.5q-15.5 0-26.5 11t-11 26.5q0 15.5 11 26.5t26.5 11H443Zm37 352q-80.907 0-152.065-30.763-71.159-30.763-123.797-83.5Q151.5-257 120.75-328.087 90-399.175 90-480q0-80.907 30.763-152.065 30.763-71.159 83.5-123.797Q257-808.5 328.087-839.25 399.175-870 480-870q80.907 0 152.065 30.763 71.159 30.763 123.797 83.5Q808.5-703 839.25-631.913 870-560.825 870-480q0 80.907-30.763 152.065-30.763 71.159-83.5 123.797Q703-151.5 631.913-120.75 560.825-90 480-90Zm0-75q131.5 0 223.25-91.75T795-480q0-131.5-91.75-223.25T480-795q-131.5 0-223.25 91.75T165-480q0 131.5 91.75 223.25T480-165Zm0-315Z"
        fill="currentColor"
      />
    </svg>
  )
}
