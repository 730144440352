import type { ChangeEvent, InputHTMLAttributes } from 'react'
import { useCallback, useState } from 'react'

import { Input } from '@/components/atoms/Input'

interface SearchFilterProps {
  searchValue: string
  onSearchChange: (value: string) => void
}

export const SearchFilter = ({
  searchValue,
  onSearchChange,
  ...props
}: SearchFilterProps & InputHTMLAttributes<HTMLInputElement>) => {
  const [text, setText] = useState(searchValue ?? '')

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setText(event.target.value)
      onSearchChange(event.target.value)
    },
    [onSearchChange]
  )

  return <Input placeholder="Search" value={text} onChange={onChange} inputMode="text" {...props} />
}
