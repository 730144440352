import type { ComponentPropsWithoutRef } from 'react'

import { cn } from '@/utils/cn'
import { type VariantProps, cva } from 'class-variance-authority'

interface ValueIndicatorProps {
  testId?: string
}

export const indicatorVariants = cva('', {
  variants: {
    variant: {
      positive: 'text-green',
      negative: 'text-red',
      mute: 'text-textSecondary',
      default: 'text-[inherit]',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export const ValueIndicator = ({
  variant = 'default',
  className,
  testId,
  ...props
}: ValueIndicatorProps & ComponentPropsWithoutRef<'span'> & VariantProps<typeof indicatorVariants>) => {
  return <span className={cn(indicatorVariants({ className, variant }))} data-testid={testId} {...props} />
}

export function getValueIndicatorView(
  value: number | null | undefined
): VariantProps<typeof indicatorVariants>['variant'] {
  if (!value || value === 0) return 'default'
  return value > 0 ? 'positive' : 'negative'
}
