import { cn } from '@/utils/cn'
import type { HTMLAttributes } from 'react'

export const PageContent = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <main
      className={cn('flex w-full max-w-full flex-shrink-0 flex-grow basis-full flex-col overflow-hidden', className)}
      {...props}
    >
      {children}
    </main>
  )
}
