import { TableCell, type TableCellProps } from '@/components/atoms/Table'

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/atoms/Tooltip'
import { DescribedImage } from '@/components/molecules/DescribedImage'
import type { VaultForList } from '@/types'
import { getTokenImage } from '@/utils'

interface AssetCellProps extends Pick<VaultForList, 'asset'> {
  testId?: string
}

export const AssetCell = ({ asset, testId, ...props }: AssetCellProps & TableCellProps) => {
  return (
    <TableCell data-cell="Token" {...props}>
      <Tooltip>
        <TooltipTrigger>
          <DescribedImage
            src={getTokenImage(asset.symbol)}
            alt={`${asset.symbol} token image`}
            title={`${asset.symbol} token image`}
            loading="lazy"
          >
            <span className="hidden sm:block" data-testid={`${testId}-asset-symbol`}>
              {asset.symbol}
            </span>
          </DescribedImage>
        </TooltipTrigger>
        <TooltipContent>
          <p>
            <b>Vault asset:</b> {asset.symbol}
          </p>
        </TooltipContent>
      </Tooltip>
    </TableCell>
  )
}
