import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const DropIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M480-85q-134.5 0-224.75-92.5T165-408q0-61 28-122.5T262.5-649q41.5-57 90.25-106.75T442.5-842q8-7.5 17.5-10.75t20-3.25q10.5 0 20 3.25T517.5-842q41.5 36.5 90 86.25t90 106.75Q739-592 767-530.5T795-408q0 138-90.25 230.5T480-85Zm0-75q104 0 172-70.5T720-408q0-73-60.5-165T480-774Q361-665 300.5-573T240-408q0 107 68 177.5T480-160Zm0-320Zm11 280q12-1 20.5-9.5T520-230q0-14-9-22.5t-23-7.5q-41 3-87-22.5T343-375q-2-11-10.5-18t-19.5-7q-14 0-22.75 10.5T284-365q17 91 80 130t127 35Z"
        fill="currentColor"
      />
    </svg>
  )
}
