import { Panel, PanelTitle } from '@/components/atoms/Panel'
import { VaultsTable } from '@/components/organisms/VaultsTable'
import type { ApyModes, VaultForList } from '@/types'
import type { Subdomain } from '@/types/Subdomain'

interface FiltersResultProps {
  vaults: VaultForList[]
  subdomain: Subdomain | undefined
  apyMode: ApyModes
}

export const FiltersResult = ({ vaults, subdomain, apyMode }: FiltersResultProps) => {
  if (vaults.length === 0) {
    return (
      <Panel>
        <PanelTitle>No vaults found...</PanelTitle>
      </Panel>
    )
  }
  return <VaultsTable vaults={vaults} subdomain={subdomain} apyMode={apyMode} />
}
