export type ChartData<T extends string> = Record<T, number> & Record<string, number>

export function interpolateChartData<T extends string>(
  chartData: ChartData<T>[],
  deltaKey: T,
  vaultKeys: string[]
): ChartData<T>[] {
  for (let i = 1; i < chartData.length; i++) {
    const prevDataPoint: ChartData<T> = chartData[i - 1]
    for (const vaultKey of vaultKeys) {
      if (!chartData[i][vaultKey] && prevDataPoint[vaultKey] !== undefined) {
        const nextDataPoint = chartData.slice(i).findIndex((dataPoint) => dataPoint[vaultKey]) + i
        if (nextDataPoint === -1) continue
        const timeDiff = chartData[nextDataPoint][deltaKey] - prevDataPoint[deltaKey]
        if (timeDiff === 0) continue
        const valueDiff = chartData[nextDataPoint][vaultKey] - prevDataPoint[vaultKey]
        const slope = valueDiff / timeDiff
        for (let j = i; j < nextDataPoint; j++) {
          ;(chartData[j] as any)[vaultKey] =
            prevDataPoint[vaultKey] + slope * (chartData[j][deltaKey] - prevDataPoint[deltaKey])
        }
      }
    }
  }
  return chartData
}
