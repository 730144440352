import type { ComponentPropsWithoutRef } from 'react'

import { cn } from '@/utils/cn'

interface BadgeProps {
  testId?: string
  color?: string
}

export const Badge = ({ testId, color, className, ...props }: BadgeProps & ComponentPropsWithoutRef<'span'>) => {
  return (
    <span
      data-testid={testId}
      className={cn(
        'flex h-fit min-h-6 w-fit items-center gap-1 overflow-hidden rounded-2xl px-2 py-0.5 transition-all',
        'font-bold leading-none',
        className
      )}
      style={{
        color: `hsl(${color})`,
        backgroundColor: `hsl(${color} / 16%)`,
      }}
      {...props}
    />
  )
}
