import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/atoms/Accordion'
import type { ImageGetterType, SetSelectedItemsType } from '../common/types'
import type { ItemsGroup } from './SelectFilterByGroup'
import { SelectItem } from './SelectItem'

import { DropdownItem, DropdownListItem } from '@/components/atoms/Dropdown'
import { ArrowIcon } from '@/components/atoms/Icons'
import { cn } from '@/utils/cn'

interface Props {
  groupName: string
  itemsGroup: ItemsGroup
  selectedItems: string[]
  setSelectedItems: SetSelectedItemsType
  imageGetter: ImageGetterType
  name: string
  allowMultipleGroupsSelection?: boolean
}

export const SelectGroup = (props: Props) => {
  const { groupName, itemsGroup, selectedItems, setSelectedItems, allowMultipleGroupsSelection } = props
  const isGroupSelected = itemsGroup.items.every((item) => selectedItems.includes(item))

  const handleGroupTitleClick = () => {
    if (allowMultipleGroupsSelection) {
      return setSelectedItems(
        isGroupSelected
          ? selectedItems.filter((item) => !itemsGroup.items.includes(item))
          : [...selectedItems, ...itemsGroup.items]
      )
    } else {
      return setSelectedItems(isGroupSelected ? [] : itemsGroup.items)
    }
  }

  return (
    <>
      <AccordionItem value={groupName} className="flex w-full flex-col gap-2">
        <DropdownListItem>
          <GroupTitleItem
            name={groupName}
            isActive={isGroupSelected}
            isSelectable={itemsGroup.isSelectable}
            onClick={handleGroupTitleClick}
          />
        </DropdownListItem>
        <AccordionContent className="space-y-2 border-gray border-l pl-3">
          {itemsGroup.items.map((item) => (
            <SelectItem key={item} item={item} {...props} />
          ))}
        </AccordionContent>
      </AccordionItem>
    </>
  )
}

interface GroupTitleItemProps {
  name: string
  onClick: () => void
  isActive: boolean
  isSelectable: boolean
}

function GroupTitleItem({ name, isActive, onClick, isSelectable }: GroupTitleItemProps) {
  if (isSelectable) {
    return (
      <DropdownItem onClick={onClick} isActive={isActive}>
        <AccordionTrigger className="group w-fit">
          <b>{name}</b>
          <ArrowIcon
            direction="down"
            className={cn('ml-auto transition-transform', 'group-data-[state=open]:-scale-y-100')}
          />
        </AccordionTrigger>
      </DropdownItem>
    )
  }
  return (
    <AccordionTrigger className="group w-fit">
      <b>{name}</b>
      <ArrowIcon
        direction="down"
        className={cn('ml-auto transition-transform', 'group-data-[state=open]:-scale-y-100')}
      />
    </AccordionTrigger>
  )
}
