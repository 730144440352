import { format, fromUnixTime } from 'date-fns'
import { Fragment } from 'react'

import { LabeledValueHeader } from '@/components/molecules/LabeledValue'
import { formatNumber } from '@/utils'

export const ApyChartTransparentTooltip = (props: Record<string, any>) => {
  const { label, payload } = props

  if (!payload) {
    return <></>
  }

  const date = label && `${format(fromUnixTime(Number(label)), 'MMMM do')}`

  return (
    <LabeledValueHeader>
      <span>
        {date}:{' '}
        {payload.map((item: Record<string, any>, index: number) => {
          const key = item.dataKey
          return <Fragment key={index}>{formatNumber(item.payload[key])}%</Fragment>
        })}
      </span>
    </LabeledValueHeader>
  )
}
