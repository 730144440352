import { Badge } from '@/components/atoms/Badge'
import type { EditResultProps } from '@/types'
import { cn } from '@/utils/cn'
import { type VariantProps, cva } from 'class-variance-authority'
import type { ComponentPropsWithoutRef } from 'react'

const postVariant = cva(cn('w-full rounded-lg p-2'), {
  variants: {
    status: {
      Success: 'bg-green/15 text-green',
      Failed: 'bg-red/15 text-red',
      Pending: 'bg-warning/15 text-warning',
    },
  },
  defaultVariants: {
    status: 'Pending',
  },
})

export const PostResult = ({
  message,
  status,
  className,
}: EditResultProps & ComponentPropsWithoutRef<'span'> & VariantProps<typeof postVariant>) => {
  return <Badge className={cn(postVariant({ className, status }))}>{message}</Badge>
}
