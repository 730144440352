import { VaultsListRowDetails } from './VaultsListRowDetails'

import type { ApyModes, VaultForList } from '@/types'
import type { Subdomain } from '@/types/Subdomain'

interface VaultsListSubrowProps {
  vault: VaultForList
  apyMode: ApyModes
  columnsCount: number
  subdomain: Subdomain | undefined
}

export const VaultsListSubrow = ({ vault, apyMode, columnsCount, subdomain }: VaultsListSubrowProps) => {
  return (
    <tr className="-translate-y-2 flex rounded-b-lg bg-darkGray/10 sm:table-row sm:translate-y-0 sm:rounded-none">
      <td className="w-full p-3 sm:w-auto" colSpan={columnsCount}>
        <VaultsListRowDetails vault={vault} apyMode={apyMode} subdomain={subdomain} />
      </td>
    </tr>
  )
}
