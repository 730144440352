import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const EditNoteIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M207.5-400q-15.5 0-26.5-11t-11-26.5q0-15.5 11-26.5t26.5-11h205q15.5 0 26.5 11t11 26.5q0 15.5-11 26.5t-26.5 11h-205Zm0-158q-15.5 0-26.5-11t-11-26.5q0-15.5 11-26.5t26.5-11h362q15.5 0 26.5 11t11 26.5q0 15.5-11 26.5t-26.5 11h-362Zm0-157q-15.5 0-26.5-11t-11-26.5q0-15.5 11-26.5t26.5-11h362q15.5 0 26.5 11t11 26.5q0 15.5-11 26.5t-26.5 11h-362ZM515-207.5v-66q0-7.5 2.75-14.5t8.25-12.5l209-208q8.5-8.5 19-12.5t21.5-4q11.5 0 22 4.25t19 12.75l37 37.5q8 8.5 12.25 18.75T870-430.5q0 11-4 21.75t-12.5 19.25L645.5-181q-5.5 5.5-12.5 8.25T618.5-170h-66q-15.5 0-26.5-11t-11-26.5Zm297.5-223-37-37 37 37Zm-240 203h38l123-124-18-19-19-18-124 123v38Zm143-143-19-18 37 37-18-19Z"
        fill="currentColor"
      />
    </svg>
  )
}
