import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuTrigger,
} from '@/components/atoms/Navigation'
import { MenuLink, type MenuLinksBasicProps, menuLinkStyles } from './MenuLink'

export const MenuGroup = ({ links, title, icon }: MenuLinksBasicProps) => {
  return (
    <NavigationMenuItem>
      <NavigationMenuTrigger className={menuLinkStyles}>
        {icon} {title}
      </NavigationMenuTrigger>
      <NavigationMenuContent>
        <ul className="grid w-80 grid-cols-2 gap-2 p-4">
          {links.map((link, index) => (
            <li key={link.name + index}>
              <NavigationMenuLink asChild>
                <MenuLink key={index} className="w-full justify-start lg:justify-start" {...link} />
              </NavigationMenuLink>
            </li>
          ))}
        </ul>
      </NavigationMenuContent>
    </NavigationMenuItem>
  )
}
