import { DAY_IN_SECONDS, truncateChartData } from '@vaultsfyi/common'
import type { BackendProtocol } from '../../types'
import type { MultiChartData } from './composeMultiChartData'

export function composeProtocolTvlChartData(protocolData: NonNullable<BackendProtocol>) {
  const multiChartData: MultiChartData[] = []
  const dataRecord: Record<number, MultiChartData> = {}
  for (const [version, data] of Object.entries(protocolData.versions)) {
    for (const dataPoint of data.historicalData) {
      const date = dataPoint.date
      if (!dataRecord[date]) dataRecord[date] = { date }
      dataRecord[date][`tvl-${version}`] = dataPoint.tvl
    }
  }
  if (protocolData.historicalData)
    for (const dataPoint of protocolData.historicalData) {
      const date = dataPoint.date
      if (!dataRecord[date]) dataRecord[date] = { date }
      dataRecord[date]['tvl-main'] = dataPoint.tvl
    }

  for (const dataPoint of Object.values(dataRecord)) {
    multiChartData.push(dataPoint)
  }

  const daily = truncateChartData(multiChartData, 'date', DAY_IN_SECONDS)

  return {
    highResolution: multiChartData,
    daily,
  }
}
