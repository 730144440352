import { useState } from 'react'

import { VaultsListSubrow } from '@/pages/VaultsListPage/components/VaultsListSubrow'
import {
  ApyCell,
  AssetCell,
  BookmarkCell,
  DetailsButtonCell,
  FeaturedCell,
  NameCell,
  NetworkCell,
  ProtocolCell,
  TvlCell,
} from '@/pages/VaultsListPage/components/cells'
import type { VaultListColumn } from '@/pages/VaultsListPage/types'

import { TableRow } from '@/components/atoms/Table'
import { EXCLUDE_REWARDS_KEY } from '@/constants/stateConstants'
import { useIsMobile } from '@/hooks/useIsMobile'
import { useSearchState } from '@/hooks/useSearchState'
import { SparklineChartCell } from '@/pages/VaultsListPage/components/cells/SparklineChartCell'
import type { ApyModes, VaultForList } from '@/types'
import type { Subdomain } from '@/types/Subdomain'
import { getFilteredTableColumns } from '@/utils'
import { cn } from '@/utils/cn'

export interface VaultsListRowProps {
  vault: VaultForList
  testId?: string
  subdomain: Subdomain | undefined
  apyMode: ApyModes
}

const rowMobileDefaultStyles = cn(
  'grid grid-cols-[repeat(5,_1.5rem)_1fr_1fr_repeat(5,_1.5rem)] gap-0.5 xs:gap-1 rounded-lg bg-white p-2 xs:p-3',
  '[grid-template-areas:"favorite_name_name_name_name_name_name_featured_protocol_network_token_opener"_"tvl_tvl_tvl_tvl_tvl_tvl_apy_apy_apy_apy_apy_apy"]'
)
const rowMobileNetworkSubdomainStyles =
  '[grid-template-areas:"name_name_name_name_name_name_name_name_._protocol_token_opener"_"tvl_tvl_tvl_tvl_tvl_tvl_apy_apy_apy_apy_apy_apy"]'
const rowMobileProtocolSubdomainStyles =
  '[grid-template-areas:"name_name_name_name_name_name_name_name_._network_token_opener"_"tvl_tvl_tvl_tvl_tvl_tvl_apy_apy_apy_apy_apy_apy"]'
const rowMobileYearnSubdomainStyles =
  '[grid-template-areas:"name_name_name_name_name_name_name_name_featured_network_token_opener"_"tvl_tvl_tvl_tvl_tvl_tvl_apy_apy_apy_apy_apy_apy"]'

const rowDesktopStyles = cn(
  'sm:table-row sm:grid-cols-[unset] sm:gap-[unset] sm:rounded-none sm:bg-[unset] sm:p-0 sm:[grid-template-areas:unset]'
)

export const VaultsListRow = ({ vault, testId, subdomain, apyMode }: VaultsListRowProps) => {
  const { searchState } = useSearchState()
  const excludeRewards = searchState.get(EXCLUDE_REWARDS_KEY) === 'true'

  const [isRowOpen, setRowOpen] = useState<boolean>(false)

  const handleRowOpen = () => {
    setRowOpen((prevState) => !prevState)
  }

  const isMobile = useIsMobile()
  const filteredColumns = getFilteredTableColumns({ subdomain, isMobile })

  return (
    <>
      <TableRow
        className={cn(
          rowMobileDefaultStyles,
          subdomain?.type === 'network' && rowMobileNetworkSubdomainStyles,
          subdomain?.type === 'protocol'
            ? subdomain?.name === 'yearn'
              ? rowMobileYearnSubdomainStyles
              : rowMobileProtocolSubdomainStyles
            : '',
          rowDesktopStyles,
          isRowOpen ? 'rounded-b-none' : 'rounded-b-lg',
          subdomain?.type ? '' : ''
        )}
      >
        {filteredColumns.map((column) =>
          getRowCell({ column, vault, testId, isRowOpen, handleRowOpen, excludeRewards })
        )}
      </TableRow>
      {isRowOpen && (
        <VaultsListSubrow vault={vault} apyMode={apyMode} columnsCount={filteredColumns.length} subdomain={subdomain} />
      )}
    </>
  )
}

interface RowCellProps extends Pick<VaultsListRowProps, 'vault' | 'testId'> {
  column: VaultListColumn
  isRowOpen: boolean
  handleRowOpen: () => void
  excludeRewards?: boolean
}

function getRowCell({ column, vault, testId, isRowOpen, handleRowOpen, excludeRewards }: RowCellProps) {
  const { address, name, network, asset, tvlInUsd, apy, protocolName, warnings, isFeatured, sparklineChartData } = vault

  switch (column) {
    case 'favorite':
      return <BookmarkCell key={column} variant="mobile" address={address} network={network} />
    case 'protocol':
      return (
        <ProtocolCell
          key={column}
          variant="mobile"
          className="[grid-area:protocol] sm:[grid-area:unset]"
          protocolName={protocolName}
          testId={testId}
        />
      )
    case 'name':
      return (
        <NameCell
          key={column}
          className="[grid-area:name] sm:[grid-area:unset]"
          variant="mobile"
          name={name}
          address={address}
          network={network}
          testId={testId}
        />
      )
    case 'featured':
      return (
        <FeaturedCell
          key={column}
          className="[grid-area:featured] sm:[grid-area:unset]"
          variant="mobile"
          isFeatured={isFeatured}
        />
      )
    case 'network':
      return (
        <NetworkCell
          key={column}
          variant="mobile"
          className="[grid-area:network] sm:[grid-area:unset]"
          network={network}
          testId={testId}
        />
      )
    case 'token':
      return (
        <AssetCell
          key={column}
          variant="mobile"
          className="[grid-area:token] sm:[grid-area:unset]"
          asset={asset}
          testId={testId}
        />
      )
    case 'tvl':
      return (
        <TvlCell
          key={column}
          variant="mobile"
          className="grid grid-cols-[auto_auto] justify-start [grid-area:tvl] before:font-bold before:content-[attr(data-cell)_':'] sm:table-cell sm:grid-cols-[unset] sm:before:content-[unset] sm:[grid-area:unset]"
          tvlInUsd={tvlInUsd}
          testId={testId}
        />
      )
    case 'apy':
      return (
        <ApyCell
          key={column}
          variant="mobile"
          className="grid grid-cols-[auto_auto] items-center justify-end text-xl [grid-area:apy] before:font-bold before:text-base before:content-[attr(data-cell)_':'] sm:table-cell sm:grid-cols-1 sm:justify-start sm:text-base sm:before:content-[unset] sm:[grid-area:unset]"
          apy={apy}
          warnings={warnings}
          testId={testId}
        />
      )
    case 'apy trend':
      return (
        <SparklineChartCell
          key={column}
          variant="mobile"
          chartData={sparklineChartData}
          excludeRewards={excludeRewards}
        />
      )
    case 'opener':
      return (
        <DetailsButtonCell
          key={column}
          variant="mobile"
          className="[grid-area:opener] sm:py-2 sm:pl-3 sm:[grid-area:unset]"
          isRowOpen={isRowOpen}
          handleRowOpen={handleRowOpen}
        />
      )
  }
}
