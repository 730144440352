import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const QuestionCircleIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M479-247q19.74 0 33.37-13.63Q526-274.26 526-294q0-19.74-13.63-33.37Q498.74-341 479-341q-19.74 0-33.37 13.63Q432-313.74 432-294q0 19.74 13.63 33.37Q459.26-247 479-247Zm1 157q-80.907 0-152.065-30.763-71.159-30.763-123.797-83.5Q151.5-257 120.75-328.087 90-399.175 90-480q0-80.907 30.763-152.065 30.763-71.159 83.5-123.797Q257-808.5 328.087-839.25 399.175-870 480-870q80.907 0 152.065 30.763 71.159 30.763 123.797 83.5Q808.5-703 839.25-631.913 870-560.825 870-480q0 80.907-30.763 152.065-30.763 71.159-83.5 123.797Q703-151.5 631.913-120.75 560.825-90 480-90Zm0-75q131.5 0 223.25-91.75T795-480q0-131.5-91.75-223.25T480-795q-131.5 0-223.25 91.75T165-480q0 131.5 91.75 223.25T480-165Zm0-315Zm4-169q25.403 0 44.202 16Q547-617 547-593q0 21.607-12.75 38.304Q521.5-538 505-523.5q-23 20-41.5 43.25t-18 53.25q0 13.5 9.975 22.25T478.75-396q14.25 0 24.312-9.429Q513.125-414.857 516-429q3.938-20.611 17.719-36.806Q547.5-482 563-497q22.5-21 38.25-46.75T617-600q0-49.681-39.5-81.341Q538-713 484.867-713q-36.71 0-70.038 15.75Q381.5-681.5 362.5-650q-7 11.5-4.578 24.007Q360.344-613.485 371-607q13 8 27.5 4.75T422-618.5q11-14.758 27.5-22.629Q466-649 484-649Z"
        fill="currentColor"
      />
    </svg>
  )
}
