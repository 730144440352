import { Image } from '@/components/atoms/Image'
import arbitrum from '/images/networks/arbitrum.svg'
import base from '/images/networks/base.svg'
import mainnet from '/images/networks/mainnet.svg'
import optimism from '/images/networks/optimism.svg'
import polygon from '/images/networks/polygon.svg'

import type { MenuLinkBasicProps, MenuLinksBasicProps } from './components'
import { HeaderTitle } from './components'

import { PageContainer } from '@/components/atoms/Container'
import {
  ChartIcon,
  DocumentIcon,
  DotsIcon,
  GlobeIcon,
  ListIcon,
  OptimizerIcon,
  QuestionCircleIcon,
  StarIcon,
} from '@/components/atoms/Icons'
import { CloudIcon } from '@/components/atoms/Icons/CloudIcon'
import { routes } from '@/config/routes'
import {
  VAULTS_FYI_API_URL,
  VAULTS_FYI_ARBITRUM_URL,
  VAULTS_FYI_BASE_URL,
  VAULTS_FYI_DOCS_URL,
  VAULTS_FYI_OPTIMISM_URL,
  VAULTS_FYI_POLYGON_URL,
  VAULTS_FYI_URL,
} from '@/constants'
import { getSubdomain } from '@/utils/subdomain'
import { DesktopNavigation } from './components/DesktopNavigation'
import { MobileNavigation } from './components/MobileNavigation'

const LIST_LINK = { icon: <ListIcon size={20} />, name: 'Vaults', to: routes.vaults, isIndex: true }
const CHARTS_LINK = {
  icon: <ChartIcon size={20} />,
  name: 'Charts',
  to: routes.chartExplorer,
  isIndex: true,
}
const FAVORITES_LINK = { icon: <StarIcon size={20} />, name: 'Favorites', to: routes.favorites }
const OPTIMIZER_LINK = { icon: <OptimizerIcon size={20} />, name: 'Optimizer', to: routes.optimizer }
const PROTOCOLS_LINK = { icon: <DocumentIcon size={20} />, name: 'Protocols', to: routes.protocols }
const API_LINK = {
  icon: <CloudIcon size={20} />,
  name: 'API',
  to: VAULTS_FYI_API_URL,
  target: '_blank',
}
const DOCS_LINK = {
  icon: <QuestionCircleIcon size={20} />,
  name: 'Docs',
  to: VAULTS_FYI_DOCS_URL,
  target: '_blank',
}
const MORE_LINKS: MenuLinksBasicProps = {
  title: 'More',
  icon: <DotsIcon />,
  links: [PROTOCOLS_LINK, FAVORITES_LINK, DOCS_LINK],
}
const ARBITRUM_SUBDOMAIN: MenuLinkBasicProps = {
  icon: (
    <Image
      src={arbitrum}
      alt="Arbitrum network image"
      title="Arbitrum network image"
      loading="lazy"
      className="size-5"
    />
  ),
  name: 'Arbitrum',
  to: VAULTS_FYI_ARBITRUM_URL,
  target: '_blank',
}
const BASE_SUBDOMAIN: MenuLinkBasicProps = {
  icon: <Image src={base} alt="Base network image" title="Base network image" loading="lazy" className="size-5" />,
  name: 'Base',
  to: VAULTS_FYI_BASE_URL,
  target: '_blank',
}
const MAINNET_SUBDOMAIN: MenuLinkBasicProps = {
  icon: (
    <Image src={mainnet} alt="Mainnet network image" title="Mainnet network image" loading="lazy" className="size-5" />
  ),
  name: 'Mainnet',
  to: VAULTS_FYI_URL,
  target: '_blank',
}
const OPTIMISM_SUBDOMAIN: MenuLinkBasicProps = {
  icon: (
    <Image
      src={optimism}
      alt="Optimism network image"
      title="Optimism network image"
      loading="lazy"
      className="size-5"
    />
  ),
  name: 'Optimism',
  to: VAULTS_FYI_OPTIMISM_URL,
  target: '_blank',
}
const POLYGON_SUBDOMAIN: MenuLinkBasicProps = {
  icon: (
    <Image src={polygon} alt="Polygon network image" title="Polygon network image" loading="lazy" className="size-5" />
  ),
  name: 'Polygon',
  to: VAULTS_FYI_POLYGON_URL,
  target: '_blank',
}
const NETWORK_LINKS: MenuLinksBasicProps = {
  title: 'Networks',
  expandable: true,
  icon: <GlobeIcon size={20} />,
  links: [ARBITRUM_SUBDOMAIN, BASE_SUBDOMAIN, MAINNET_SUBDOMAIN, OPTIMISM_SUBDOMAIN, POLYGON_SUBDOMAIN],
}

const MENU_LINKS: Array<MenuLinkBasicProps | MenuLinksBasicProps> = [
  LIST_LINK,
  OPTIMIZER_LINK,
  CHARTS_LINK,
  NETWORK_LINKS,
  API_LINK,
  MORE_LINKS,
]
const SUBDOMAIN_MENU_LINKS: Array<MenuLinkBasicProps | MenuLinksBasicProps> = [
  LIST_LINK,
  OPTIMIZER_LINK,
  CHARTS_LINK,
  API_LINK,
  MORE_LINKS,
]

interface HeaderProps {
  hasLayoutRole?: boolean
}

export const Header = ({ hasLayoutRole }: HeaderProps) => {
  const subdomain = getSubdomain()

  return (
    <header>
      <PageContainer className="mx-auto my-0">
        <nav className="flex flex-wrap items-center justify-between gap-4 py-2 md:gap-6 lg:py-4">
          <HeaderTitle />
          <>
            <DesktopNavigation links={subdomain ? SUBDOMAIN_MENU_LINKS : MENU_LINKS} hideDisclaimer={hasLayoutRole} />
            <MobileNavigation links={subdomain ? SUBDOMAIN_MENU_LINKS : MENU_LINKS} hideDisclaimer={hasLayoutRole} />
          </>
        </nav>
      </PageContainer>
    </header>
  )
}
