import { PanelContentWrapper, PanelHeader, PanelTitle } from '@/components/atoms/Panel'
import { HoldersChart } from '@/components/molecules'
import { HoldersMissingChart } from '@/components/molecules/HoldersChart/HoldersMissingCharts'
import type { BackendVaultDetailed } from '@/types'
import { formatAmount, getHoldersChartData } from '@/utils'

interface Props {
  vault: BackendVaultDetailed
  tvlInNativeAsset: boolean
}

export const HolderProportionsChart = ({ vault, tvlInNativeAsset }: Props) => {
  const { holdersTotalBalance, holdersCount, topHolders, network, asset, tvl, tvlInUsd } = vault
  const chartData = getHoldersChartData({
    topHolders,
    holdersCount,
    holdersTotalBalance: holdersTotalBalance ?? '0',
    tvl,
    tvlInUsd,
    network,
    asset,
  })

  return (
    <PanelContentWrapper>
      <PanelHeader>
        <PanelTitle>Holders Distribution</PanelTitle>
      </PanelHeader>
      <div className="flex w-full flex-col gap-6">
        {holdersCount ? (
          <>
            <HoldersChart chartData={chartData} tvlInNativeAsset={tvlInNativeAsset} />
            <span>
              Number of holders: <b>{formatAmount(holdersCount)}</b>
            </span>
          </>
        ) : (
          <>
            <HoldersMissingChart />
            <span>
              Number of holders: <b>N/A</b>
            </span>
          </>
        )}
      </div>
    </PanelContentWrapper>
  )
}
