import type { ReactNode } from 'react'
import { useMemo, useState } from 'react'

import { DescribedImage } from '@/components/molecules/DescribedImage'
import type { ImageGetterType, SetSelectedItemsType } from '../common/types'
import { SelectGroup } from './SelectGroup'

import { Accordion } from '@/components/atoms/Accordion'
import {
  Dropdown,
  DropdownContent,
  DropdownInput,
  DropdownList,
  DropdownPlaceholder,
  DropdownSeparator,
  DropdownTrigger,
} from '@/components/atoms/Dropdown'
import { ScrollArea } from '@/components/atoms/ScrollArea'
import { textIncludes } from '@/utils'

export type ItemsGroup = {
  items: string[]
  isSelectable: boolean
}

export type GroupedItems = {
  [key: string]: ItemsGroup
}

interface Props {
  groupedItems: GroupedItems
  selectedItems: string[]
  setSelectedItems: SetSelectedItemsType
  name: string
  dropdownPrefix: string
  imageGetter: ImageGetterType
  onTriggerClick?: () => void
  className?: string
  allowMultipleGroupsSelection?: boolean
}

export const SelectFilterByGroup = ({ groupedItems, ...props }: Props) => {
  const { selectedItems, name, dropdownPrefix, imageGetter, onTriggerClick, className } = props

  const [search, setSearch] = useState<string>('')

  const filteredGroupedItems = useMemo(
    () =>
      Object.entries(groupedItems).reduce((acc, [groupName, group]) => {
        const filteredGroupItems = group.items.filter((item) => textIncludes(item, search))

        if (filteredGroupItems.length > 0) {
          acc[groupName] = { ...group, items: filteredGroupItems }
        }

        return acc
      }, {} as GroupedItems),
    [groupedItems, search]
  )

  const selectedItemsWithoutGroups = selectedItems.filter((item) => !Object.keys(groupedItems).includes(item))
  const selectedGroupName = Object.keys(groupedItems).find(
    (groupName) => selectedItems.filter((item) => groupedItems[groupName].items.includes(item)).length > 0
  )
  const itemsPresent = Object.values(groupedItems).reduce((sum, curr) => sum + curr.items.length, 0) > 0

  return (
    <Dropdown>
      <DropdownTrigger className={className} onClick={onTriggerClick}>
        {FilterTriggerText({
          selectedItems: selectedItemsWithoutGroups,
          groupName: selectedGroupName,
          name,
          dropdownPrefix,
          imageGetter,
          itemsPresent,
        })}
      </DropdownTrigger>
      <DropdownContent>
        <DropdownInput
          placeholder={`Find ${name}`}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          inputMode="text"
        />
        {Object.keys(filteredGroupedItems).length > 0 && (
          <>
            <DropdownSeparator />
            <ScrollArea className="h-48">
              <Accordion type="multiple">
                <DropdownList>
                  {Object.keys(filteredGroupedItems).map((key) => (
                    <SelectGroup key={key} groupName={key} itemsGroup={filteredGroupedItems[key]} {...props} />
                  ))}
                </DropdownList>
              </Accordion>
            </ScrollArea>
          </>
        )}
      </DropdownContent>
    </Dropdown>
  )
}

interface FilterTriggerTextProps {
  selectedItems: string[]
  groupName: string | undefined
  name: string
  dropdownPrefix: string
  imageGetter: ImageGetterType
  itemsPresent: boolean
}

const FilterTriggerText = (props: FilterTriggerTextProps): ReactNode => {
  const { selectedItems, groupName, name, dropdownPrefix, imageGetter, itemsPresent } = props
  const itemsLength = selectedItems.length

  if (selectedItems.length === 0 || !itemsPresent) {
    return <DropdownPlaceholder>{`${dropdownPrefix} ${name}`}</DropdownPlaceholder>
  }

  if (itemsLength > 1 && groupName) return <span className="first-letter:uppercase">{groupName}</span>

  return (
    <>
      <DescribedImage src={imageGetter(selectedItems[0])} alt={`${selectedItems[0]} ${name} image`} loading="lazy">
        {selectedItems[0]}
      </DescribedImage>
    </>
  )
}
