export function getNativeCurrency(network: string) {
  switch (network) {
    case 'polygon':
      return 'matic'
    case 'gnosis':
      return 'xdai'
    default:
      return 'eth'
  }
}
