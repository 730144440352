import { Label } from '@/components/atoms/Label'
import { Switch } from '@/components/atoms/Switch'
import { TVL_IN_NATIVE_ASSET_KEY } from '@/constants/stateConstants'
import { useSearchState } from '@/hooks/useSearchState'

interface NativeAssetTvlSwitchProps {
  onChangeCallback?: (checked: boolean) => void
}

export function NativeAssetTvlSwitch({ onChangeCallback }: NativeAssetTvlSwitchProps) {
  const { searchState, updateSearchState } = useSearchState()

  const nativeAsset = searchState.get(TVL_IN_NATIVE_ASSET_KEY) === 'true'
  const handleToggle = (checked: boolean) => {
    updateSearchState([TVL_IN_NATIVE_ASSET_KEY, checked])
    onChangeCallback?.(checked)
  }

  return (
    <Label>
      <Switch aria-label="TVL in native asset" checked={nativeAsset} onCheckedChange={handleToggle} />
      TVL in native asset
    </Label>
  )
}
