export function getLocalStorageValue(key: string): string | null | undefined {
  try {
    const storedValue = localStorage.getItem(key)
    if (storedValue) {
      return JSON.parse(storedValue)
    }
  } catch (error) {
    console.error('Local storage is disabled for this user agent', error)
    return null
  }
}
