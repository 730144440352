import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const GoogleIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M21.7412 9.20909C20.173 7.73636 18.2094 6.97273 16.0003 6.97273C12.1003 6.97273 8.78665 9.60455 7.60028 13.15L7.6 13.1498V13.1501C7.3 14.0501 7.12273 15.0046 7.12273 16.0001C7.12273 16.9955 7.3 17.9501 7.6 18.8501L7.5821 18.8638H7.60028C8.78665 22.4092 12.1003 25.041 16.0003 25.041C18.0183 25.041 19.7228 24.4957 21.0591 23.5958V23.5954C22.6545 22.5318 23.7182 20.95 24.0727 19.0818H16V13.2727H30.1273C30.3045 14.2545 30.4 15.2773 30.4 16.3409C30.4 20.909 28.7637 24.7545 25.9273 27.3727L25.9276 27.3729C23.4457 29.6638 20.0503 31.0001 16.0003 31.0001C10.1366 31.0001 5.07756 27.632 2.60938 22.7365V22.7226L2.60909 22.7228C1.58636 20.7046 1 18.4273 1 16.0001C1 13.5728 1.58636 11.2955 2.60909 9.27734H2.60947L2.60938 9.27727C5.07756 4.36818 10.1366 1 16.0003 1C20.0503 1 23.4321 2.48636 26.0366 4.91364L21.7412 9.20909Z"
        fill="currentColor"
      />
    </svg>
  )
}
