import { DescribedImage } from '@/components/molecules/DescribedImage'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import type { VaultMetadata } from '@/types'
import { getVaultProtocolImage, getVaultProtocolName } from '@/utils'

interface Props {
  metadata: VaultMetadata
}

export const ProtocolStatistic = ({ metadata }: Props) => {
  const protocolName = getVaultProtocolName(metadata)
  const protocolImage = getVaultProtocolImage(metadata.protocol.name)

  return (
    <div>
      <LabeledValueHeader>Protocol</LabeledValueHeader>
      <LabeledValueContent>
        <LabeledValueValue>
          <DescribedImage src={protocolImage} alt={`${protocolName} image`} loading="lazy">
            <span className="first-letter:uppercase">{protocolName}</span>
          </DescribedImage>
        </LabeledValueValue>
      </LabeledValueContent>
    </div>
  )
}
