import type { DependencyList } from 'react'
import { useEffect } from 'react'

type DebounceFunction = (...args: any[]) => void

export function useDebounce(func: DebounceFunction, deps: DependencyList, timeout: number) {
  useEffect(() => {
    const trackTimeout = setTimeout(() => {
      func()
    }, timeout)
    return () => {
      clearTimeout(trackTimeout)
    }
  }, [func, timeout, deps])
}
