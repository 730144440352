import { useMemo } from 'react'

import type { BackendVault } from '@/types'
import type { DbProtocol } from '@vaultsfyi/common'

export type VaultProtocol = Pick<DbProtocol, 'name'>

export function useProtocols(vaults: BackendVault[]): VaultProtocol[] {
  const protocols = new Set<string>()
  for (const vault of vaults) {
    protocols.add(vault.protocolName)
  }
  return useMemo(() => Array.from(protocols).map((name) => ({ name })), [vaults])
}
