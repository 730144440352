import { NavLink, generatePath, useLoaderData } from 'react-router-dom'

import { Button } from '@/components/atoms/Button'
import { DatabaseIcon, DocumentIcon, ListIcon, LogoutIcon } from '@/components/atoms/Icons'
import { Image } from '@/components/atoms/Image'
import { Panel } from '@/components/atoms/Panel'
import { Page } from '@/components/molecules'
import { routes } from '@/config'
import { BACKEND_URL } from '@/constants'
import type { IsAuthorizedResponse } from '@/utils/checkIsAuthorized'

function WallbreakerPage() {
  const { authorized, image, name } = useLoaderData() as IsAuthorizedResponse

  return (
    <Page title="Wallbreaker">
      <Panel className="mx-auto flex w-fit min-w-56 flex-col items-center justify-center gap-4 p-4 [&_a_span]:w-full">
        {authorized && (
          <div className="mb-2 flex w-full flex-col items-center gap-4">
            <h2 className="font-bold">Logged in as</h2>
            <div className="flex w-full items-center justify-center gap-2 rounded-lg border border-gray p-2">
              {image && <Image src={image} alt={`${name} profile photo`} />}
              {name}
            </div>
          </div>
        )}
        <Button variant="secondary" className="w-full" asChild>
          <NavLink to={routes.dataUpdater}>
            <DatabaseIcon size={24} />
            <span>Data Updater</span>
          </NavLink>
        </Button>
        <Button variant="secondary" className="w-full" asChild>
          <NavLink to={routes.protocolUpdater}>
            <DatabaseIcon size={24} />
            <span>Protocol Updater</span>
          </NavLink>
        </Button>
        <Button variant="secondary" className="w-full" asChild>
          <NavLink to={routes.aboutChange}>
            <DocumentIcon size={24} />
            <span>About Changer</span>
          </NavLink>
        </Button>
        <Button variant="secondary" className="w-full" asChild>
          <NavLink to={generatePath(routes.wallbreakerCharts, { apyMode: '1day' })}>
            <ListIcon size={24} />
            <span>Charts 1 day</span>
          </NavLink>
        </Button>
        <Button variant="secondary" className="w-full" asChild>
          <NavLink to={generatePath(routes.wallbreakerCharts, { apyMode: '7day' })}>
            <ListIcon size={24} />
            <span>Charts 7 day</span>
          </NavLink>
        </Button>
        <Button variant="secondary" className="w-full" asChild>
          <NavLink to={generatePath(routes.wallbreakerCharts, { apyMode: '30day' })}>
            <ListIcon size={24} />
            <span>Charts 30 day</span>
          </NavLink>
        </Button>
        {authorized && (
          <Button variant="secondary" className="mt-2 w-full border-red bg-red/15 text-red [&>span]:w-full" asChild>
            <a href={`${BACKEND_URL}/oauth2/logout`}>
              <span>Logout</span>
              <LogoutIcon size={20} />
            </a>
          </Button>
        )}
      </Panel>
    </Page>
  )
}

export const Component = () => WallbreakerPage()
