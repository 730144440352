import type { ComponentPropsWithoutRef } from 'react'

import { Button } from '../Button'
import { ZoomOutIcon } from '../Icons'

import { cn } from '@/utils/cn'

interface Props {
  isVisible: boolean
}

export const ZoomOutButton = ({ isVisible, ...props }: Props & ComponentPropsWithoutRef<'button'>) => {
  return (
    <Button
      variant="secondary"
      className={cn('absolute top-0 right-0 min-w-10 p-0', !isVisible && 'pointer-events-none select-none opacity-0')}
      {...props}
    >
      <ZoomOutIcon size={24} />
    </Button>
  )
}
