import { SEND_EVENT_TIMEOUT } from '@/constants'
import { useDebounce, useVaultsSearchParams } from '@/hooks'
import { trackVaultsListEvents } from '@/pages/VaultsListPage/hooks'

export const useTrackFiltersTrayEvent = () => {
  const { searchFor, selectedNetworks, selectedTokens } = useVaultsSearchParams()
  useDebounce(
    () => {
      const selectedNetworksReport = selectedNetworks.map((network) => network)
      const selectedTokensReport = selectedTokens.map((token) => token)
      const reportPhrase = `User searched for '${searchFor}', networks: ${
        selectedNetworksReport.length > 0 ? selectedNetworksReport : 'all'
      }, tokens: ${selectedTokensReport.length > 0 ? selectedTokensReport : 'all'}`

      trackVaultsListEvents({
        action: 'Table filter changed',
        label: reportPhrase,
      })
    },
    [searchFor, selectedTokens, selectedNetworks],
    SEND_EVENT_TIMEOUT
  )
}
