import { cn } from '@/utils/cn'
import type { HTMLAttributes } from 'react'

export const LabeledValueHeader = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('text-base text-textSecondary leading-none', className)}
      data-role="labeled-value-header"
      {...props}
    />
  )
}

export const LabeledValueContent = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn('mt-1', className)} data-role="labeled-value-content" {...props} />
}

export const LabeledValueValue = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('font-bold text-2xl text-textPrimary leading-none', className)}
      data-role="labeled-value-value"
      {...props}
    />
  )
}
