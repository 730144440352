import { queryClient } from '@/config/queryClient'
import { getBackendFilters } from '@/config/subdomains'
import { trpcQueryClient } from '@/config/trpc'
import { APY_MODE_KEY, EXCLUDE_REWARDS_KEY } from '@/constants/stateConstants'
import { DEFAULT_APY_MODE } from '@/pages/YieldOptimizerPage/defaultValues'
import type { BackendVault } from '@/types'
import { isApyMode } from '@/types'
import { transformVault } from '@/utils'
import { getSubdomain } from '@/utils/subdomain/getSubdomain'

export async function getAllVaults(url: string): Promise<BackendVault[]> {
  const { searchParams, host } = new URL(url)
  const apyModeRaw = searchParams.get(APY_MODE_KEY) ?? ''
  const apyMode = isApyMode(apyModeRaw) ? apyModeRaw : DEFAULT_APY_MODE

  const excludeRewards = searchParams.get(EXCLUDE_REWARDS_KEY) === 'true'

  const subdomain = getSubdomain(host)
  const vaults = await queryClient.ensureQueryData({
    queryKey: ['getAllVaults', { apyMode }],
    queryFn: () =>
      trpcQueryClient.getAllVaults.query({
        interval: apyMode,
        filters: getBackendFilters(subdomain?.name),
      }),
  })

  const transformedVaults = vaults.map(transformVault)

  if (excludeRewards) {
    return transformedVaults.map((vault) => ({ ...vault, apy: vault.rawApy, rewardsApy: undefined }))
  }

  return transformedVaults
}
