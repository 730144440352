import { cn } from '@/utils/cn'
import type { ImgHTMLAttributes } from 'react'

export const Image = ({ className, alt, ...props }: ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <span
      className={cn('flex size-6 items-center justify-center overflow-hidden rounded-full', className)}
      data-role="image-wrapper"
    >
      {/* biome-ignore lint/a11y/useAltText: <explanation> */}
      <img className="h-full max-w-full object-contain" alt={alt} {...props} />
    </span>
  )
}
