import { TableCell, type TableCellProps } from '@/components/atoms/Table'
import { BookmarkButton } from '@/components/molecules/BookmarkButton'
import { useBookmarks } from '@/providers/BookmarksProvider'

interface BookmarkCellProps {
  address: string
  network: string
}

export const BookmarkCell = ({ address, network, ...props }: BookmarkCellProps & TableCellProps) => {
  const { bookmarks, addBookmark, removeBookmark } = useBookmarks()
  const isBookmarked = bookmarks.some((bookmark) => bookmark.address === address && bookmark.network === network)
  const handleBookmark = () => {
    if (isBookmarked) {
      removeBookmark({ address, network })
    }
    if (!isBookmarked) {
      addBookmark({ address, network })
    }
  }

  return (
    <TableCell data-cell="Bookmark" {...props}>
      <BookmarkButton onClick={handleBookmark} isActive={isBookmarked} />
    </TableCell>
  )
}
