import { OptimizerVaults } from '../OptimizerVaults'

import type { InvestmentData } from '@/components/organisms/VaultItem'
import type { NetworksGasPrices } from '@/hooks'
import { useVaultsForOptimizer } from '@/hooks'
import type { ApyModes, BackendVault, ChainTokenPrices } from '@/types'

export interface InvestmentOptimizerProps {
  assetSymbols: string[]
  vaults: BackendVault[]
  gasPrices: NetworksGasPrices
  networkTokenPrices: ChainTokenPrices | undefined
  excludeRewards: boolean
  apyMode: ApyModes
  selectedTags: string[]
  selectedNetworks: string[]
  selectedProtocols: string[]
  selectedTvlRange: [number, number]
}

export const InvestmentOptimizer = ({
  assetSymbols,
  amount,
  period,
  vaults,
  gasPrices,
  networkTokenPrices,
  excludeRewards,
  apyMode,
  selectedTags,
  selectedNetworks,
  selectedProtocols,
  selectedTvlRange,
}: InvestmentOptimizerProps & InvestmentData) => {
  const evaluatedVaults = useVaultsForOptimizer(
    vaults,
    assetSymbols,
    amount,
    period,
    gasPrices,
    networkTokenPrices,
    excludeRewards,
    selectedTags,
    selectedNetworks,
    selectedProtocols,
    selectedTvlRange
  )

  return <OptimizerVaults vaults={evaluatedVaults} amount={amount} period={period} apyMode={apyMode} />
}
