import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const SortIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M167.5-250q-15.5 0-26.5-11t-11-26.5q0-15.5 11-26.5t26.5-11H324q15.5 0 26.5 11t11 26.5q0 15.5-11 26.5T324-250H167.5Zm0-187.5q-15.5 0-26.5-11T130-475q0-15.5 11-26.5t26.5-11h391q15.5 0 26.5 11t11 26.5q0 15.5-11 26.5t-26.5 11h-391Zm0-187.5q-15.5 0-26.5-11t-11-26.5q0-15.5 11-26.5t26.5-11h625q15.5 0 26.5 11t11 26.5q0 15.5-11 26.5t-26.5 11h-625Z"
        fill="currentColor"
      />
    </svg>
  )
}
