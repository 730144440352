import mixpanel from 'mixpanel-browser'
import ReactGA from 'react-ga4'

import { getSubdomain } from '@/utils/subdomain/getSubdomain'

interface Props {
  action: string
  label: string
}

const EVENT_NAME = 'Vaults list page action'

export const trackVaultsListEvents = ({ action, label }: Props) => {
  const subdomain = getSubdomain()?.prefix ?? 'N/A'

  ReactGA.event(
    {
      category: EVENT_NAME,
      action,
      label,
    },
    {
      subdomain,
    }
  )
  mixpanel.track(EVENT_NAME, {
    Action: action,
    Details: label,
    Subdomain: subdomain,
  })
}
