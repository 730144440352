import { Label } from '@/components/atoms/Label'
import { Switch } from '@/components/atoms/Switch'
import { EXCLUDE_REWARDS_KEY } from '@/constants/stateConstants'
import { useSearchState } from '@/hooks/useSearchState'

interface RewardsSwitchProps {
  onChangeCallback?: (checked: boolean) => void
}

export function RewardsSwitch({ onChangeCallback }: RewardsSwitchProps) {
  const { searchState, updateSearchState } = useSearchState()

  const excludeRewards = searchState.get(EXCLUDE_REWARDS_KEY) === 'true'

  const checked = !excludeRewards

  const handleRewardsToggle = (checked: boolean) => {
    updateSearchState([EXCLUDE_REWARDS_KEY, !checked])

    onChangeCallback?.(checked)
  }

  return (
    <Label>
      <Switch aria-label="Include rewards" checked={checked} onCheckedChange={handleRewardsToggle} />
      Include rewards
    </Label>
  )
}
