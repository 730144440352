import { format, fromUnixTime } from 'date-fns'

import { UNIX_NOW } from '@/constants'

export function getChartDateLabel(timestamp: number): string {
  const date = fromUnixTime(timestamp)
  const now = fromUnixTime(UNIX_NOW)
  const isCurrentYear = date.getFullYear() === now.getFullYear()
  const dateFormat = isCurrentYear ? 'MMM dd' : 'MMM dd, yyyy'

  return format(date, dateFormat)
}
