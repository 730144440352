import { type ReactNode, useEffect } from 'react'

import { getSubdomain } from '@/utils/subdomain'

interface StyleProviderProps {
  children: ReactNode
}

export const StyleProvider = ({ children }: StyleProviderProps) => {
  useEffect(() => {
    document.body.setAttribute('data-subdomain', getSubdomain()?.name ?? '')
  })

  return <>{children}</>
}
