import { useMemo } from 'react'

import { SelectFilterByGroup, type SelectFilterProps, SelectNetworkFilter } from '@/components/molecules'
import { SelectTvlRange } from '@/components/molecules/filters/SelectTvlRange'
import type { BackendVault } from '@/types'
import type { Subdomain } from '@/types/Subdomain'
import { SubdomainType } from '@/types/Subdomain'
import { getTokenImage, groupTokens } from '@/utils'

interface TokenFilterProps {
  tokens: SelectFilterProps['items']
  selectedTokens: SelectFilterProps['selectedItems']
  setSelectedTokens: SelectFilterProps['setSelectedItems']
}

interface FloatingFiltersProps extends TokenFilterProps {
  vaults: Array<BackendVault>
  subdomain: Subdomain | undefined
}

export const FloatingFilters = ({
  subdomain,
  vaults,
  tokens,
  selectedTokens,
  setSelectedTokens,
}: FloatingFiltersProps) => {
  const showNetworkSelect = !(subdomain?.type === SubdomainType.Network)
  const groupedAssets = useMemo(() => groupTokens(tokens), [tokens])

  return (
    <>
      {showNetworkSelect && <SelectNetworkFilter vaults={vaults} />}
      <SelectFilterByGroup
        groupedItems={groupedAssets}
        selectedItems={selectedTokens}
        setSelectedItems={setSelectedTokens}
        name="token"
        dropdownPrefix="Filter by"
        imageGetter={getTokenImage}
        allowMultipleGroupsSelection
      />
      <SelectTvlRange />
    </>
  )
}
