import type { Network } from '@vaultsfyi/common'

type NetworkWithExplorer = {
  [key in Network]: string
}

export const networks: NetworkWithExplorer = {
  base: 'https://basescan.org',
  mainnet: 'https://etherscan.io',
  arbitrum: 'https://arbiscan.io',
  optimism: 'https://optimistic.etherscan.io',
  polygon: 'https://polygonscan.com',
  gnosis: 'https://gnosisscan.io',
}
