import { cn } from '@/utils/cn'
import type { HTMLAttributes } from 'react'

export const Logo = ({ className }: HTMLAttributes<SVGElement>) => {
  return (
    <svg
      className={cn('block [&>path]:[transform-box:fill-box] [&>path]:[transform-origin:50%_50%]', className)}
      width="800"
      height="800"
      viewBox="0 0 800 800"
      fill="none"
      color="currentColor"
    >
      <title>vaults.fyi logo</title>
      <path d="M445.671 770.454L409.489 665.88H626.584L445.671 770.454Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M400 600L746 200L746 371.913L655.066 477.039H746L746 600H400Z"
        fill="currentColor"
      />
      <path d="M55.3228 259.131L163.87 280.046L55.3228 468.278L55.3228 259.131Z" fill="currentColor" />
      <path
        d="M167.149 665.404L54 600L227 300L400 800L251.526 714.177L209.337 592.244L167.149 665.404Z"
        fill="currentColor"
      />
      <path d="M695.101 171.841L622.736 255.5L514.188 67.2673L695.101 171.841Z" fill="currentColor" />
      <path d="M573 300L54 200L188.152 122.455L317.806 147.437L274.588 72.4925L400 0L573 300Z" fill="currentColor" />
      <path d="M371.834 540.393L290.623 304.903L532.953 351.953L371.834 540.393Z" fill="currentColor" />
    </svg>
  )
}
