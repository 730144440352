import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const SaveIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M204-129q-30.938 0-52.969-22.031Q129-173.062 129-204v-552q0-30.938 22.031-52.969Q173.062-831 204-831h440q15.143 0 28.866 5.739Q686.589-819.522 697-809l112 112q10.522 10.411 16.261 24.134Q831-659.143 831-644v440q0 30.938-22.031 52.969Q786.938-129 756-129H204Zm552-515L644-756H204v552h552v-440ZM480-247q47.5 0 80.75-33.25T594-361q0-47.5-33.25-80.75T480-475q-47.5 0-80.75 33.25T366-361q0 47.5 33.25 80.75T480-247ZM282.5-561h276q15.5 0 26.5-11t11-26.5v-79q0-15.5-11-26.5t-26.5-11h-276q-15.5 0-26.5 11t-11 26.5v79q0 15.5 11 26.5t26.5 11ZM204-644v440-552 112Z"
        fill="currentColor"
      />
    </svg>
  )
}
