import type { ReactNode } from 'react'

import bankEmoji from '/images/emojis/bankEmoji.png'
import coinEmoji from '/images/emojis/coinEmoji.png'

interface LoaderProps {
  children?: ReactNode
}

export function Loader({ children }: LoaderProps) {
  return (
    <div className="flex flex-col items-center gap-4 py-16 text-center">
      <div className="relative isolate mb-4 leading-none">
        <img
          className="repeat-infinite relative z-2 size-[84px] origin-[50%_100%] animate-loader-bank rounded-none"
          src={bankEmoji}
          alt="Bank emoji"
        />
        <img
          className="repeat-infinite absolute top-2 left-2 size-8 animate-loader-coin"
          src={coinEmoji}
          alt="Coin emoji"
        />
        <img
          className="repeat-infinite absolute top-8 left-2 size-8 animate-loader-coin [animation-delay:460ms]"
          src={coinEmoji}
          alt="Coin emoji"
        />
        <img
          className="repeat-infinite absolute bottom-2 left-2 size-8 animate-loader-coin [animation-delay:920ms]"
          src={coinEmoji}
          alt="Coin emoji"
        />
      </div>
      {children ?? (
        <>
          <h1 className="text-3xl leading-none sm:text-4xl">Loading...</h1>
          <p>We are looking for yield source!</p>
        </>
      )}
    </div>
  )
}
