import * as Sentry from '@sentry/react'
import { Analytics } from '@vercel/analytics/react'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { RouterProvider, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

import { IS_PRODUCTION } from './constants'
import { FallbackPage } from './pages'
import { router } from './router'

import { Providers } from '@/providers'

Sentry.init({
  dsn: 'https://53552eadb2abfcd4f90e2e5dc11cb846@o4505872996827136.ingest.sentry.io/4505873028874240',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: ['vaults.fyi'],
  // Performance Monitoring
  tracesSampleRate: 0.5,
  // Session Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.7,
  enabled: IS_PRODUCTION,
})

function App() {
  if (IS_PRODUCTION) {
    ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_TRACK_ID)
    mixpanel.init(import.meta.env.VITE_MIXPANEL_PRODUCTION_TOKEN ?? '', { track_pageview: true })
  } else {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_DEVELOPMENT_TOKEN ?? '', { debug: true, track_pageview: true })
  }

  return (
    <>
      <Providers>
        <RouterProvider router={router} fallbackElement={<FallbackPage />} future={{ v7_startTransition: true }} />
      </Providers>
      <Analytics />
    </>
  )
}

export default App
