import { Label } from '@/components/atoms/Label'
import { Switch } from '@/components/atoms/Switch'
import { PROTOCOL_CURATED_ONLY_KEY } from '@/constants/stateConstants'
import { useSearchState } from '@/hooks/useSearchState'

interface CuratedSwitchProps {
  onChangeCallback?: (checked: boolean) => void
}

export function CuratedSwitch({ onChangeCallback }: CuratedSwitchProps) {
  const { searchState, updateSearchState } = useSearchState()

  const protocolCuratedOnly = searchState.get(PROTOCOL_CURATED_ONLY_KEY) === 'true'

  const handleRewardsToggle = (checked: boolean) => {
    updateSearchState([PROTOCOL_CURATED_ONLY_KEY, checked])

    onChangeCallback?.(checked)
  }

  return (
    <Label>
      <Switch
        aria-label="Show Yearn curated vaults only"
        checked={protocolCuratedOnly}
        onCheckedChange={handleRewardsToggle}
      />
      Yearn curated vaults only
    </Label>
  )
}
