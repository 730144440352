import { useMemo } from 'react'

import type { BackendVault, VaultForList } from '@/types'

export const useVaultsForList = <T extends BackendVault>(vaults: T[]): VaultForList[] => {
  const listVaults = useMemo(() => {
    return vaults.map(transformVault)
  }, [vaults])

  return listVaults
}

function transformVault(vault: BackendVault): VaultForList {
  return { ...vault, tvlInUsd: vault.tvlInUsd ?? 0 }
}
