import type { CompareTransformFunction } from './comparators'
import { compareObjectsByProperties } from './comparators'
import type { ObjectEndpoints } from './getPrimitiveValues'

export function sortObjectsByKey<T extends object, Keys extends ObjectEndpoints<T>>(
  objects: T[],
  keys: Keys,
  ascendingOrder: boolean,
  transform?: CompareTransformFunction<T, Keys>
) {
  return [...objects].sort((a, b) => compareObjectsByProperties(a, b, keys, ascendingOrder, transform))
}
