import { cn } from '@/utils/cn'
import type { HTMLAttributes } from 'react'
import { LoadingIcon } from './Icons'

export function LoadingSpinner({ className }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn('flex h-full w-full animate-spin items-center justify-center', className)} data-role="loader">
      <LoadingIcon />
    </div>
  )
}
