import { cn } from '@/utils/cn'
import { type IconProps, iconVariants } from './IconBase'

export const BlockIcon = ({ size = 16, className, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <path
        d="M442.5-183.5 243.5-299Q226-309.5 216-326.414T206-364v-229q0-20.672 10-37.586Q226-647.5 243.5-658l199-115.5q17.754-10.5 37.377-10.5t37.623 10.5l199 115.5q17.5 10.5 27.5 27.414T754-593v229q0 20.672-10 37.586Q734-309.5 716.5-299l-199 115.5Q499.746-173 480.123-173T442.5-183.5Zm.5-88v-186L281-550v185.5l162 93Zm75 0 161-93V-550l-161 92.5v186Zm-390.5-367q-15.5 0-26.5-11T90-676v-119q0-30.938 22.031-52.969Q134.062-870 165-870h119q15.5 0 26.5 11t11 26.5q0 15.5-11 26.5T284-795H165v119q0 15.5-11 26.5t-26.5 11ZM165-90q-30.938 0-52.969-22.031Q90-134.062 90-165v-119q0-15.5 11-26.5t26.5-11q15.5 0 26.5 11t11 26.5v119h119q15.5 0 26.5 11t11 26.5q0 15.5-11 26.5T284-90H165Zm630 0H676q-15.5 0-26.5-11t-11-26.5q0-15.5 11-26.5t26.5-11h119v-118q0-15.5 11-26.5t26.5-11q15.5 0 26.5 11t11 26.5v118q0 30.938-22.031 52.969Q825.938-90 795-90Zm0-586v-119H676q-15.5 0-26.5-11t-11-26.5q0-15.5 11-26.5t26.5-11h119q30.938 0 52.969 22.031Q870-825.938 870-795v119q0 15.5-11 26.5t-26.5 11q-15.5 0-26.5-11T795-676ZM480-523.5l160-92L480-708l-160 92.5 160 92Zm0 38.5Zm0-38.5Zm38 66Zm-75 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
