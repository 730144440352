import { LabeledValueContent, LabeledValueHeader } from '@/components/molecules/LabeledValue'
import { VaultTag } from '@/components/molecules/VaultTag'
import type { BackendVaultDetailed } from '@/types'

interface Props {
  tags: BackendVaultDetailed['tags']
}

export const TagsStatistic = ({ tags }: Props) => {
  return (
    <div>
      <LabeledValueHeader>Vault tags</LabeledValueHeader>
      <LabeledValueContent>
        <div className="flex flex-wrap items-center gap-2">
          {tags ? tags.map((tag) => <VaultTag tag={tag} key={tag.id} />) : 'N/A'}
        </div>
      </LabeledValueContent>
    </div>
  )
}
