import { useMemo, useState } from 'react'

import { FiltersResult } from './FiltersResult'

import { LoadMoreButton } from '@/components/molecules/LoadMoreButton'
import { PROTOCOL_CURATED_ONLY_KEY } from '@/constants/stateConstants'
import { useSortedVaults, useVaultsForList, useVaultsSortState } from '@/hooks'
import type { ApyModes, BackendVault } from '@/types'
import type { Subdomain } from '@/types/Subdomain'
import {
  fuzzySearchVaults,
  isTagsInVault,
  isVaultAssetInTokens,
  isVaultCuratedByProtocol,
  isVaultInNetworks,
  isVaultInProtocols,
  trackEvent,
} from '@/utils'
import { isVaultInTvlRange } from '@/utils/vaultUtils/vaultsFilters/getFilteredVaultsByTvl'

interface VaultsListTableProps {
  searchFor: string
  searchState: URLSearchParams
  selectedTags: string[]
  selectedNetworks: string[]
  selectedProtocols: string[]
  selectedTokens: string[]
  selectedTvlRange: [number, number]
  vaults: Array<BackendVault>
  subdomain: Subdomain | undefined
  apyMode: ApyModes
}

const INITIAL_TABLE_SIZE = 25

export function VaultsListTable(props: VaultsListTableProps) {
  const { sortState } = useVaultsSortState()

  const filteredVaults = useFilteredVaults(props)
  const vaultsForList = useVaultsForList(filteredVaults)
  const sortedVaults = useSortedVaults(vaultsForList, sortState)
  const [tableSize, setTableSize] = useState(INITIAL_TABLE_SIZE)

  const loadMoreHandler = () => {
    setTableSize((prev) => prev + INITIAL_TABLE_SIZE)
    trackEvent({ event: 'Load more table vaults' })
  }

  const moreVaultsToLoad = tableSize < filteredVaults.length

  return (
    <>
      <FiltersResult vaults={sortedVaults.slice(0, tableSize)} subdomain={props.subdomain} apyMode={props.apyMode} />
      {moreVaultsToLoad && <LoadMoreButton onClick={loadMoreHandler} />}
    </>
  )
}

function useFilteredVaults(props: VaultsListTableProps) {
  const {
    searchState,
    searchFor,
    selectedTags,
    selectedNetworks,
    selectedProtocols,
    selectedTokens,
    selectedTvlRange,
    vaults,
    subdomain,
  } = props

  const protocolCuratedOnly = searchState.get(PROTOCOL_CURATED_ONLY_KEY) === 'true'

  return useMemo(() => {
    const vaultsByCuration =
      protocolCuratedOnly && subdomain ? vaults.filter((vault) => isVaultCuratedByProtocol(vault, subdomain)) : vaults

    const filteredVaults = vaultsByCuration.filter(
      (vault) =>
        isVaultInNetworks(vault, selectedNetworks) &&
        isVaultInProtocols(vault, selectedProtocols) &&
        isVaultInTvlRange(vault, selectedTvlRange) &&
        isVaultAssetInTokens(vault, selectedTokens) &&
        isTagsInVault(vault, selectedTags)
    )

    if (!searchFor) return filteredVaults
    return fuzzySearchVaults(filteredVaults, searchFor)
  }, [vaults, searchFor, selectedTags, selectedNetworks, selectedTokens, subdomain, protocolCuratedOnly])
}
