import type { ChartData } from '@vaultsfyi/common'

export const MULTI_APY_CHART_DATA_MOCK: ChartData<'date'>[] = [
  {
    date: 1717497795,
    '0x0-ethereum-apy': 258,
    '0x1-ethereum-apy': 40,
    '0x2-ethereum-apy': 465,
  },
  {
    date: 1717584195,
    '0x0-ethereum-apy': 405,
    '0x1-ethereum-apy': 57,
    '0x2-ethereum-apy': 503,
  },
  {
    date: 1717670595,
    '0x0-ethereum-apy': 421,
    '0x1-ethereum-apy': 44,
    '0x2-ethereum-apy': 631,
  },
  {
    date: 1717756995,
    '0x0-ethereum-apy': 546,
    '0x1-ethereum-apy': 134,
    '0x2-ethereum-apy': 677,
  },
  {
    date: 1717843395,
    '0x0-ethereum-apy': 626,
    '0x1-ethereum-apy': 257,
    '0x2-ethereum-apy': 733,
  },
  {
    date: 1717929795,
    '0x0-ethereum-apy': 670,
    '0x1-ethereum-apy': 321,
    '0x2-ethereum-apy': 885,
  },
  {
    date: 1718016195,
    '0x0-ethereum-apy': 803,
    '0x1-ethereum-apy': 403,
    '0x2-ethereum-apy': 863,
  },
  {
    date: 1718102595,
    '0x0-ethereum-apy': 812,
    '0x1-ethereum-apy': 516,
    '0x2-ethereum-apy': 932,
  },
  {
    date: 1718188995,
    '0x0-ethereum-apy': 822,
    '0x1-ethereum-apy': 612,
    '0x2-ethereum-apy': 1075,
  },
  {
    date: 1718275395,
    '0x0-ethereum-apy': 943,
    '0x1-ethereum-apy': 655,
    '0x2-ethereum-apy': 1181,
  },
  {
    date: 1718361795,
    '0x0-ethereum-apy': 1082,
    '0x1-ethereum-apy': 616,
    '0x2-ethereum-apy': 1226,
  },
  {
    date: 1718448195,
    '0x0-ethereum-apy': 1131,
    '0x1-ethereum-apy': 735,
    '0x2-ethereum-apy': 1194,
  },
  {
    date: 1718534595,
    '0x0-ethereum-apy': 1138,
    '0x1-ethereum-apy': 697,
    '0x2-ethereum-apy': 1315,
  },
  {
    date: 1718620995,
    '0x0-ethereum-apy': 1171,
    '0x1-ethereum-apy': 672,
    '0x2-ethereum-apy': 1316,
  },
  {
    date: 1718707395,
    '0x0-ethereum-apy': 1176,
    '0x1-ethereum-apy': 645,
    '0x2-ethereum-apy': 1397,
  },
  {
    date: 1718793795,
    '0x0-ethereum-apy': 1301,
    '0x1-ethereum-apy': 620,
    '0x2-ethereum-apy': 1469,
  },
  {
    date: 1718880195,
    '0x0-ethereum-apy': 1269,
    '0x1-ethereum-apy': 737,
    '0x2-ethereum-apy': 1513,
  },
  {
    date: 1718966595,
    '0x0-ethereum-apy': 1256,
    '0x1-ethereum-apy': 757,
    '0x2-ethereum-apy': 1626,
  },
  {
    date: 1719052995,
    '0x0-ethereum-apy': 1250,
    '0x1-ethereum-apy': 861,
    '0x2-ethereum-apy': 1746,
  },
  {
    date: 1719139395,
    '0x0-ethereum-apy': 1200,
    '0x1-ethereum-apy': 849,
    '0x2-ethereum-apy': 1691,
  },
  {
    date: 1719225795,
    '0x0-ethereum-apy': 1243,
    '0x1-ethereum-apy': 951,
    '0x2-ethereum-apy': 1711,
  },
  {
    date: 1719312195,
    '0x0-ethereum-apy': 1191,
    '0x1-ethereum-apy': 1041,
    '0x2-ethereum-apy': 1746,
  },
  {
    date: 1719398595,
    '0x0-ethereum-apy': 1176,
    '0x1-ethereum-apy': 1151,
    '0x2-ethereum-apy': 1667,
  },
  {
    date: 1719484995,
    '0x0-ethereum-apy': 1146,
    '0x1-ethereum-apy': 1200,
    '0x2-ethereum-apy': 1604,
  },
  {
    date: 1719571395,
    '0x0-ethereum-apy': 1115,
    '0x1-ethereum-apy': 1258,
    '0x2-ethereum-apy': 1525,
  },
  {
    date: 1719657795,
    '0x0-ethereum-apy': 1121,
    '0x1-ethereum-apy': 1283,
    '0x2-ethereum-apy': 1534,
  },
  {
    date: 1719744195,
    '0x0-ethereum-apy': 1163,
    '0x1-ethereum-apy': 1379,
    '0x2-ethereum-apy': 1465,
  },
  {
    date: 1719830595,
    '0x0-ethereum-apy': 1139,
    '0x1-ethereum-apy': 1390,
    '0x2-ethereum-apy': 1391,
  },
  {
    date: 1719916995,
    '0x0-ethereum-apy': 1145,
    '0x1-ethereum-apy': 1393,
    '0x2-ethereum-apy': 1365,
  },
  {
    date: 1720003395,
    '0x0-ethereum-apy': 1231,
    '0x1-ethereum-apy': 1361,
    '0x2-ethereum-apy': 1414,
  },
  {
    date: 1720089795,
    '0x0-ethereum-apy': 1287,
    '0x1-ethereum-apy': 1297,
    '0x2-ethereum-apy': 1377,
  },
]
