import type { Network } from '@vaultsfyi/common'

import { getGasPrice } from './getGasPrice'

export type NetworksGasPrices = {
  [key in Network]?: number
}

export const getGasPricesPerNetwork = async (networkNames: readonly Network[]): Promise<NetworksGasPrices> => {
  const gasPrices: NetworksGasPrices = {}
  await Promise.all(
    networkNames.map(async (networkName) => {
      gasPrices[networkName] = await getGasPrice(networkName)
    })
  )
  return gasPrices
}
