import { PanelContentWrapper, PanelHeader, PanelTitle } from '@/components/atoms/Panel'
import { TvlChart } from '@/components/molecules'
import type { TvlChartData } from '@/types'
import type { ChartPeriod } from '@/utils'
import { getChartDataByPeriod } from '@/utils'
import type { Asset } from '@vaultsfyi/common'

interface Props {
  chartData: TvlChartData[]
  title: string
  period: ChartPeriod
  tvlInNativeAsset?: boolean
  asset?: Asset
}

export const TvlHistoryChart = ({ chartData, title, period, tvlInNativeAsset, asset }: Props) => {
  const firstNonZeroIndex = chartData.findIndex((dataPoint) => dataPoint.tvl !== 0)
  const chartDataByPeriod = getChartDataByPeriod(chartData, period, firstNonZeroIndex)

  return (
    <PanelContentWrapper>
      <PanelHeader>
        <PanelTitle>{title}</PanelTitle>
      </PanelHeader>
      <TvlChart chartData={chartDataByPeriod} tvlInNativeAsset={tvlInNativeAsset} asset={asset} zoomable />
    </PanelContentWrapper>
  )
}
