import type { GroupedItems } from '@/components/molecules'
import { isEthCoin } from './isEthCoin'
import { isEuroCoin } from './isEuroCoin'
import { isStableCoin } from './isStableCoin'
import { sortTokens } from './sortTokens'

const USD_PRIORITY = ['USDC', 'DAI', 'USDT']
const ETH_PRIORITY = ['ETH', 'WETH']

export function groupTokens(tokenSymbols: string[]): GroupedItems {
  const emptyGroupedTokens: GroupedItems = {
    'USD coins': { items: [], isSelectable: true },
    'EUR coins': { items: [], isSelectable: true },
    ETH: { items: [], isSelectable: true },
    Other: { items: [], isSelectable: false },
  }

  const groupedTokens = tokenSymbols.reduce((grouped, tokenSymbol) => {
    const objKey = getTokenGroupName(tokenSymbol)
    grouped[objKey].items.push(tokenSymbol)
    return grouped
  }, emptyGroupedTokens)

  groupedTokens['USD coins'].items.sort((a, b) => sortTokens(a, b, USD_PRIORITY))
  groupedTokens['ETH'].items.sort((a, b) => sortTokens(a, b, ETH_PRIORITY))
  groupedTokens['EUR coins'].items.sort()
  groupedTokens['Other'].items.sort()

  return groupedTokens
}

function getTokenGroupName(tokenSymbol: string) {
  if (isStableCoin(tokenSymbol)) {
    return 'USD coins'
  }
  if (isEuroCoin(tokenSymbol)) {
    return 'EUR coins'
  }
  if (isEthCoin(tokenSymbol)) {
    return 'ETH'
  }
  return 'Other'
}
