import { TableCell, type TableCellProps } from '@/components/atoms/Table'
import { TableRowButton } from '@/components/atoms/TableRowButton'

interface DetailsButtonCellProps {
  isRowOpen: boolean
  handleRowOpen: () => void
}

export const DetailsButtonCell = ({
  isRowOpen,
  handleRowOpen,
  className,
  ...props
}: DetailsButtonCellProps & TableCellProps) => {
  return (
    <TableCell className={className} data-cell="Details" data-row-action {...props}>
      <TableRowButton onClick={handleRowOpen} $isActive={isRowOpen} />
    </TableCell>
  )
}
