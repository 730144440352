import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'

import { Markdown } from '@/components/atoms/Markdown'
import { Panel } from '@/components/atoms/Panel'
import { Page } from '@/components/molecules'
import { trpc } from '@/config/trpc'
import { usePageTracking } from '@/hooks'

function AboutPage() {
  usePageTracking()

  const [markdown] = trpc.getAbout.useSuspenseQuery()

  return (
    <Page title="About">
      <Panel>
        <Markdown remarkPlugins={[remarkGfm, remarkBreaks]}>{markdown}</Markdown>
      </Panel>
    </Page>
  )
}

export const Component = () => AboutPage()
