import { format, fromUnixTime } from 'date-fns'

import { ChartTooltip } from '@/components/atoms/Charts/ChartTooltip'
import { EqualIcon } from '@/components/atoms/Icons'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import type { ApyModes } from '@/types'
import {
  formatNumber,
  getApyChartDescriptorStackId,
  getChartBaseApyItemDescriptor,
  getChartRewardsApyItemDescriptor,
} from '@/utils'
import { Fragment } from 'react'

export const ApyChartTooltip = (props: Record<string, any>) => {
  const { label, payload, apyMode } = props

  if (!payload) {
    return <></>
  }

  const dateLabel = label && `${format(fromUnixTime(Number(label)), 'MMM dd, yyyy, HH:mm, O')}`

  const apyLabelsByStackId = groupApyLabelsByStackId(payload)

  return (
    <ChartTooltip>
      <LabeledValueHeader className="!text-xs">{dateLabel}</LabeledValueHeader>
      <LabeledValueContent>
        <LabeledValueValue className="!text-base">
          {apyLabelsByStackId.map((itemList: Record<string, any>[], index: number) => {
            const descriptors = itemList.map((item: Record<string, any>, innerIndex: number) => {
              const key = item.dataKey
              const name = getApyName(key, apyMode)
              return (
                <p key={innerIndex}>
                  {name}: {formatNumber(item.payload[key])}%
                </p>
              )
            })
            const totalStackValue = itemList.reduce((acc, item: Record<string, any>) => {
              return acc + item.payload[item.dataKey]
            }, 0)
            return (
              <Fragment key={index}>
                {descriptors}
                {itemList.length > 1 && (
                  <div className="flex items-center">
                    <EqualIcon size={12} />
                    <p>{formatNumber(totalStackValue)}%</p>
                  </div>
                )}
              </Fragment>
            )
          })}
        </LabeledValueValue>
      </LabeledValueContent>
    </ChartTooltip>
  )
}

function groupApyLabelsByStackId(payload: Record<string, any>[]) {
  const apyLabelsByStackId: Record<number, any> = {}

  payload.forEach((item: Record<string, any>) => {
    const stackId = getApyChartDescriptorStackId(item.dataKey)
    if (!apyLabelsByStackId[stackId]) {
      apyLabelsByStackId[stackId] = []
    }
    apyLabelsByStackId[stackId].push(item)
  })

  return Object.values(apyLabelsByStackId)
}

function getApyName(key: string, apyMode: ApyModes) {
  switch (key) {
    case 'rangeApy':
      return getChartBaseApyItemDescriptor(apyMode)
    case 'rawApy':
      return 'Raw'
    case 'rewardsApy':
      return getChartRewardsApyItemDescriptor(apyMode)
  }
}
