import { cn } from '@/utils/cn'
import { type VariantProps, cva } from 'class-variance-authority'
import { type IconProps, iconVariants } from './IconBase'

const directionVariants = cva(cn('origin-center [transform-box:fill-box]'), {
  variants: {
    direction: {
      left: 'rotate-180',
      down: 'rotate-90',
      up: '-rotate-90',
      right: 'rotate-0',
    },
  },
  defaultVariants: {
    direction: 'right',
  },
})

export const ArrowIcon = ({
  direction = 'up',
  size = 12,
  className,
  ...props
}: IconProps & VariantProps<typeof directionVariants>) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <g className={cn(directionVariants({ direction }))}>
        <path
          d="M581.5-480 290-771.5q-14-14-13.75-33.25T290.5-838q14-14 33.25-14T357-838l304.5 305q11.5 11.5 16.75 25t5.25 28q0 14.5-5.25 28t-16.75 25l-305 305q-14 14-33 13.75t-33-14.25q-14-14-14-33.25t14-33.25l291-291Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
