import type { HTMLAttributes, ReactNode } from 'react'

import { MinusCircleIcon, PlusCircleIcon } from '@/components/atoms/Icons'
import { Tile } from '@/components/atoms/Tile'
import { ValueIndicator, type indicatorVariants } from '@/components/atoms/ValueIndicator'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import type { VariantProps } from 'class-variance-authority'

interface ReturnsTileProps {
  headerText: string
  tileMainContent: string
  baseYieldText: string | undefined
  rewardsText: string | undefined
  txCostsText: string | undefined
  valueIndicatorView: VariantProps<typeof indicatorVariants>['variant']
  isHighTvlImpact?: boolean
}

export function ReturnsTile({
  headerText,
  tileMainContent,
  baseYieldText,
  rewardsText,
  txCostsText,
  valueIndicatorView,
  isHighTvlImpact,
}: ReturnsTileProps) {
  const profitIconsView = isHighTvlImpact ? 'mute' : 'positive'

  return (
    <Tile variant="primary" spacing="medium">
      <div>
        <LabeledValueHeader>{headerText}</LabeledValueHeader>
        <LabeledValueContent className="mt-4">
          <LabeledValueValue>
            <ValueIndicator variant={valueIndicatorView}>{tileMainContent}</ValueIndicator>
          </LabeledValueValue>
        </LabeledValueContent>
        <LabeledValueContent>
          <LabeledValueValue>
            {baseYieldText && (
              <BreakdownItem variant={profitIconsView} icon="plus" label="Base yield:">
                {baseYieldText}
              </BreakdownItem>
            )}
            {rewardsText && (
              <BreakdownItem variant={profitIconsView} icon="plus" label="Rewards:">
                {rewardsText}
              </BreakdownItem>
            )}
            {txCostsText && (
              <BreakdownItem variant="negative" icon="minus" label="Tx. costs:">
                {txCostsText}
              </BreakdownItem>
            )}
          </LabeledValueValue>
        </LabeledValueContent>
      </div>
    </Tile>
  )
}

interface IconIndicator {
  icon: 'plus' | 'minus'
}

interface BreakdownItemProps extends HTMLAttributes<HTMLDivElement> {
  label: ReactNode
}

function BreakdownItem({
  children,
  variant,
  label,
  icon,
  ...props
}: BreakdownItemProps & IconIndicator & VariantProps<typeof indicatorVariants>) {
  return (
    <LabeledValueContent className="font-normal text-base leading-none" {...props}>
      <div className="flex gap-1">
        <IndicatorIcon variant={variant} icon={icon} />
        <div className="flex w-full flex-wrap justify-between gap-1">
          <span className="min-w-16">{label}</span>
          <span className="whitespace-break-spaces text-right">{children}</span>
        </div>
      </div>
    </LabeledValueContent>
  )
}

function IndicatorIcon({ variant, icon }: VariantProps<typeof indicatorVariants> & IconIndicator) {
  return <ValueIndicator variant={variant}>{icon === 'plus' ? <PlusCircleIcon /> : <MinusCircleIcon />}</ValueIndicator>
}
