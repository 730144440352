import { InvestmentPickerFilters, type InvestmentPickerFiltersProps } from './InvestmentPickerFilters'
import type { InvestmentPickerInputsProps } from './InvestmentPickerInputs'
import { InvestmentPickerInputs } from './InvestmentPickerInputs'

import { Panel, PanelContentWrapper, PanelTitle } from '@/components/atoms/Panel'
import { RewardsSwitch } from '@/components/molecules/RewardsSwitch/RewardsSwitch'

export const InvestmentPicker = ({
  amount,
  period,
  assetSymbols,
  selectedTokens,
  setSelectedTokens,
  setAmount,
  setPeriod,
  ...props
}: InvestmentPickerInputsProps & InvestmentPickerFiltersProps) => {
  return (
    <Panel className="bg-darkGray/10 p-0">
      <PanelContentWrapper className="p-4 pb-2 md:px-4 md:py-2">
        <div className="flex flex-col flex-wrap gap-x-6 gap-y-2 md:flex-row md:items-center">
          <PanelTitle>I would like to earn yield on</PanelTitle>
          <InvestmentPickerInputs
            amount={amount}
            setAmount={setAmount}
            assetSymbols={assetSymbols}
            selectedTokens={selectedTokens}
            setSelectedTokens={setSelectedTokens}
            period={period}
            setPeriod={setPeriod}
          />
          <RewardsSwitch />
        </div>
      </PanelContentWrapper>
      <InvestmentPickerFilters {...props} />
    </Panel>
  )
}
