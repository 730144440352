import { Area, CartesianGrid, ComposedChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { ChartLegend } from '../ChartLegend'

import { ChartContainer } from '@/components/atoms/Charts/ChartContainer'
import { ChartWatermark } from '@/components/atoms/Charts/ChartWatermark'
import { ZoomOutButton } from '@/components/atoms/Charts/ZoomOutButton'
import { useChartZoom } from '@/hooks'
import { useChartInteraction } from '@/hooks/charts/useChartInteraction'
import { useChartZoomedIn } from '@/hooks/charts/useChartZoomedIn'
import { useOnMouseUp } from '@/hooks/charts/useOnMouseUp'
import type { BackendProtocol } from '@/types'
import { formatNumber, getChartColor, getChartDateLabel } from '@/utils'
import type { MultiChartDataGroup } from '@/utils/chartUtils/composeMultiChartData'
import { useState } from 'react'
import { MultiTvlChartTooltip } from './MultiTvlChartTooltip'

interface ApyChartProps {
  protocolData: NonNullable<BackendProtocol>
  protocolVersions: string[]
  multiChartData: MultiChartDataGroup
  hideLegend?: boolean
  hideXAxis?: boolean
  zoomable: boolean
  unstacked?: boolean
  tvlInNativeAsset?: boolean
}

export const MultiProtocolTvlChart = ({
  protocolData,
  protocolVersions,
  multiChartData,
  hideLegend,
  hideXAxis,
  zoomable,
  unstacked,
  tvlInNativeAsset,
}: ApyChartProps) => {
  const {
    isZoomEnabled,
    state,
    allowDataOverflow,
    xAxisDomain,
    yAxisDomain,
    onChartMouseDown,
    onChartMouseMove,
    onChartMouseUp,
    onChartMouseLeave,
    zoomOut,
  } = useChartZoom({
    initialData: multiChartData.daily as unknown as Record<string, number>[],
    zoomable,
  })

  const { activeLine, hiddenLines, onChartClick, onChartHover, onChartLeave } = useChartInteraction()

  const zoomedIn = useChartZoomedIn(multiChartData.daily, xAxisDomain)
  const chartData = zoomedIn ? multiChartData.highResolution : multiChartData.daily

  const chartDescriptors = protocolVersions.map((version, index) => {
    const tvlKey = `tvl-${version}`
    return {
      key: tvlKey,
      name: `${capitalizeFirstLetter(protocolData.name)} ${version} TVL`,
      colors: getChartColor(index),
      hidden: hiddenLines.includes(tvlKey),
    }
  })

  const [mouseOut, setMouseOut] = useState(true)
  useOnMouseUp(() => mouseOut && onChartMouseUp(), [mouseOut])

  return (
    <div className="relative">
      <ChartContainer className="md:aspect-auto md:h-64 md:min-h-64">
        <ResponsiveContainer>
          <ComposedChart
            data={chartData}
            onMouseDown={onChartMouseDown}
            onMouseMove={onChartMouseMove}
            onMouseUp={onChartMouseUp}
            onMouseEnter={() => setMouseOut(false)}
            onMouseLeave={() => {
              setMouseOut(true)
              onChartMouseLeave()
            }}
            margin={{ top: 8, right: 0, bottom: 0, left: 6 }}
          >
            <defs>
              {protocolVersions.map((version, index) => {
                const chartKey = `tvl-${version}`
                const chartGradientStart = hiddenLines.includes(chartKey)
                  ? 'transparent'
                  : getChartColor(index, activeLine && activeLine !== chartKey ? '10%' : '64%')
                const chartGradientStop = hiddenLines.includes(chartKey)
                  ? 'transparent'
                  : getChartColor(index, activeLine && activeLine !== chartKey ? '10%' : '58%')

                return (
                  <linearGradient key={chartKey} id={`${chartKey}Fill`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={chartGradientStart} stopOpacity={1} />
                    <stop offset="100%" stopColor={chartGradientStop} stopOpacity={1} />
                  </linearGradient>
                )
              })}
            </defs>
            <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={!hideXAxis} />
            <XAxis
              dataKey="date"
              scale="time"
              tick={!hideXAxis}
              tickFormatter={getChartDateLabel}
              minTickGap={16}
              tickLine={false}
              height={hideXAxis ? 0 : undefined}
              domain={xAxisDomain}
              allowDataOverflow={allowDataOverflow}
              type={isZoomEnabled ? 'number' : 'category'}
            />
            <YAxis
              tickFormatter={(value) => formatNumber(value, 0, 1)}
              width={38}
              tickLine={false}
              allowDataOverflow={allowDataOverflow}
              domain={yAxisDomain}
            />
            {chartData.length > 0 && (
              <Tooltip
                content={<MultiTvlChartTooltip descriptors={chartDescriptors} tvlInNativeAsset={tvlInNativeAsset} />}
              />
            )}
            {protocolVersions
              .map((entry, index) => {
                const chartKey = `tvl-${entry}`
                const areaStroke = hiddenLines.includes(chartKey)
                  ? 'transparent'
                  : getChartColor(index, activeLine && activeLine !== chartKey ? '20%' : '100%')

                return (
                  <Area
                    key={chartKey}
                    type="monotone"
                    dot={false}
                    stackId={unstacked ? undefined : 1}
                    dataKey={chartKey}
                    stroke={areaStroke}
                    fill={unstacked ? 'transparent' : `url(#${chartKey}Fill)`}
                    strokeWidth={1.5}
                    isAnimationActive={false}
                    onMouseEnter={() => onChartHover(chartKey)}
                    onMouseLeave={() => onChartLeave()}
                  />
                )
              })
              .reverse()}
            {isZoomEnabled && state.dataKeyAreaLeft && state.dataKeyAreaRight ? (
              <ReferenceArea
                x1={state.dataKeyAreaLeft}
                x2={state.dataKeyAreaRight}
                fill="hsl(var(--gray)/30%)"
                stroke="hsl(var(--gray))"
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
        {isZoomEnabled && (
          <ZoomOutButton onClick={zoomOut} isVisible={state.left !== 'dataMin' && state.right !== 'dataMax'} />
        )}
        <ChartWatermark variant="square" />
      </ChartContainer>
      {!hideLegend && (
        <ChartLegend
          legendElements={chartDescriptors}
          onChartItemClick={onChartClick}
          onChartHover={onChartHover}
          onChartLeave={onChartLeave}
        />
      )}
    </div>
  )
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
