import { CrossedShieldIcon, VerifiedShieldIcon } from '@/components/atoms/Icons'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/atoms/Tooltip'
import type { VaultForList } from '@/types'
import { cn } from '@/utils/cn'
import type * as TooltipPrimitive from '@radix-ui/react-tooltip'

interface CurationTooltipProps extends Pick<VaultForList, 'isFeatured'> {
  isColored?: boolean
}

export const CurationTooltip = ({
  isFeatured,
  side,
  isColored,
}: CurationTooltipProps & TooltipPrimitive.TooltipContentProps) => {
  return (
    <Tooltip>
      <TooltipTrigger className={cn(isColored ? (isFeatured ? 'text-green' : 'text-warning') : 'text-textSecondary')}>
        {isFeatured ? <VerifiedShieldIcon size={24} /> : <CrossedShieldIcon size={24} />}
      </TooltipTrigger>
      <TooltipContent side={side}>
        <FeaturedTooltipContent isFeatured={isFeatured} />
      </TooltipContent>
    </Tooltip>
  )
}

const FeaturedTooltipContent = ({ isFeatured }: CurationTooltipProps) => {
  if (isFeatured) {
    return <p>Yearn curated vault</p>
  }
  return <p>Community strategy</p>
}
