import { fetchAuthorizedData } from '@/utils/checkIsAuthorized'
import { useEffect, useState } from 'react'

export function useIsAuthorized() {
  const [isAuthorized, setIsAuthorized] = useState(false)
  useEffect(() => {
    fetchAuthorizedData().then((data) => setIsAuthorized(data.authorized))
  }, [])
  return isAuthorized
}
