import { cn } from '@/utils/cn'
import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import { type ButtonHTMLAttributes, forwardRef } from 'react'

const buttonVariants = cva(cn('inline-flex gap-2 transition-colors'), {
  variants: {
    variant: {
      primary: cn(
        'inline-flex cursor-pointer gap-2 text-base outline-none transition-all',
        'h-10 items-center justify-center px-3 py-0 text-center leading-none',
        'rounded-lg bg-primaryGradient font-bold text-white [background-position:0%_50%] [background-size:225%_100%]',
        'hover:[background-position:100%_50%] focus-visible:[background-position:100%_50%]',
        'disabled:opacity-60'
      ),
      secondary: cn(
        'inline-flex cursor-pointer gap-2 text-base text-textPrimary outline-none transition-all',
        'h-10 items-center justify-center px-3 py-0 text-center leading-none',
        'rounded-lg border border-blue bg-blue text-textPrimary',
        'hover:border-textPrimary focus-visible:border-textPrimary',
        'disabled:border-blue disabled:text-textSecondary'
      ),
      text: cn(
        'inline-flex cursor-pointer gap-2 text-actionPrimary text-base outline-none transition-all',
        'hover:text-decorationPrimary focus-visible:text-decorationPrimary',
        'disabled:text-textPrimary/60'
      ),
      icon: cn(
        'inline-flex cursor-pointer gap-2 text-base text-textPrimary outline-none transition-all',
        'text-textSecondary',
        'hover:text-decorationPrimary focus-visible:text-decorationPrimary',
        'disabled:text-decorationSecondary/60'
      ),
      default: 'inline-flex cursor-pointer gap-2 text-base text-textPrimary outline-none transition-all',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asChild = false, className, variant, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return <Comp className={cn(buttonVariants({ className, variant }))} ref={ref} {...props} />
  }
)
Button.displayName = 'Button'
