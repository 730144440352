import { AccordionTrigger } from '@/components/atoms/Accordion'
import { Button } from '@/components/atoms/Button'
import { ArrowIcon } from '@/components/atoms/Icons'
import { cn } from '@/utils/cn'

interface VaultControlsProps {
  isDetailsOpen: boolean
  lendLink: string | undefined | null
  handleLinkEvent: () => void
  handleDetailsEvent: () => void
}

export const VaultControls = ({ isDetailsOpen, lendLink, handleLinkEvent, handleDetailsEvent }: VaultControlsProps) => {
  return (
    <div className="flex w-full flex-col gap-2 md:max-w-[136px]">
      {lendLink && (
        <Button variant="primary" onClick={handleLinkEvent} asChild>
          <a href={lendLink} target="_blank" rel="noopener noreferrer">
            Deposit
          </a>
        </Button>
      )}
      <Button variant="secondary" asChild>
        <AccordionTrigger onClick={handleDetailsEvent} className="group">
          {isDetailsOpen ? 'Less' : 'More'} details{' '}
          <ArrowIcon
            direction="down"
            className={cn('ml-auto transition-transform', 'group-data-[state=open]:-scale-y-100')}
          />
        </AccordionTrigger>
      </Button>
    </div>
  )
}
