import { trpc } from '@/config/trpc'
import type { ApyModes } from '@/types'

interface VaultQueryParams {
  address: string
  chainName: string
  interval: ApyModes
}

export const useChartData = ({ address, chainName, interval }: VaultQueryParams) => {
  const { data: chartData = [] } = trpc.getChartData.useQuery({ address, chainName, interval }, { suspense: false })
  return chartData
}
