import type { ChartData } from '@vaultsfyi/common'
import type { AxisDomainItem } from 'recharts/types/util/types'

type AxisDomain = [AxisDomainItem, AxisDomainItem] | string[]

export function isPointInDomain(dataPoint: ChartData<'date'>, xAxisDomain: AxisDomain): boolean {
  if (typeof xAxisDomain[0] === 'number' && xAxisDomain[0] > dataPoint.date) return false
  if (typeof xAxisDomain[1] === 'number' && xAxisDomain[1] < dataPoint.date) return false
  return true
}
