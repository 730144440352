import { Warning } from '@/components/molecules/Warning/Warning'
import type { DbWarning } from '@vaultsfyi/common'

interface Props {
  warnings: DbWarning[]
}

export function PreviewApyWarnings({ warnings }: Props) {
  return (
    <ul className="flex flex-col gap-2">
      {warnings.map(({ id, content }) => (
        <Warning key={id} content={content} />
      ))}
    </ul>
  )
}
