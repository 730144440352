import type { ComponentPropsWithoutRef } from 'react'

import { Button } from '@/components/atoms/Button'
import { StarIcon } from '@/components/atoms/Icons'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/atoms/Tooltip'
import { routes } from '@/config'
import { cn } from '@/utils/cn'
import { NavLink } from 'react-router-dom'

interface BookmarkButtonActiveProps {
  isActive?: boolean
}

export const BookmarkButton = ({
  isActive,
  onClick,
  className,
  ...props
}: BookmarkButtonActiveProps & ComponentPropsWithoutRef<'button'>) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="icon"
          className={cn(
            'relative flex cursor-pointer items-center justify-center',
            'before:absolute before:size-8',
            isActive && 'text-decorationPrimary',
            className
          )}
          onClick={onClick}
          aria-label="Add to favorites"
          {...props}
        >
          <StarIcon
            className={cn(
              '[&>[data-fillable-path]]:transition-colors',
              isActive && '[&>[data-fillable-path]]:fill-decorationPrimary'
            )}
            size={24}
          />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <BookmarkTooltipContent isActive={isActive} />
      </TooltipContent>
    </Tooltip>
  )
}

const BookmarkTooltipContent = ({ isActive }: BookmarkButtonActiveProps) => {
  const tooltipText = isActive ? 'Remove vault from favorites.' : 'Add vault to favorites.'

  return (
    <div className="flex flex-col gap-2">
      <p>{tooltipText}</p>
      <p>
        <Button variant="text" className="text-actionPrimary" asChild>
          <NavLink to={routes.favorites}>See your favorites list</NavLink>
        </Button>
        .
      </p>
    </div>
  )
}
