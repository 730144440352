import { cn } from '@/utils/cn'
import { cva } from 'class-variance-authority'
import type { ReactNode } from 'react'

interface LegendElement {
  key: string
  name: string
  legendValue?: string
  hidden?: boolean
  colors: string[] | string
  button?: ReactNode
}

interface ChartLegendProps {
  className?: string
  legendElements: LegendElement[]
  onChartItemClick?: (key: string) => void
  onChartHover?: (key: string) => void
  onChartLeave?: () => void
}

const legendItemVariants = cva(cn('flex flex-col gap-1'), {
  variants: {
    clickable: {
      true: 'cursor-pointer hover:text-decorationPrimary focus-visible:text-decorationPrimary',
      false: '',
    },
    hidden: {
      true: 'line-through opacity-40',
      false: '',
    },
  },
  defaultVariants: {
    clickable: false,
    hidden: false,
  },
})

export const ChartLegend = ({
  className,
  legendElements,
  onChartItemClick,
  onChartHover,
  onChartLeave,
}: ChartLegendProps) => {
  return (
    <div className={cn('flex flex-wrap items-center justify-center gap-x-6 gap-y-3 leading-none', className)}>
      {legendElements.map(({ key, name, legendValue, colors, hidden, button }) => (
        <div
          key={name}
          className={legendItemVariants({ clickable: !!onChartItemClick, hidden })}
          onClick={() => onChartItemClick && onChartItemClick(key)}
          onMouseEnter={() => onChartHover && onChartHover(key)}
          onMouseLeave={onChartLeave}
        >
          <div className="flex items-center gap-1">
            {button}
            <span>{name}</span>
            <div
              className="size-3 rounded"
              style={
                Array.isArray(colors)
                  ? { backgroundImage: `linear-gradient(to bottom, ${colors[0]}, ${colors[1]})` }
                  : { backgroundColor: colors }
              }
            />
          </div>
          {legendValue && <span className="font-bold text-lg text-textPrimary leading-none">{legendValue}</span>}
        </div>
      ))}
    </div>
  )
}
